export const defaultChecklistItems = [
  { name: 'Trash', status: '-' },
  { name: 'Coffee', status: '-' },
  { name: 'Cooler', status: '-' },
  { name: 'Seat Belts', status: '-' },
  { name: 'Daytime Controls', status: '-' },
  { name: 'Covers', status: '-' },
  { name: 'Lavatories', status: '-' },
  { name: 'Tires', status: '-' },
  { name: 'Oil', status: '-' },
  { name: 'Battery', status: '-' },
  { name: 'Headset', status: '-' },
  { name: 'Credit Cards', status: '-' },
  { name: 'Times/Cycles', status: '-' },
];

export const TripPostFlightState = {
  FAIL: 'FAIL',
  PASS: 'PASS',
  NA: 'N/A',
  NO_DATA: '-',
};

export const TripPostFlightOptions = {
  [TripPostFlightState.FAIL]: 'FAIL',
  [TripPostFlightState.PASS]: 'PASS',
  [TripPostFlightState.NA]: 'N/A',
};
