import React, { useState, useEffect } from 'react';

import { Stack } from '@appComponents/navigation';
import { useAuthState } from '@appFirebase';
import Main from './main';
import Login from './auth/Login';
import ForgotPassword from './auth/ForgotPassword';
import { CreateAccount, CreateCompany } from './auth/CreateAccount';
import { useMyData } from '@appUtils/api';
import { UserRole } from '@appUtils/tripConverter';
import Text from '@appComponents/Text';
import Dialog from '@appComponents/Dialog';
import Button from '@appComponents/Button';
import Terms from './common/Terms';
import { WaitingRoom } from './auth/WaitingRoom';
import app from '@appFirebase';
import SubscriptionStack from './main/SubscriptionStack';

const SUBSCRIPTION_STATUS_TO_SHOW_WEB = ['trialing', 'active'];

export default function Screens() {
  const [auth, isLoading] = useAuthState();

  if (isLoading) {
    return null;
  }

  const AppScreens = auth ? AuthenticatedScreens : UnauthenticatedScreens;

  return (
    <AppScreens>
      <Stack.Group>
        <Stack.Screen
          name="Terms"
          component={Terms}
          options={{ title: 'Terms and Conditions' }}
        />
        <Stack.Screen
          name="Privacy"
          component={Terms}
          options={{ title: 'Privacy Policy' }}
        />
      </Stack.Group>
    </AppScreens>
  );
}

const AuthenticatedScreens = ({ children }) => {
  const [user, userLoading] = useMyData();
  const [company, setCompany] = useState(null);
  const [inTrialCodePeriod, setInTrialCodePeriod] = useState(false);

  useEffect(() => {
    if (user?.managementCompany && !company) {
      const companyRef = app
        .firestore()
        .collection('managementCompanies')
        .doc(user.managementCompany.docId);
      companyRef.onSnapshot(doc => {
        const companyData = doc.data();
        setCompany(companyData);
        if (companyData?.trialCodeExpirationDate) {
          const expirationDate = new Date(
            companyData.trialCodeExpirationDate.seconds * 1000 +
              companyData.trialCodeExpirationDate.nanoseconds / 1000000,
          );
          setInTrialCodePeriod(expirationDate > new Date());
        }
      });
    }
  }, [user, company]);

  if (userLoading) {
    return <UnauthenticatedScreens children={children} />;
  }

  if (!user?.managementCompany?.ref) {
    return (
      <Stack.Navigator>
        <Stack.Group>
          <Stack.Screen
            name="Waiting to Join Company"
            component={WaitingRoom}
            options={{ headerShown: false }}
          />
        </Stack.Group>
      </Stack.Navigator>
    );
  }
  const hasWebAccess =
    !userLoading &&
    (user.role === UserRole.MANAGER || user.role === UserRole.EMPLOYEE);

  if (!hasWebAccess) {
    return <UnauthenticatedScreens showWarning={true} children={children} />;
  }

  if (
    (company &&
      company.subscriptionId &&
      SUBSCRIPTION_STATUS_TO_SHOW_WEB.includes(company.subscriptionStatus)) ||
    inTrialCodePeriod
  ) {
    return (
      <Stack.Navigator>
        <Stack.Group>
          {/*The Main screen is a Drawer Navigator that handles all our main app routes*/}
          <Stack.Screen
            name="Main"
            component={Main}
            options={{ headerShown: false }}
          />
          {/*Non-drawer screen like modals can be added here*/}
        </Stack.Group>
        {children}
      </Stack.Navigator>
    );
  }

  return (
    <Stack.Navigator>
      <Stack.Group>
        <Stack.Screen
          name="Subscription"
          component={SubscriptionStack}
          options={{ headerShown: false }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

const UnauthenticatedScreens = ({ showWarning = false, children }) => {
  const [dialogVisible, setDialogVisible] = useState(showWarning);

  useEffect(() => {
    setDialogVisible(showWarning);
  }, [showWarning]);

  return (
    <>
      <WebAccessDialog
        dialogVisible={dialogVisible}
        setDialogVisible={setDialogVisible}
      />
      <Stack.Navigator>
        <Stack.Group screenOptions={{ headerShown: false }}>
          {/*Typically, for landing pages and register/login screens*/}
          <Stack.Screen name="Login" component={Login} />
          <Stack.Screen name="Forgot Password" component={ForgotPassword} />
          <Stack.Screen name="Join Company" component={CreateAccount} />
          <Stack.Screen name="Create Company" component={CreateCompany} />
        </Stack.Group>
        {children}
      </Stack.Navigator>
    </>
  );
};

const WebAccessDialog = ({ dialogVisible, setDialogVisible }) => {
  return (
    <Dialog
      title="This user does not have access to the Web app."
      visible={dialogVisible}
      actionSlot={[
        <Button
          key="close"
          mode="outlined"
          color="dark"
          onPress={() => setDialogVisible(false)}>
          Close
        </Button>,
      ]}>
      <Text>
        Please login to the Mobile app or login with a different user.
      </Text>
    </Dialog>
  );
};
