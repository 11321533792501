import React, { useEffect } from 'react';
import {
  StyleSheet,
  View,
  Modal,
  TouchableOpacity,
  ActivityIndicator,
  ScrollView,
} from 'react-native';
import { Box, Spacer } from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import { useTheme } from '@appComponents/theme';
import { useMeteringContext } from '@appUtils/context/MeteringContext';
import {
  RvsmAltitudeField,
  VorCheckField,
  VorNumberField,
} from '@appComponents/Metering/Fields';
import { RadioButton } from 'react-native-paper';

export const VorRvsmModal = ({ onClose, updateMode, trip }) => {
  const theme = useTheme();
  const { control, watch, setValue, isSaving, saveMeteringData, selectedLeg } =
    useMeteringContext();

  const vor1 = watch('vorRvsm.vor1');
  const vor2 = watch('vorRvsm.vor2');
  const readingTaken = watch('vorRvsm.readingTaken');

  useEffect(() => {
    const difference = Math.abs(Number(vor1) - Number(vor2)) || 0;
    setValue('vorRvsm.vorDifference', difference.toFixed(2));
  }, [vor1, vor2, setValue]);

  const handleReadingTakenChange = value => {
    setValue('vorRvsm.readingTaken', value);
  };

  return (
    <Modal visible={true} animationType="fade" transparent={true}>
      <View style={styles.modalContainer}>
        <View
          style={[
            styles.modalContent,
            { backgroundColor: theme.colors.background },
          ]}>
          <Text style={styles.modalTitle}>
            {updateMode === 'leg'
              ? `EDIT LEG ${selectedLeg?.from} > ${selectedLeg?.to}`
              : 'EDIT TREND MONITORING'}
          </Text>

          <ScrollView style={styles.scrollView}>
            <Box mt={2}>
              <VorCheckField
                name="vorRvsm.vorCheck"
                label="VOR Check"
                control={control}
                style={{ backgroundColor: theme.colors.fieldBackground }}
              />

              <Spacer size={1} />

              <View style={styles.container}>
                <VorNumberField
                  wrapStyle={styles.input}
                  name="vorRvsm.vor1"
                  label="#1"
                  control={control}
                  style={{ backgroundColor: theme.colors.fieldBackground }}
                />

                <VorNumberField
                  wrapStyle={styles.input}
                  name="vorRvsm.vor2"
                  label="#2"
                  control={control}
                  style={{ backgroundColor: theme.colors.fieldBackground }}
                />
                <VorNumberField
                  wrapStyle={styles.input}
                  name="vorRvsm.vorDifference"
                  disabled
                  label="DIFFERENCE"
                  control={control}
                />
              </View>

              <RvsmAltitudeField
                name="vorRvsm.rvsm"
                label="Altitude at RVSM"
                control={control}
                style={{ backgroundColor: theme.colors.fieldBackground }}
              />

              <View style={styles.radioGroup}>
                <Text style={styles.radioGroupLabel}>Reading Taken</Text>
                <RadioButton.Group
                  onValueChange={handleReadingTakenChange}
                  value={readingTaken || '-'}>
                  <View style={styles.radioItem}>
                    <RadioButton value="ground" color={theme.colors.primary} />
                    <Text style={styles.radioLabel}>GROUND</Text>
                  </View>
                  <View style={styles.radioItem}>
                    <RadioButton value="air" color={theme.colors.primary} />
                    <Text style={styles.radioLabel}>AIR</Text>
                  </View>
                </RadioButton.Group>
              </View>
            </Box>
          </ScrollView>
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              onPress={() => {
                if (updateMode === 'trip') {
                  setValue('vorRvsm', trip?.metering?.overall?.trendMonitoring);
                }

                onClose();
              }}
              style={[
                styles.button,
                { backgroundColor: theme.colors.fieldBorder },
              ]}>
              <Text style={styles.buttonText}>CANCEL</Text>
            </TouchableOpacity>
            {!isSaving ? (
              <TouchableOpacity
                onPress={() => {
                  const success = saveMeteringData();
                  if (success) {
                    setTimeout(() => {
                      onClose();
                    }, 1000);
                  }
                }}
                style={[
                  styles.button,
                  { backgroundColor: theme.colors.accent },
                ]}>
                <Text style={styles.buttonText}>SAVE</Text>
              </TouchableOpacity>
            ) : (
              <View style={styles.saveLoader}>
                <ActivityIndicator size="large" color={theme.colors.accent} />
              </View>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: '90%',
    maxWidth: 800,
    maxHeight: '80%',
    borderRadius: 10,
    padding: 20,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  radioGroup: {
    marginTop: -20,
  },
  radioGroupLabel: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  radioItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  radioLabel: {
    marginLeft: 8,
    fontSize: 16,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  button: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginLeft: 10,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  input: {
    width: 200,
  },
});
