import React from 'react';

import { HelpBase, HelpText } from './Help';
import Text from '@appComponents/Text';
import { ScrollView } from 'react-native';
import { Spacer } from '@appComponents/ScreenLayout';

const TermsAndDefinitions = () => (
  <HelpBase>
    <ScrollView>
      <Section title="Terms">
        <Definition
          term="Company ID"
          def=" – A five-digit value associated with a Management Company account. It is displayed in the top right corner of the Management Company app window."
        />
        <Definition
          term="App Passenger"
          def=" – A passenger on a trip who is using the FlightApp! mobile app."
        />
        <Definition
          term="Guest Passenger"
          def=" – A passenger on a trip who is not using the mobile app."
        />
        <Definition
          term="Management Company"
          def=" – The company responsible for managing aircraft and trip logistics, using the FlightApp! web app."
        />
        <Definition
          term="Owner"
          def=" – The individual with ownership rights to an aircraft. Owners use the FlightApp! mobile app to schedule their aircraft, communicate with individuals associated with the trip, and view, approve, and pay trip expenses."
        />
        <Definition
          term="Pilot"
          def=" – The individual assigned to an aircraft and responsible for recording trip expenses and aircraft data."
        />
        <Definition
          term="Trip Id"
          def=" – A unique six-digit code automatically assigned to a new trip. This value cannot be changed."
        />
      </Section>
      <Section title="Owner and Pilot Statuses Associated with a Trip">
        <Spacer />
        <Subtitle sTitle="Owner:" />
        <Definition
          term="Draft"
          def=" – A trip created by the Owner but not yet shared with Managers."
        />
        <Definition
          term="Requested"
          def=" – The Owner has built a trip and submitted it to the Manager, but the Manager has not acknowledged it yet."
        />
        <Definition
          term="Acknowledged"
          def=" – The Manager informs the Owner that they are working on the trip request."
        />
        <Definition
          term="Manager Requested"
          def=" – A Manager has created a trip and shared it with the Owner for approval or rejection."
        />
        <Definition term="Canceled" def="– The trip has been canceled." />
        <Definition
          term="Accepted"
          def=" – The Owner has accepted the trip created by the Manager."
        />
        <Definition
          term="Rejected"
          def=" – The Owner has rejected the Manager-made trip."
        />
        <Definition
          term="Updated"
          def=" – The Manager has updated the trip, but the Owner has not yet viewed the change."
        />
        <Definition
          term="Seen"
          def=" – The Owner has seen the update made by the Manager."
        />
        <Spacer />
        <Subtitle sTitle="Pilot:" />
        <Definition
          term="Manager Requested"
          def=" – The Manager has created a trip and shared it with the Pilot(s) for acceptance or rejection."
        />
        <Definition
          term="Manager Canceled"
          def=" – The trip has been canceled by the Manager."
        />
        <Definition term="Accepted" def=" – The Pilot has accepted the trip." />
        <Definition term="Rejected" def=" – The Pilot has rejected the trip." />
        <Definition
          term="Updated"
          def=" – The Manager has updated the trip, but the Pilot has not yet seen it."
        />
        <Definition
          term="Seen"
          def=" – The Pilot has seen the update made by the Manager."
        />
      </Section>
      <Section title="Trip States" />
      <ul>
        <li>
          <HelpText weight="bold">Requested</HelpText>
          <ul>
            <li>
              Owner-built trips will appear on the Manager app's "Requested" tab.
            </li>
            <li>
              When the Manager acknowledges the trip, its state changes to {<Text weight="bold">Draft</Text>}, and the Owner's status changes to {<Text weight="bold">Acknowledged</Text>}
            </li>
            <li>
              {<Text weight="bold">Note:</Text>} A trip remains in the Owner’s "Requested" tab until the Manager completes the itinerary and updates the trip status to {<Text weight="bold">Upcoming</Text>}.
            </li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          <HelpText weight="bold">Draft</HelpText>
          <ul>
            <li>
              {<Text weight="bold">Owner Draft:</Text>} The trip has been built by the Owner but not sent to the Manager.
            </li>
            <li>
              {<Text weight="bold">Manager Draft:</Text>}: The Manager has either acknowledged an Owner's trip request or created a trip and sent it to the Pilot(s) and Owner for approval.
            </li>
          </ul>
        </li>
        <Definition
          term="Upcoming"
          def=" – The itinerary has been finalized, but the trip has not started yet."
        />
        <Definition term="Active" def=" – The trip is in progress." />
        <Definition term="Completed" def=" – The trip has ended." />
        <Definition
          term="Archived"
          def=" – A completed trip that has been closed out and archived."
        />
        <li>
          {<Text weight="bold">Cancled</Text>} – The trip has been canceled by
          the Manager. It moves to the "Completed" tab with the status of{' '}
          {<Text weight="bold">Cancled</Text>}.
        </li>
      </ul>
      <Section title="Additional Features" />
      <Definition
        term="Messages"
        def="– Each trip has a dedicated messaging channel where the Manager and OPPs associated with the trip can read and write messages."
      />
      <Definition
        term="Trip Notes"
        def="Typically used by the Owner to document requests for the trip"
      />
      <Definition
        term="Flags"
        def=" – Flags are used to highlight specific trip expense line items. Flags can be added by Owners, Pilots, or Managers."
      />
      <Definition
        term="Flag Comments"
        def=" – Comments on flagged expenses, where any OPP can share communication."
      />
      <Definition term="Squawk Statuses" def="">
        <ul>
          <Definition
            term="MEL (yellow)"
            def=" – Minimum Equipment List: The aircraft has an issue but is flight-worthy."
          />
          <Definition
            term="UFW (red)"
            def=" – Un-Flight Worthy: The aircraft has an issue and is not allowed to fly."
          />
          <Definition
            term="UC (gray)"
            def=" – Uncategorized: The aircraft has an issue, but its severity has not been classified as either MEL or UFW."
          />
          <Definition
            term="NS (green)"
            def=" – No squawk issues with the aircraft."
          />
        </ul>
        <Definition
          term="Note:"
          def="Pilots or Managers can tag an aircraft with a maintenance squawk, but only Managers can set the Squawk status. Images can be attached to a squawk report."
        />
      </Definition>
      <Section title="Aircraft Information">
        <Definition
          term="Aircraft Name"
          def=" – An informal name given to the aircraft by the Manager."
        />
        <Definition
          term="Aircraft Type "
          def=" – Typically the manufacturer and model of the aircraft."
        />
        <Definition
          term="Last Location "
          def=" – As entered by the Pilot or Manager."
        />
        <Definition
          term="Current Fuel "
          def=" – As entered by the Pilot or Manager."
        />
        <Definition
          term="Primary Owner "
          def=" – The individual who takes primary responsibility for an aircraft. Every aircraft must have a Primary Owner assigned."
        />
        <Definition
          term="Owner "
          def=" – An aircraft Owner who is not the Primary Owner."
        />
        <Definition
          term="Pilots"
          def=" – Any Pilot who has been assigned scheduling rights for the aircraft."
        />
        <Definition
          term="Active Squawks"
          def=" – Current aircraft maintenance issues. (See Squawk Statuses)"
        />
        <Definition
          term="Archived Squawks"
          def=" – Resolved and archived maintenance issues."
        />
      </Section>
      <Section title="Accounting Terms">
        <Definition
          term="Accounts Payable (Pilot)"
          def={
            ' – In the "Accounting" tab, under "Trip Expenses," there is a table in the bottom right showing the total trip expenses paid by the Pilot(s) and awaiting reimbursement. This window allows the Manager to make adjustments and pay the Pilot(s) via Stripe.'
          }>
          <Definition
            term="Note:"
            def="Both the Management Company and Pilots must have their Stripe accounts set up."
          />
        </Definition>
        <Definition
          term="Accounts Receivable (Owner)"
          def={
            ' – In the "Accounting" tab, under "Trip Expenses," there is a table in the bottom left showing the total trip expenses owed by the Owner to the Management Company. This window allows the Manager to make adjustments and request payment from the Owner.'
          }>
          <Definition
            term="Note:"
            def="Both the Management Company and Owners must have their Stripe accounts set up."
          />
        </Definition>
      </Section>
      <Section title="Trip Builder – A feature used by the Manager, Pilot, or Owner to create a trip." />
    </ScrollView>
  </HelpBase>
);

const Section = ({ title, children }) => (
  <>
    <Text color="primary" weight="bold" size="large">
      {title}
    </Text>
    <ul>{children}</ul>
  </>
);

const Subtitle = ({ sTitle }) => (
  <Text color="mobilePrimary" weight="bold" size="mediumLarge">
    {sTitle}
  </Text>
);

const Definition = ({ term, def, children }) => (
  <li>
    <HelpText weight="bold">{term}</HelpText>
    <HelpText>{def}</HelpText>
    {children && <ul>{children}</ul>}
  </li>
);

export default TermsAndDefinitions;
