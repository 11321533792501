/**
 * @file The chat component that displays messages for a trip and allows sending messages
 */
import React, { Fragment } from 'react';
import styled from '@emotion/native';
import {
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import _ from 'lodash';
import { DateTime } from 'luxon';

import { getUid } from '@appFirebase';
import './tripMessages.css';
import {
  useTripConversation,
  sendMessage,
  clearManagerUnreadMessages,
} from '@appUtils/messages';
import { Trip } from '@appUtils/tripConverter';

type MessageChatProps = {
  trip: Trip,
  height: Number,
  visible: Boolean,
};

const MessageChat = ({ trip, width, height, visible }: MessageChatProps) => {
  const currentUID = getUid();
  const conversation = useTripConversation(trip?.id) ?? {};
  if (visible && conversation?.managementUnreadMessages > 0) {
    clearManagerUnreadMessages({ conversation });
  }

  const submitHandler = (
    innerHtml: String,
    textContent: String,
    innerText: String,
    nodes: NodeList,
  ) => {
    sendMessage(conversation?.id, textContent, trip);
  };

  return (
    <ChatBox height={height}>
      <ChatContainer>
        <MessageList>
          {_.map(conversation?.messages, message => {
            const formatedSentTime = DateTime.fromMillis(
              message?.dateSent?.toMillis(),
            ).toFormat('t ZZZZ dd LLL yyyy');
            const name = message?.author?.name?.split(' ')[0] ?? '';
            const role = message?.author?.role;
            const updatedRole = role
              ? `${role.charAt(0).toUpperCase()}${role.slice(1).toLowerCase()}`
              : '';
            const direction =
              message?.author?.id !== currentUID ? 'incoming' : 'outgoing';
            return (
              <Fragment
                key={`${message?.author?.id} ${message?.dateSent.toMillis()}`}>
                <Message.Header
                  sentTime={formatedSentTime}
                  sender={`${name}, ${updatedRole}`}
                />
                <Message
                  className="message"
                  type="text"
                  model={{
                    direction,
                    message: message?.text,
                  }}
                />
              </Fragment>
            );
          })}
        </MessageList>
        <MessageInput
          placeholder="Write your message..."
          attachButton={false}
          onSend={submitHandler}
          disabled={!trip?.id}
        />
      </ChatContainer>
    </ChatBox>
  );
};

const ChatBox = styled.View(({ height }) => ({
  width: '100%',
  height,
}));

export default MessageChat;
