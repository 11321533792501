import React, { useEffect, useState } from 'react';
import { MainSurface, ScreenLayout } from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import Button from '@appComponents/Button';
import { DropDownField } from '@appComponents/forms/FormFields';
import { useForm } from 'react-hook-form';
import { useTheme } from '@appComponents/theme';

import styled from '@emotion/native';

import app from '@appFirebase';
import { useMyData } from '@appUtils/api';

const aircraftQuantityOptions = Array.from({ length: 20 }, (_, i) => ({
  name: (i + 1).toString(),
  value: i + 1,
}));

const subscriptionManagementFunction = app
  .functions()
  .httpsCallable('subscriptionManagement');
const updateSubscriptionFunction = app
  .functions()
  .httpsCallable('updateSubscription');

const Subscription = () => {
  const [user, userLoading] = useMyData();
  const [company, setCompany] = useState(null);
  const managementCompany = user?.managementCompany;
  const theme = useTheme();
  const [loading, setLoading] = useState(true);

  const form = useForm({
    defaultValues: {
      aircraftQuantity: {
        name: '',
        value: '',
      },
    },
  });
  const aircraftQuantity = form.watch('aircraftQuantity');

  useEffect(() => {
    if (managementCompany && !company) {
      const companyRef = app
        .firestore()
        .collection('managementCompanies')
        .doc(managementCompany.docId);
      companyRef.onSnapshot(doc => {
        setCompany(doc.data());
      });
    }
  }, [company, user, managementCompany]);

  useEffect(() => {
    if (company) {
      setLoading(false);
      if (company.aircraftQuantity) {
        form.reset({
          aircraftQuantity: {
            name: company.aircraftQuantity.toString(),
            value: company.aircraftQuantity,
          },
        });
      }
    }
  }, [company, form]);

  const onSubmitUpdate = async () => {
    setLoading(true);
    try {
      await updateSubscriptionFunction({
        companyDocId: user.managementCompany.docId,
        quantity: aircraftQuantity.value,
      });
      setLoading(false);
    } catch (error) {
      console.error('Error updating subscription:', error);
      setLoading(false);
    }
  };

  const onManageSubscription = async () => {
    setLoading(true);
    try {
      const data = await subscriptionManagementFunction({
        companyDocId: user.managementCompany.docId,
      });
      window.location.href = data.data.portalSessionUrl;
    } catch (error) {
      console.error('Error managing subscription:', error);
      setLoading(false);
    }
  };

  const userDataLoading = userLoading && !company;

  if (userDataLoading) {
    return (
      <ScreenLayout alignItems="stretch">
        <MainSurface>
          <Text>Loading...</Text>
        </MainSurface>
      </ScreenLayout>
    );
  }

  return (
    <ScreenLayout alignItems="stretch">
      <SubscriptionSurface>
        <PageContent>
          <Section>
            <DropDownField
              control={form.control}
              label="How many aircraft do you manage?"
              name="aircraftQuantity"
              options={aircraftQuantityOptions}
              theme={theme}
            />
            <Button
              disabled={loading}
              onPress={onSubmitUpdate}
              style={{ marginTop: theme.layout.space(1) }}>
              Update
            </Button>
          </Section>

          <Section>
            <ManageDiv>
              <TextDiv>
                <Text>
                  You can change your payment method, and cancel your
                  subscription here.
                </Text>
              </TextDiv>
              <Button
                disabled={loading}
                onPress={() => onManageSubscription(user)}>
                Manage subscription
              </Button>
            </ManageDiv>
          </Section>
        </PageContent>
      </SubscriptionSurface>
    </ScreenLayout>
  );
};

const SubscriptionSurface = styled(MainSurface)`
  justify-content: center;
`;

const PageContent = styled.View`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${({ theme }) => `${theme.layout.space(2)}px`};
  padding-top: ${({ theme }) => `${theme.layout.space(1)}px`};
`;

const Section = styled.View`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ManageDiv = styled.View`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => `${theme.layout.space(3)}px`};
`;

const TextDiv = styled.View`
  margin-bottom: ${({ theme }) => `${theme.layout.space(1)}px`};
`;

export default Subscription;
