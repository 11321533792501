/**
 * @file
 * ImageViewer component
 *
 * @format
 * @flow strict-local
 */
import * as React from 'react';
import type { Node } from 'react';

import ImageThumbnails from './ImageThumbnails';
import ImageViewerModal from './ImageViewerModal';
import AddImageButton from '../AddImageButton';
import { Spacer } from '@appComponents/ScreenLayout';

export const ImageViewer = ({
  images,
  initialIndex,
  canAddImage,
  handleUpload,
  handleRemove,
  displayFileNames = false,
  addImageText,
  uploadData,
  dialogText,
}: ImageViewerProps): Node => {
  const [index, setIndex] = React.useState(initialIndex);
  const [modalVisible, setModalVisible] = React.useState(false);

  const onThumbnailPress = React.useCallback(thumbIndex => {
    setModalVisible(true);
    setIndex(thumbIndex);
  }, []);

  const onDismiss = React.useCallback(() => {
    setModalVisible(false);
    setIndex(initialIndex);
  }, [initialIndex]);

  return (
    <>
      <ImageThumbnails
        images={images}
        onPress={onThumbnailPress}
        canAddImage={canAddImage}
        handleRemove={handleRemove}
        displayFileNames={displayFileNames}
        uploadData={uploadData}
      />
      <Spacer />
      {canAddImage && (
        <AddImageButton
          width="50%"
          onImageResult={handleUpload}
          addImageText={addImageText}
          dialogText={dialogText}
        />
      )}
      <ImageViewerModal
        images={images}
        defaultIndex={index}
        onDismiss={onDismiss}
        visible={modalVisible}
        displayFileNames={displayFileNames}
        uploadData={uploadData}
      />
    </>
  );
};

type ImageViewerProps = {
  images: Array<{
    uir: string,
    path: string,
  }>,
  initialIndex?: number,
  displayFileNames?: boolean,
};

ImageViewer.defaultProps = {
  initialIndex: undefined,
};

export default ImageViewer;
