import React, { useState } from 'react';
import { View, StyleSheet, ScrollView, Text } from 'react-native';
import { useTheme } from '@appComponents/theme';
import { useMeteringContext } from '@appUtils/context/MeteringContext';
import { useAircraftData } from '@appUtils/aircraft';
import { FlightTimesModal } from './Modals/FlightTimesModal';
import { StyledIconButton } from '@appComponents/Metering/StyledIconButton';
// import { usePreviewCycleIncrement } from './hooks/useCyclesPreviewIncrements';

export const FlightTimes = ({ trip, handleSelectedLegChange }) => {
  const theme = useTheme();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { watch } = useMeteringContext();
  const updateMode = watch('updateMode');

  const [aircraft, aircraftLoading] = useAircraftData(trip?.aircraft?.path);
  const [selectedLegId, setSelectedLegId] = useState(null);

  // const updatedMetering = usePreviewCycleIncrement({ trip, aircraft });

  const updatedMetering = trip?.metering;

  if (aircraftLoading) {
    return null;
  }

  const isHobbs = aircraft?.meteringSystem
    ? aircraft?.meteringSystem === 'Hobbs'
    : true;
  const showAPU = ['Hobbs', 'FMS'].includes(aircraft?.apuMeteringSystem);

  const getFlightTimeData = (field, legId) => {
    if (updateMode === 'leg' && legId) {
      return updatedMetering?.legs?.[legId]?.flightTimes?.[field] || '-';
    }

    return updatedMetering?.overall?.flightTimes?.[field] || '-';
  };

  const openModal = legId => {
    setSelectedLegId(legId);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedLegId(null);
  };

  const renderMainInfo = leg => {
    // Labels for the first column
    const labels = [
      { label: 'Beginning', fieldKey: 'Beginning' },
      {
        label: isHobbs ? 'Ending' : 'Current',
        fieldKey: isHobbs ? 'Ending' : 'Current',
      },
      {
        label: isHobbs ? 'Current' : 'Ending',
        fieldKey: isHobbs ? 'Current' : 'Ending',
      },
    ];

    const sections = [];

    if (isHobbs) {
      sections.push({
        sectionTitle: 'HOBBS (HR)',
        dataKeys: {
          Beginning: 'hobbsBeginning',
          Ending: 'hobbsEnding',
          Current: 'hobbsCurrent',
        },
      });
    }

    sections.push({
      sectionTitle: 'AIR FRAME (HR)',
      dataKeys: {
        Beginning: 'airFrameBeginning',
        Ending: 'airFrameEnding',
        Current: 'airFrameCurrent',
      },
    });

    // For each engine
    for (let i = 0; i < aircraft.numEngines; i++) {
      const engineNumber = i + 1;
      sections.push({
        sectionTitle: `ENGINE ${engineNumber} (HR)`,
        dataKeys: {
          Beginning: `engine${engineNumber}Beginning`,
          Ending: `engine${engineNumber}Ending`,
          Current: `engine${engineNumber}Current`,
        },
      });
      sections.push({
        sectionTitle: `ENGINE ${engineNumber} CYCLES`,
        dataKeys: {
          Beginning: `engine${engineNumber}CyclesBeginning`,
          Ending: `engine${engineNumber}CyclesEnding`,
          Current: `engine${engineNumber}CyclesCurrent`,
        },
      });
    }

    // LANDING section
    sections.push({
      sectionTitle: 'LANDINGS',
      dataKeys: {
        Beginning: 'landingBeginning',
        Ending: 'landingEnding',
        Current: 'landingCurrent',
      },
    });

    // APU sections if applicable
    if (showAPU) {
      sections.push({
        sectionTitle: 'APU (HR)',
        dataKeys: {
          Beginning: 'apuBeginning',
          Ending: 'apuEnding',
          Current: 'apuCurrent',
        },
      });
      sections.push({
        sectionTitle: 'APU CYCLES',
        dataKeys: {
          Beginning: 'apuCycleBeginning',
          Ending: 'apuCycleEnding',
          Current: 'apuCycleCurrent',
        },
      });
    }

    // Adjust sections into rows of 3 columns (excluding the labels column)
    const columnsPerRow = 3;
    const sectionRows = [];
    for (let i = 0; i < sections.length; i += columnsPerRow) {
      sectionRows.push(sections.slice(i, i + columnsPerRow));
    }

    return (
      <View style={styles.infoContainer}>
        {updateMode === 'leg' ? (
          <Text style={[styles.legHeader, { color: theme.colors.text }]}>
            {leg?.from} &gt; {leg?.to}
          </Text>
        ) : null}
        {sectionRows.map((sectionRow, rowIndex) => (
          <View key={rowIndex} style={styles.rowContainer}>
            <View style={styles.labelColumn}>
              <Text
                style={[
                  styles.sectionTitle,
                  { color: theme.colors.tableBackground },
                ]}>
                placeholder
              </Text>
              {labels.map(labelItem => (
                <View
                  key={labelItem.fieldKey}
                  style={[
                    styles.cell,
                    { backgroundColor: theme.colors.background },
                  ]}>
                  <Text
                    style={[{ color: theme.colors.text }, styles.labelCell]}>
                    {labelItem.label}
                  </Text>
                </View>
              ))}
            </View>
            {sectionRow.map(section => (
              <View key={section.sectionTitle} style={styles.valueColumn}>
                <Text
                  style={[
                    styles.sectionTitle,
                    { color: theme.colors.text },
                    styles.textCenter,
                  ]}>
                  {section.sectionTitle}
                </Text>
                {labels.map(labelItem => (
                  <View
                    key={labelItem.fieldKey}
                    style={[
                      styles.cell,
                      { backgroundColor: theme.colors.background },
                    ]}>
                    <Text
                      style={[{ color: theme.colors.text }, styles.textCenter]}>
                      {getFlightTimeData(
                        section.dataKeys[labelItem.fieldKey],
                        leg?.id,
                      )}
                    </Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        ))}
      </View>
    );
  };

  return (
    <>
      <ScrollView style={styles.container}>
        {updateMode === 'leg' ? (
          trip.legs.map((leg, index) => (
            <View key={leg.id}>
              <View
                style={[
                  styles.legHeaderContainer,
                  { borderBottomColor: theme.colors.white },
                ]}>
                <Text
                  style={[styles.legIndexText, { color: theme.colors.text }]}>
                  Leg {index + 1}
                </Text>
                <StyledIconButton
                  icon="edit"
                  backgroundColor={theme.colors.primary}
                  onPress={() => {
                    handleSelectedLegChange(leg);
                    openModal(leg.id);
                  }}
                />
              </View>
              {renderMainInfo(leg)}
            </View>
          ))
        ) : (
          <View>
            <View
              style={[
                styles.legHeaderContainer,
                { borderBottomColor: theme.colors.white },
              ]}>
              <StyledIconButton
                icon="edit"
                backgroundColor={theme.colors.primary}
                onPress={() => {
                  openModal(null);
                }}
                hasMargin
              />
            </View>
            {renderMainInfo(null)}
          </View>
        )}
      </ScrollView>

      {isModalVisible ? (
        <FlightTimesModal
          onClose={closeModal}
          legId={selectedLegId}
          trip={trip}
          // trip={{ ...trip, metering: updatedMetering }}
          aircraft={aircraft}
          isHobbs={isHobbs}
          showAPU={showAPU}
          updateMode={updateMode}
        />
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  legHeaderContainer: {
    borderBottomWidth: 1,
    padding: 12,
    marginTop: 22,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  legIndexText: {
    fontSize: 18,
  },
  infoContainer: {
    marginVertical: 16,
    paddingHorizontal: 12,
  },
  legHeader: {
    fontSize: 18,
    marginBottom: 16,
    marginTop: 16,
  },
  rowContainer: {
    flexDirection: 'row',
    marginBottom: 16,
  },
  labelColumn: {
    width: '18%',
  },
  valueColumn: {
    width: '25%',
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: 8,
  },
  cell: {
    paddingVertical: 14,
    paddingHorizontal: 4,
    marginVertical: 2,
  },
  textCenter: {
    textAlign: 'center',
  },
  labelCell: {
    paddingLeft: 6,
  },
});
