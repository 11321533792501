/**
 * @format
 * @flow strict-local
 */

import React, { Node } from 'react';
import type { NavigationProp } from '@react-navigation/native';
import { useForm } from 'react-hook-form';
import styled from '@emotion/native';
import { Platform, TouchableOpacity, ScrollView } from 'react-native';

import Button, { FitToContentButton } from '@appComponents/Button';
import Notification from '@appComponents/Notification';
import Text from '@appComponents/Text';
import { EmailFormField, PasswordFormField } from './FormFields';
import { Box, Spacer } from '../ScreenLayout';
import { getNativeButtonLabelStyle } from '../../mobile/components/styles/labelStyles';
import { JoinCompanyButton } from '../../web/screens/auth/AuthLayout';
import Icon from '@appComponents/theme/Icon';
import { useTheme } from '@appComponents/theme';

/* TODO: Refactor so LoginForm is not needed or children are passed to the LoginForm
   See https://bitbucket.org/aviatainc/flightworks-combined/pull-requests/41/feat-fw-175-main-trips-screen-for-owners#comment-263307964
*/

const LoginForm = ({ navigation, onSubmit }: LoginFormProps): Node => {
  const theme = useTheme();
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const submitCallback = React.useCallback(
    payload =>
      onSubmit(payload).catch(error =>
        setError('general', { type: 'manual', message: error.message }),
      ),
    [onSubmit, setError],
  );

  return (
    <ScrollView>
      <Box>
        <Text size="medium">Enter your email address</Text>
        <Spacer size={0.25} />
        <EmailFormField control={control} showLabel={false} />
      </Box>
      {Platform.OS !== 'web' && <Spacer />}

      <Box>
        <Text size="medium">Enter your password</Text>
        <Spacer size={0.25} />
        <PasswordFormField control={control} showLabel={false} />
      </Box>
      {Platform.OS !== 'web' && <Spacer />}

      <ForgotPassword navigation={navigation} />
      <Spacer />
      <LoginButton onPress={handleSubmit(submitCallback)} />

      {Platform.OS !== 'web' && (
        <Row>
          <Text color="heading">Don't have an account?</Text>
          <CreateAccountButton
            mode="text"
            color="secondary"
            uppercase={false}
            onPress={() => navigation.push('Create Account')}>
            <LimeText>Sign Up</LimeText>
          </CreateAccountButton>
        </Row>
      )}

      {Platform.OS === 'web' && (
        <>
          <Spacer size={2} />
          <Divider />
          <Spacer />
          <Text size="mediumLarge">Don't have an account?</Text>
          <Spacer />
          <Box dir="row" jc="space-between" wrap="wrap">
            <PaddedButton
              mode="outlined"
              onPress={() => navigation.navigate('Create Company')}>
              <Text color="primary">CREATE ACCOUNT</Text>
            </PaddedButton>
            <JoinCompanyButton
              onPress={() => navigation.navigate('Join Company')}
            />
          </Box>
        </>
      )}
      <Row>
        <Notification
          color="error"
          visible={errors?.general}
          onDismiss={() => clearErrors()}>
          {errors?.general?.message}
        </Notification>
      </Row>
      <Spacer size={5} />
      {Platform.OS === 'web' && (
        <>
          <Box width="fit-content" dir="row" ai="center">
            <a href="https://flightapp.io/" target="_blank">
              <Text size="medium" color="primary">
                Learn more about FlightApp!
              </Text>
            </a>
            <Spacer dir="horizontal" size={0.5} />
            <Icon name="forward-arrow" size={20} color={theme.colors.primary} />
          </Box>
          <Spacer size={2} />
        </>
      )}
    </ScrollView>
  );
};

type LoginFormProps = {
  navigation: NavigationProp,
  onSubmit: () => void,
};

const PaddedButton = styled(FitToContentButton)(({ theme }) => ({
  paddingHorizontal: theme.layout.space(1),
}));

const Row = styled.View`
  flex-direction: row;
  align-items: center;
`;

const LoginButton = ({ onPress }) =>
  Platform.OS === 'web' ? (
    <WebLoginButton onPress={onPress} color="mobilePrimary">
      <Text color="background">LOGIN</Text>
    </WebLoginButton>
  ) : (
    <MobileLoginButton
      onPress={onPress}
      labelStyle={getNativeButtonLabelStyle()}>
      <Text>Login</Text>
    </MobileLoginButton>
  );

const WebLoginButton = styled(Button)(({ theme }) => ({
  paddingVertical: theme.layout.space(0.5),
}));

const MobileLoginButton = styled(Button)(({ theme }) => ({
  minHeight: 48,
  marginTop: theme.layout.space(3),
  marginBottom: theme.layout.space(2),
}));

const ForgotPassword = ({ navigation }) => (
  <Box dir="row" jc="space-between">
    {Platform.OS !== 'web' ? (
      <NativeForgotPasswordButton
        mode="text"
        color="secondary"
        uppercase={true}
        onPress={() => navigation.push('Forgot Password')}>
        <LimeText fontSize="18px">Forgot Password</LimeText>
      </NativeForgotPasswordButton>
    ) : (
      <FitToContentButton
        mode="text"
        onPress={() => navigation.navigate('Forgot Password')}
        ml="auto">
        Forgot Password?
      </FitToContentButton>
    )}
  </Box>
);

const NativeForgotPasswordButton = styled(Button)(({ theme }) => ({
  alignSelf: 'flex-start',
  marginBottom: theme.layout.space(0.5),
}));

const CreateAccountButton = styled(TouchableOpacity)(({ theme }) => ({
  alignSelf: 'flex-start',
  marginBottom: theme.layout.space(0.5),
}));

const Divider = styled.View(({ theme }) => ({
  height: '2px',
  backgroundColor: theme.colors.text,
}));

const LimeText = styled(Text)`
  color: #66ff99;
  font-size: ${props => props.size || '16px'};
  font-weight: 400;
  margin-left: 5px;
`;

export default LoginForm;
