/**
 * @file
 * A component displaying totals due to Pilots based on Expenses and Adjustments
 *
 * @format
 * @flow strict-local
 */
import React from 'react';
import AccountingUsers from '@webComponents/Accounting/AccountingUsers';
import { AccountingTab } from '.';

const AccountingPilots = ({ userTotals, company }) => {
  const userDueText = 'Due To Pilot';
  return (
    <AccountingUsers
      userTotals={userTotals}
      userDueText={userDueText}
      tab={AccountingTab.PILOTS}
      company={company}
    />
  );
};

export default AccountingPilots;
