import React, { useState } from 'react';
import styled from '@emotion/native';
import _ from 'lodash';
import { DateTime } from 'luxon';

import Button, { LinkButton } from '@appComponents/Button';
import {
  Circle,
  Header,
  MainSurface,
  ScreenLayout,
  ScreenLoader,
} from '@appComponents/ScreenLayout';
import { useAircraftDialog } from '../../components/AircraftDialog';
import { useAircraftList } from '@appUtils/aircraft';
import DataTable, { Cell, PlainCell } from '@appComponents/DataTable';
import Text, { PersonText } from '@appComponents/Text';
import { SquawkLegend } from '../../components/SquawkLegend';
import {
  getSquawkColorByPriority,
  SquawkPriority,
  SquawkStatus,
} from '@appUtils/squawks';
import { ScrollView } from 'react-native';
import { useCompanyData } from '@appUtils/api';
import { Menu, useMenuState } from '@appComponents/Menu';
import app from '@appFirebase';
import { useAsyncCallback } from 'react-async-hook';
import ConfirmationDialog from '../../components/ConfirmationDialog';

const Aircraft = ({ navigation }) => {
  const { loading, data, error } = useAircraftList();
  const { dialogNode, openDialog } = useAircraftDialog({ mode: 'Add' });
  const [companyData] = useCompanyData();
  const [showAddAircraftError, setShowAddAircraftError] = useState(false);
  let emptyMessage = <Text>No Aircraft Available</Text>;

  if (loading && _.isEmpty(data)) {
    emptyMessage = <Text>Loading...</Text>;
  }
  if (error) {
    emptyMessage = <Text>Failed to load aircraft. Try again later</Text>;
  }

  const handleNewAircraft = () => {
    if (
      companyData.subscriptionId &&
      companyData.aircraftQuantity &&
      companyData.aircraftQuantity <= data.length
    ) {
      setShowAddAircraftError(true);
    } else {
      openDialog();
    }
  };

  return (
    <ScreenLayout alignItems="stretch">
      <Header>
        {showAddAircraftError && (
          <Text color="error">
            You've reached the maximum number of aircraft for your subscription.
            Please upgrade your subscription in Settings to add more aircraft.
          </Text>
        )}
        <AddAircraftButton color="accent" onPress={handleNewAircraft}>
          Add Aircraft
        </AddAircraftButton>
      </Header>

      {dialogNode}

      <MainSurface>
        {loading && <ScreenLoader />}
        <SquawkLegend />
        <ScrollView>
          <DataTable
            data={data}
            emptyMessage={emptyMessage}
            keyExtractor={(aircraft, index) => aircraft?.tailNumber ?? index}>
            <TailCell title="Tail #" navigation={navigation} />
            <PlainCell title="Name" path="name" />
            <PlainCell title="Type" path="type" />
            <NameCell title="Primary Owner" />
            <PlainCell title="Location" path="location" />
            <LastTripCell title="Last Trip" />
            <FuelCell title="Fuel Off" flex={0.5} numeric />
            <ActionsCell name="actions" numeric icon="more-dots" />
          </DataTable>
        </ScrollView>
      </MainSurface>
    </ScreenLayout>
  );
};

const TailCell = ({ item, navigation, ...cellProps }) => {
  const tail = item.tailNumber;
  const squawkPriority =
    item.squawkPriority ?? SquawkPriority[SquawkStatus.NO_SQUAWKS];
  const squawkColor = getSquawkColorByPriority(squawkPriority);

  const labelStyle = { marginHorizontal: 0 };

  return (
    <Cell {...cellProps}>
      <Circle color={squawkColor} size={10} />
      <LinkButton
        toScreen="Aircraft Details"
        params={{ documentPath: item.path }}
        mode="text"
        labelStyle={labelStyle}>
        {tail}
      </LinkButton>
    </Cell>
  );
};

const NameCell = ({ item, flex }) => (
  <Cell flex={flex}>
    <PersonText entry={_.get(item, 'owners.0')} />
  </Cell>
);

const LastTripCell = ({ item, ...cellProps }) => {
  const lastUpdate = item?.dateUpdated;
  if (!lastUpdate) {
    return <Cell {...cellProps}>-</Cell>;
  }

  const date = DateTime.fromSeconds(lastUpdate.seconds).toLocaleString(
    DateTime.DATE_MED,
  );

  return <Cell {...cellProps}>{date}</Cell>;
};

const FuelCell = ({ item, ...cellProps }) => (
  <PlainCell
    path="fuelOff"
    suffix={` ${item?.fuelUnits ?? 'gal'}`}
    row={item}
    {...cellProps}
  />
);

const AddAircraftButton = styled(Button)`
  margin-left: auto;
`;

export default Aircraft;

const ActionsCell = React.memo(
  ({ item: aircraft, label, icon, ...cellProps }) => {
    const { action, open, ...menu } = useStateActions([aircraft]);

    return (
      <Cell ref={menu.anchorEl} {...cellProps}>
        <Button
          label={label}
          loading={action.loading}
          mode="text"
          icon={icon}
          onPress={open}
        />
        <Actions aircraft={aircraft} action={action} {...menu} />
      </Cell>
    );
  },
  (a, b) => a.item.state === b.item.state,
);

const getActionsForState = aircraft => {
  return [
    {
      nextState: 'Archive',
      title: 'Archive',
      modalTitle: 'Archive Aircraft',
      modalContent:
        'Are you sure you want to archive this aircraft? You can restore it later.',
    },
  ];
};

const useStateActions = aircrafts => {
  const { close, ...menu } = useMenuState();
  const action = useAsyncCallback(async nextState => {
    const batch = app.firestore().batch();
    aircrafts.forEach(aircraft => {
      const doc = app.firestore().doc(aircraft.path);
      const updates = {
        archived: true,
      };
      batch.update(doc, updates);
    });
    close();
    return batch.commit();
  });

  return { action, close, ...menu };
};

const Actions = ({ anchorEl, isOpen, close, action, aircraft }) => (
  <Menu anchor={anchorEl} visible={isOpen} onDismiss={close}>
    {getActionsForState(aircraft).map(entry => (
      <ActionItem key={entry.title} action={action} {...entry} />
    ))}
  </Menu>
);

const ActionItem = ({ title, action, nextState, modalTitle, modalContent }) => {
  const handlePress = () => {
    action.execute(nextState);
  };
  return (
    <ConfirmationDialog
      title={title}
      modalTitle={modalTitle}
      modalContent={modalContent}
      onPress={handlePress}
    />
  );
};
