import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/native';

import {
  Box,
  TabContainer,
  MainSurface,
  ScreenLayout,
  ScreenLoader,
  Spacer,
} from '@appComponents/ScreenLayout';
import Tabs from '@appComponents/Tabs';
import Text from '@appComponents/Text';
import { getUserTotals } from '@appUtils/accounting';
import { UserRole } from '@appUtils/tripConverter';
import { useAllNonArchivedTrips } from '@appUtils/manager';
import { formatNumber } from '@appUtils/numbers';
import { useCompanyUsers, useCompanyData } from '@appUtils/api';
import AccountingOwners from './AccountingOwners';
import AccountingPilots from './AccountingPilots';
import AccountingSetUp from './AccountingSetUp';
import { ScrollView } from 'react-native';

const Accounting = ({ route }) => {
  const [activeTab, setActiveTab] = useState(
    route.params?.tab ?? AccountingTab.OWNERS,
  );
  const [company, companyLoading] = useCompanyData();
  const { data: trips } = useAllNonArchivedTrips({});
  const [owners] = useCompanyUsers(UserRole.OWNER);
  const { totalDue: totalDueFromOwners, ...ownerTotals } = getUserTotals(
    trips,
    owners,
    UserRole.OWNER,
  );
  const [pilots] = useCompanyUsers([
    UserRole.PILOT,
    UserRole.MANAGER,
    UserRole.EMPLOYEE,
  ]);

  const { totalDue: totalDueToPilots, ...pilotTotals } = getUserTotals(
    trips,
    pilots,
    UserRole.PILOT,
  ) || { totalDue: 0 };

  const loading = companyLoading;

  if (loading) {
    return <ScreenLoader />;
  }

  return (
    <ScreenLayout alignItems="stretch">
      <AccountingTotals
        totalDueFromOwners={totalDueFromOwners}
        totalDueToPilots={totalDueToPilots}
      />
      <AccountingHeader>
        <AccountingTabs activeTab={activeTab} onChange={setActiveTab} />
      </AccountingHeader>
      <MainSurface borderTopLeftRadius={0}>
        <ScrollView>
          {loading && <ScreenLoader />}
          {!loading && activeTab === AccountingTab.OWNERS && company && (
            <AccountingOwners userTotals={ownerTotals} company={company} />
          )}
          {!loading && activeTab === AccountingTab.PILOTS && company && (
            <AccountingPilots userTotals={pilotTotals} company={company} />
          )}
          {!loading && activeTab === AccountingTab.SETUP && <AccountingSetUp />}
        </ScrollView>
      </MainSurface>
    </ScreenLayout>
  );
};

const AccountingTotals = ({ totalDueFromOwners, totalDueToPilots }) => (
  <Box dir="row" jc="space-between" pl={1} pr={1.5} mb={1}>
    <TotalBox>
      <Text size="medium">Current Due from Owners</Text>
      <Spacer size={0.5} />
      <Text color="#5AFFC7" size="large">
        ${formatNumber({ value: totalDueFromOwners.toFixed(2) })}
      </Text>
    </TotalBox>
    <TotalBox>
      <Text size="medium">Current Due to Pilots</Text>
      <Spacer size={0.5} />
      <Text color="#5AFFC7" size="large">
        ${formatNumber({ value: totalDueToPilots.toFixed(2) })}
      </Text>
    </TotalBox>
    <TotalBox>
      <Text size="medium">Current Net (Owners-Pilots=Net)</Text>
      <Spacer size={0.5} />
      <Text color="#5AFFC7" size="large">
        $
        {formatNumber({
          value: totalDueFromOwners.toFixed(2) - totalDueToPilots.toFixed(2),
          decimalScale: 2,
        })}
      </Text>
    </TotalBox>
  </Box>
);

const TotalBox = styled(Box)(({}) => ({
  borderRadius: 8,
  backgroundColor: 'rgba(255, 255, 255, 0.06)',
  width: '32%',
  padding: 15,
}));

export const AccountingTab = {
  OWNERS: 'OWNERS',
  PILOTS: 'PILOTS',
  SETUP: 'EXPENSE SET UP',
};

const getAccounting = () => [
  { key: 'owners', title: AccountingTab.OWNERS },
  { key: 'pilots', title: AccountingTab.PILOTS },
  { key: 'setup', title: AccountingTab.SETUP },
];

const AccountingTabs = ({ activeTab, onChange }) => {
  const [routes] = useState(getAccounting);
  const handleChange = useCallback(
    ({ route }) => onChange(route.title),
    [onChange],
  );

  return (
    <Tabs
      tabIndex={routes.findIndex(tab => tab.title === activeTab)}
      onTabPress={handleChange}
      routes={routes}
      tabBarInline
    />
  );
};

const AccountingHeader = styled(TabContainer)`
  padding-bottom: 0;
`;

export default Accounting;
