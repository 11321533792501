import React, { useState } from 'react';
import Dialog from '../../components/Dialog';
import { MenuItem } from '../../components/Menu';
import { View } from 'react-native-web';
import Button from '@appComponents/Button';
import Text from '@appComponents/Text';
const ConfirmationDialog = ({
  title,
  modalTitle,
  onPress,
  modalContent = '',
  disabled = false,
  error = '',
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <MenuItem title={title} onPress={() => setVisible(true)} />
      <Dialog
        visible={visible}
        title={modalTitle}
        onDismiss={() => setVisible(false)}
        actionSlot={
          <View style={{ flexDirection: 'row', gap: 8 }}>
            <Button onPress={() => setVisible(false)}>Cancel</Button>
            <Button color="error" disabled={disabled} onPress={onPress}>
              Confirm
            </Button>
          </View>
        }>
        <Text>{modalContent}</Text>
        {error && (
          <Text style={{ marginTop: 16 }} color="error">
            {error}
          </Text>
        )}
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
