/**
 * @file
 * Trip related hooks and APIs
 *
 * @format
 * @flow strict-local
 */
import { useCallback, useEffect, useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import _ from 'lodash';

import app, {
  FieldValue,
  getUid,
  useCollection,
  useDocument,
  useDocumentData,
} from '@appFirebase';
import tripConverter, {
  OwnerState,
  PilotState,
  Trip,
  TripState,
  UserRole,
} from '@appUtils/tripConverter';

export const useTrip = documentPath => {
  const docRef = documentPath && app.firestore().doc(documentPath);
  const [snapshot, loading, error] = useDocument<Trip>(docRef);
  useEffect(() => error && console.error(error), [error]);

  const data = useMemo(() => {
    if (!snapshot?.exists) {
      return {};
    }

    return tripConverter.fromFirestore(snapshot);
  }, [snapshot]);

  return {
    // when documentPath changes the first few re-renders don't pick up we're loading a new document
    loading: loading || (documentPath && !snapshot),
    error,
    data,
    snapshot,
  };
};

export const useOwnerTrips = (id = getUid()) => {
  const query = useCallback(
    ref => {
      let qb = ref.collection('trips').where('owner.id', '==', id);
      return qb.orderBy('dateDeparting', 'desc');
    },
    [id],
  );

  return useTripList(query);
};

export const useAllMobileUserTrips = (archived = false) => {
  const {
    data: mainTrips,
    loading: mainLoading,
    error: mainError,
  } = useMobileUserTrips(archived);
  const {
    data: passengerTrips,
    loading: passengerLoading,
    error: passengerError,
  } = useMobilePassengerTrips(archived);
  const {
    data: pilotMadeTrips,
    loading: pilotMadeLoading,
    error: pilotMadeError,
  } = useMobilePilotMadeTrips(archived);

  const loading = mainLoading || passengerLoading || pilotMadeLoading;
  const error = mainError ?? passengerError ?? pilotMadeError;
  const allTripIds = new Set();
  const allTrips = new Set();
  mainTrips?.map(t => {
    allTripIds.add(t.id);
    allTrips.add(t);
  });
  passengerTrips?.map(t => {
    if (!allTripIds.has(t.id)) {
      allTripIds.add(t.id);
      allTrips.add({ ...t, isPassenger: true });
    }
  });
  pilotMadeTrips?.map(t => {
    if (!allTripIds.has(t.id)) {
      allTripIds.add(t.id);
      allTrips.add(t);
    }
  });

  return { trips: Array.from(allTrips), loading, error };
};

const useMobileUserTrips = (archived = false) => {
  const [user] = useMyData();

  const query = useCallback(
    ref => {
      if (!user) {
        return undefined;
      }

      return getUserTripsQuery(ref, user, archived);
    },
    [archived, user],
  );

  return useTripList(query);
};

const useMobilePassengerTrips = (archived = false) => {
  const query = useCallback(
    ref => {
      return getPassengerTripsQuery(ref, archived);
    },
    [archived],
  );

  return useTripList(query);
};

const useMobilePilotMadeTrips = (archived = false) => {
  const query = useCallback(
    ref => {
      return getPilotMadeTripsQuery(ref, archived);
    },
    [archived],
  );

  return useTripList(query);
};

export const getUserTripsQuery = (companyRef, userData, archived = false) => {
  const id = getUid();
  let qb = companyRef.collection('trips').where('archived', '==', archived);
  // TODO FW-742 Make sure this works for passengers
  if (userData.role === UserRole.OWNER) {
    qb = qb
      .where('owner.id', '==', id)
      .where('owner.state', '!=', 'Manager Draft')
      .orderBy('owner.state', 'desc');
  } else if (userData.role === UserRole.PASSENGER) {
    qb = qb
      .where('_passengerIds', 'array-contains', id)
      .where('state', 'in', [
        TripState.UPCOMING,
        TripState.ACTIVE,
        TripState.ENDED,
        TripState.CANCELLED,
      ]);
  } else {
    const allowedStates = _.filter(
      PilotState,
      state => state !== PilotState.MANAGER_DRAFT,
    );
    qb = qb.where(
      'pilotStates',
      'array-contains-any',
      allowedStates.map(state => ({ id, state })),
    );
  }

  return qb.orderBy('dateDeparting', 'desc');
};

export const getPassengerTripsQuery = (companyRef, archived = false) => {
  const id = getUid();
  let qb = companyRef
    .collection('trips')
    .where('archived', '==', archived)
    .where('_passengerIds', 'array-contains', id)
    .where('state', 'in', [
      TripState.OWNER_REQUEST,
      TripState.DRAFT,
      TripState.UPCOMING,
      TripState.ACTIVE,
      TripState.ENDED,
      TripState.CANCELLED,
    ]);

  return qb.orderBy('dateDeparting', 'desc');
};

export const getPilotMadeTripsQuery = (companyRef, archived = false) => {
  const id = getUid();
  let qb = companyRef
    .collection('trips')
    .where('archived', '==', archived)
    .where('createdBy', '==', id);

  return qb.orderBy('dateDeparting', 'desc');
};

export const useTripList = (query = 'trips') =>
  useList<Trip>(query, tripConverter);

export function useList<TItem>(
  companyCollection,
  converter = null,
  dataFilter = () => true,
) {
  const [company, , companyError] = useMyCompanyRef();

  const query = useMemo(() => {
    if (!company) {
      return undefined;
    }

    const ref =
      typeof companyCollection === 'function'
        ? companyCollection(company)
        : company.collection(companyCollection);

    return ref;
  }, [company, companyCollection]);

  const [snapshot, , snapError] = useCollection<TItem>(query);
  const error = companyError || snapError;
  useEffect(() => error && console.error(error), [error]);

  const data = useMemo(
    () =>
      _.chain(snapshot?.docs)
        .map(doc => {
          const path = doc.ref.path;

          if (converter) {
            return converter.fromFirestore(doc);
          }

          return { ...doc.data(), path };
        })
        .filter(dataFilter)
        .value(),
    [converter, dataFilter, snapshot?.docs],
  );

  return {
    loading: !snapshot && !error,
    error,
    data,
    snapshot,
  };
}

export const useCreateDraftCallback = () => useAsyncCallback(createDraft);

export const useCompanyData = () => {
  const [ref, refLoading] = useMyCompanyRef();
  const [company, loading] = useDocumentData(ref);

  return [{ ...company, id: ref?.id }, loading || refLoading];
};

// This pulls user data from the management company document
export const useCompanyUsers = (
  roles = UserRole.EMPLOYEE,
  archived = false,
) => {
  const [company, loading] = useCompanyData();

  const list = useMemo(() => {
    const set = new Set(Array.isArray(roles) ? roles : [roles]);
    return _.filter(
      company?.users,
      user =>
        user?.roles?.some(role => set.has(role)) &&
        // matches the archived value or if they don't have one we'll assume not archived
        (_.isUndefined(user?.archived) || user.archived === archived),
    );
  }, [archived, company?.users, roles]);

  return [list, loading];
};

// This pulls user data from the users collection
export const useCompanyUsersFull = ({
  roles = [UserRole.EMPLOYEE],
  archived = false,
}) => {
  const [company, loading] = useCompanyData();
  let query;

  if (!loading && company) {
    query = app
      .firestore()
      .collection('users')
      .where('role', 'in', roles)
      .where('managementCompany.docId', '==', company.id)
      .orderBy('dateCreated', 'asc');
  }
  const [snapshot, , snapError] = useCollection(query);

  const data = useMemo(
    () =>
      _.chain(snapshot?.docs)
        .map(doc => {
          const path = doc.ref.path;
          const docData = doc.data();
          const name = `${docData?.firstName} ${docData?.lastName}`;
          return { ...docData, id: doc.id, path, name };
        })
        // matches the archived value or if they don't have one we'll assume not archived
        .filter(user => {
          if (_.isUndefined(user?.archived)) {
            return archived === false;
          } else {
            return user.archived === archived;
          }
        })
        .value(),
    [archived, snapshot?.docs],
  );

  return {
    loading: !snapshot && !snapError,
    error: snapError,
    data,
    snapshot,
  };
};

export const createDraft = async (defaults = {}) => {
  const companyRef = await getMyCompanyRef();
  const tripRef = companyRef.collection('trips').doc();

  const payload = tripConverter.toFirestore({
    aircraft: { tailNumber: null },
    legs: [],
    pilots: [],
    state: TripState.DRAFT,
    createdBy: getUid(),
    departingFrom: '',
    customName: '',
    archived: false,
    ...defaults,
  });

  payload.dateCreated = FieldValue.serverTimestamp();
  await tripRef.set(payload);

  return tripRef;
};

/**
 * Use this hook when you're listening for data
 * It will first load from cache making lists appear instantaneous
 * Use `getCompanyRef` to ensure the most recent data when you're making updates (e.g. create draft)
 * @returns {[*,boolean,firebase.FirebaseError]}
 */
export const useMyCompanyRef = () => {
  const [user, loading, error] = useMyData();
  return [user?.managementCompany?.ref, loading, error];
};

export const useMyRole = () => {
  const [user, loading, error] = useMyData();
  if (error || loading) {
    return ['', loading, error];
  }
  return [user?.role, loading, error];
};

export const useMyData = () => useDocumentData(getMyUserDoc());

export const getMyUserDoc = () =>
  app.firestore().collection('users').doc(getUid());

export const getMyCompanyRef = async () => {
  const user = await getUserData(getUid());
  return user.managementCompany.ref;
};

export const getUserData = async (uid = getUid()) => {
  const snap = await app.firestore().collection('users').doc(uid).get();
  return { id: snap.id, ...snap.data() };
};

export const getUserDoc = uid => app.firestore().collection('users').doc(uid);

/**
 * Remove unseen change from the trip and sets the SEEN status when appropriate
 * @param trip
 * @return {Promise<unknown>}
 */
export const markSeen = async (trip: Trip) => {
  const updates = {
    unseenChanges: {
      ...trip.unseenChanges,
      [getUid()]: [],
    },
  };

  const isOwner = trip.owner.id === getUid();

  if (isOwner) {
    if (trip.owner.state === OwnerState.MANAGER_UPDATED) {
      const company = await getMyCompanyRef();

      updates.owner = { ...trip.owner, state: OwnerState.OWNER_SEEN };
      updates.unseenChanges[company.id] = _.union(
        updates.unseenChanges?.[company.id],
        ['owner.state'],
      );
    }
  } else {
    const index = _.findIndex(trip.pilots, p => p.id === getUid());
    const pilot = _.get(trip.pilots, index);

    if (pilot?.state === PilotState.MANAGER_UPDATED) {
      const company = await getMyCompanyRef();
      updates.pilots = trip.pilots.map(p => ({
        ...p,
        state: p.id === getUid() ? PilotState.PILOT_SEEN : p.state,
      }));
      updates.unseenChanges[company.id] = _.union(
        updates.unseenChanges?.[company.id],
        [`pilots.${index}.state`],
      );
    }
  }

  const payload = tripConverter.toFirestore(updates);
  return app.firestore().doc(trip.path).update(payload);
};

export const countTrips = (trips = []) => {
  const counts = _.countBy(trips, trip => {
    const isSeen = _.isEmpty(trip.unseenChanges?.[getUid()]);

    if (!isSeen) {
      return 'unseen';
    }

    if (trip.owner?.id === getUid()) {
      if (trip.owner.state === OwnerState.MANAGER_REQUESTED) {
        return 'requested';
      }
    }

    const pilot = findMyPilotData(trip);
    if (pilot) {
      if (pilot.state === PilotState.MANAGER_REQUESTED) {
        return 'requested';
      }
    }

    return 'seen';
  });

  return _.merge({ seen: 0, unseen: 0, requested: 0 }, counts);
};

export const findMyPilotData = trip =>
  _.find(trip.pilots, p => p.id === getUid());

export const queryTripsInRange = (companyRef, from, to) =>
  companyRef
    .collection('trips')
    .where('archived', '==', false)
    .where('dateDeparting', '>=', from.toJSDate())
    .where('dateDeparting', '<=', to.toJSDate());

export const getOverlappingTrips = (id, { trips, from, to }) =>
  trips.filter(t => t.identifier !== id && isOverlapping(from, to, t));

const isOverlapping = (startBoundary, endBoundary, trip: Trip) => {
  const isActiveTrip = [
    TripState.OWNER_REQUEST,
    TripState.DRAFT,
    TripState.UPCOMING,
    TripState.ACTIVE,
  ].includes(trip.state);

  if (!isActiveTrip) {
    return false;
  }

  const tripStart = _.head(trip.legs)?.departureDate;
  const tripEnd = _.last(trip.legs)?.departureDate.endOf('day');

  return [
    [startBoundary, tripStart, tripEnd],
    [endBoundary, tripStart, tripEnd],
    [tripStart, startBoundary, endBoundary],
    [tripEnd, startBoundary, endBoundary],
  ].some(params => isBetweenDates(...params));
};

const isBetweenDates = (date, from, to) => from <= date && date <= to;

export const archiveOrDeleteUser = async (userId: string) => {
  const userDocRef = app.firestore().collection('users').doc(userId);
  const userSnapshot = await userDocRef.get();

  if (userSnapshot.exists) {
    const userData = userSnapshot.data();

    if (!userData?.archived) {
      await userDocRef.update({ archived: true });

      const managementCompanyRef = app
        .firestore()
        .collection('managementCompanies')
        .doc(userData.managementCompany.docId);

      const managementCompanySnapshot = await managementCompanyRef.get();
      const managementCompanyData = managementCompanySnapshot.data();

      if (managementCompanyData?.users) {
        const updatedUsers = managementCompanyData.users.map(user => {
          if (user.id === userId) {
            return { ...user, archived: true };
          }
          return user;
        });

        await managementCompanyRef.update({ users: updatedUsers });
      }
    } else if (userData.archived) {
      await userDocRef.update(
        {
          managementCompany: FieldValue.delete(),
        },
        { merge: true },
      );

      const managementCompanyRef = app
        .firestore()
        .collection('managementCompanies')
        .doc(userData.managementCompany.docId);
      const managementCompanySnapshot = await managementCompanyRef.get();
      const managementCompanyData = managementCompanySnapshot.data();

      if (managementCompanyData?.users) {
        const updatedUsers = managementCompanyData.users.filter(
          u => u.id !== userId,
        );
        await managementCompanyRef.update({ users: updatedUsers });
      }
    }
  }
};

export const restoreUser = async (userId: string) => {
  const userDocRef = app.firestore().collection('users').doc(userId);
  const userSnapshot = await userDocRef.get();

  if (userSnapshot.exists) {
    const userData = userSnapshot.data();

    if (userData?.archived) {
      await userDocRef.update({ archived: FieldValue.delete() });

      const managementCompanyRef = app
        .firestore()
        .collection('managementCompanies')
        .doc(userData.managementCompany.docId);
      const managementCompanySnapshot = await managementCompanyRef.get();
      const managementCompanyData = managementCompanySnapshot.data();

      if (managementCompanyData?.users) {
        const updatedUsers = managementCompanyData.users.map(user => {
          if (user.id === userId) {
            return { ...user, archived: false };
          }
          return user;
        });
        await managementCompanyRef.update({ users: updatedUsers });
      }
    }
  }
};

export const hasAlreadyBeenAddedToCalendar = (trip, legIndex) => {
  const myEvents = trip?.addedCalendarEvents?.[getUid()] ?? [];
  return myEvents.includes(legIndex);
};

export const trackAddedCalendarEvent = async (
  trip: Trip,
  legIndex: number,
  id = getUid(),
) => {
  const updates = {
    [`addedCalendarEvents.${id}`]: FieldValue.arrayUnion(legIndex),
  };

  return app.firestore().doc(trip.path).update(updates);
};
