import React, { useEffect } from 'react';
import { HeaderBorderBottom, ContentHeader, Content } from './Settings';
import Text from '@appComponents/Text';
import styled from '@emotion/native';
import { View } from 'react-native';
import {
  FirstNameFormField,
  LastNameFormField,
  EmailFormField,
  PhoneNumberFormField,
  PasswordFormField,
  WeightFormField,
} from './fields';

export const PersonalDetailsForm = ({ setVisible, form }) => {
  useEffect(() => {
    if (form.formState.isDirty) {
      setVisible(true);
    }
  }, [form.formState.isDirty, setVisible]);

  return (
    <Content>
      <HeaderBorderBottom style={{ marginBottom: 30 }}>
        <ContentHeader>
          <Text>PERSONAL DETAILS</Text>
        </ContentHeader>
      </HeaderBorderBottom>

      <Row>
        <FieldWrapper>
          <FirstNameFormField control={form.control} />
        </FieldWrapper>
        <FieldWrapper>
          <LastNameFormField control={form.control} />
        </FieldWrapper>
      </Row>
      <Row>
        <FieldWrapper>
          <EmailFormField control={form.control} />
        </FieldWrapper>
        <FieldWrapper>
          <PhoneNumberFormField control={form.control} />
        </FieldWrapper>
      </Row>

      <Row>
        <FieldWrapper>
          <WeightFormField control={form.control} />
        </FieldWrapper>
      </Row>

      <HeaderBorderBottom style={{ marginBottom: 30, marginTop: 30 }}>
        <ContentHeader>
          <Text>CHANGE PASSWORD</Text>
        </ContentHeader>
      </HeaderBorderBottom>

      <FieldWrapper>
        <PasswordFormField control={form.control} />
      </FieldWrapper>
    </Content>
  );
};

const FieldWrapper = styled(View)({
  paddingHorizontal: 16,
  width: '50%',
});

const Row = styled(View)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
}));
