/**
 * @file Message related functions
 */
import app, { FieldValue, useCollection, DocumentData } from '@appFirebase';
import { useMyCompanyRef, getMyCompanyRef, getMyUserDoc } from './api';
import _ from 'lodash';

export const useExpenseComments = (tripId = '', expenseId = '') => {
  const [companyRef] = useMyCompanyRef();
  let query;
  if (companyRef) {
    query = companyRef
      .collection('expenseComments')
      .where('tripId', '==', tripId)
      .where('expenseId', '==', expenseId);
  }
  const [snapshots] = useCollection(query);
  if (snapshots?.docs[0]) {
    return expenseCommentConverter.fromFirestore(snapshots.docs[0]);
  }
};

export const useExpenseHasComments = (tripId = '', expenseId = '') => {
  return useExpenseComments(tripId, expenseId) === undefined ? false : true;
};

export const useTripExpensesHaveComments = (tripId = '') => {
  const [companyRef] = useMyCompanyRef();
  let query;
  if (companyRef) {
    query = companyRef
      .collection('expenseComments')
      .where('tripId', '==', tripId);
  }
  const [snapshots] = useCollection(query);
  const expensesHaveComments = {};
  if (snapshots?.docs[0]) {
    for (let doc of snapshots.docs) {
      const data = expenseCommentConverter.fromFirestore(doc);
      expensesHaveComments[data?.expenseId] = true;
    }
  }
  return expensesHaveComments;
};

export const useTripHasExpenseComments = (tripId = '') => {
  const [companyRef] = useMyCompanyRef();
  let query;
  if (companyRef) {
    query = companyRef
      .collection('expenseComments')
      .where('tripId', '==', tripId);
  }
  const [snapshots] = useCollection(query);
  if (snapshots?.docs[0]) {
    return true;
  }
  return false;
};

export const addExpenseComment = async (commentsId, text, tripId, expense) => {
  const user = await getMyUserDoc().get();
  const userData = user.data();
  const companyRef = await getMyCompanyRef();
  const commentsRef = companyRef.collection('expenseComments').doc(commentsId);
  const newComment = {
    author: {
      name: `${userData?.firstName} ${userData?.lastName}`,
      id: user?.id,
      role: userData?.role,
    },
    dateSent: FieldValue.serverTimestamp(),
    text,
  };

  app.firestore().runTransaction(async t => {
    const doc = await t.get(commentsRef);
    const expenseComments = doc.data();
    const nextCommentIndex = expenseComments?.nextCommentIndex ?? 0;
    const comments = {
      ...expenseComments?.messages,
      [nextCommentIndex]: newComment,
    };
    let updatedComments = {
      nextCommentIndex: nextCommentIndex + 1,
      comments: comments,
    };
    if (!commentsId) {
      updatedComments = setupNewExpenseComments({
        updatedComments,
        tripId,
        expense,
        companyRef,
      });
    } else {
      updatedComments = expenseCommentConverter.toFirestore(updatedComments);
    }

    t.set(commentsRef, updatedComments, { merge: true });
  });
};

const setupNewExpenseComments = ({
  updatedComments,
  tripId,
  expense,
  companyRef,
}) => {
  updatedComments.dateCreated = FieldValue.serverTimestamp();
  updatedComments.tripId = tripId;
  updatedComments.tripRef = companyRef.collection('trips').doc(tripId);
  updatedComments.expenseId = expense.id;
  return updatedComments;
};

export const deleteExpenseComments = async (
  companyRef,
  tripId = '',
  expenseId = '',
) => {
  let comments;
  if (companyRef) {
    comments = await companyRef
      .collection('expenseComments')
      .where('tripId', '==', tripId)
      .where('expenseId', '==', expenseId)
      .get();
  }
  if (comments?.docs[0]) {
    for (let doc of comments.docs) {
      doc.ref.delete();
    }
  }
};

export type ExpenseComments = {
  dateCreated: DateTime,
  comments: Array<{
    author: { id: string, name: string, role: string },
    date: DateTime,
    text: string,
  }>,
  nextCommentIndex: Number,
  tripId: string,
  tripRef: string,
  expenseId: string,
  id: string,
  path: string,
  ref: string,
};

const expenseCommentConverter = {
  toFirestore(expenseComments: ExpenseComments): DocumentData {
    /**
     * IMPORTANT
     * `toFirestore` should be able to work with partial data, it would not always receive a full expenseComments
     * derived fields should only be added if the data they are derived from is present
     * This allows us to send just the information that needs to update (delta) and helps with change tracking
     */
    return _.omitBy(
      expenseComments,
      // Ignore undefined values or keys that shouldn't be modified or included
      (value, key) =>
        _.isUndefined(value) ||
        _.includes(['dateCreated', 'path', 'id', 'ref'], key),
    );
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): ExpenseComments {
    const data = snapshot.data(options);
    return {
      ...data,
      ref: snapshot.ref,
      path: snapshot.ref.path,
      id: snapshot.ref.id,
    };
  },
};
