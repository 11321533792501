import React, { Node, useState } from 'react';
import { Controller, Control, useWatch } from 'react-hook-form';
import { Text } from 'react-native';
import styled from '@emotion/native';
import { HeaderText } from '@webComponents/Accounting/General';
import TextField, { PasswordField } from '@appComponents/TextField';
import RadioGroup from '@appComponents/RadioGroup';
import { useTheme } from '../theme';
import DialogDatePicker from '../../mobile/components/DialogDatePicker';
import * as Phone from '@appUtils/phone';
import { UserRole } from '@appUtils/tripConverter';
import { parenthesizedNumberToNegative } from '@appUtils/accounting';
import { formatNumber } from '@appUtils/numbers';
import SearchField from '@appComponents/SearchField';

const TextWrapper = styled(Text)(({ ml, w, h, aSelf }) => ({
  marginLeft: ml ?? 0,
  width: w ?? undefined,
  alignSelf: aSelf ?? undefined,
  height: h ?? undefined,
}));

export const RULES = optional => ({
  EMAIL: {
    ...(!optional && { required: 'Email is required' }),
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: 'Enter a valid email address',
    },
  },
  FIRST_NAME: {
    ...(!optional && { required: 'First Name is required' }),
    pattern: {
      value: /^(?!\s*$).+$/i,
      message: 'First Name cannot be blank',
    },
  },
  LAST_NAME: {
    ...(!optional && { required: 'Last Name is required' }),
    pattern: {
      value: /^(?!\s*$).+$/i,
      message: 'Last Name cannot be blank',
    },
  },
  COMPANY_NAME: {
    ...(!optional && { required: 'Company Name is required' }),
    pattern: {
      value: /^(?!\s*$).+$/i,
      message: 'Company Name cannot be blank',
    },
  },
  PHONE: {
    ...(!optional && { required: 'Phone Number is required' }),
    validate: value => {
      if (optional && !value) {
        return true;
      }

      return Phone.parse(value)?.isValid()
        ? true
        : 'Enter a valid 10-digit phone number';
    },
  },
});

export const TextFormField = ({
  label,
  name,
  control,
  rules,
  disabled = false,
  showLabel = true,
  ...rest
}: TextFormFieldProps): Node => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    shouldUnregister={false}
    render={({ field: { onChange, value, onBlur }, fieldState: { error } }) =>
      renderTextField({
        label: label,
        onChange: onChange,
        value: value,
        onBlur: onBlur,
        error: error,
        disabled: disabled,
        showLabel: showLabel,
        ...rest,
      })
    }
  />
);

export const CompanyNameFormField = ({
  control,
  optional = false,
  showLabel = true,
}: FormFieldProps): Node => (
  <TextFormField
    label="Enter your company name"
    name="companyName"
    placeholder="Company name"
    control={control}
    rules={RULES(optional).COMPANY_NAME}
    showLabel={showLabel}
  />
);

export const NumberFormField = ({
  label,
  name,
  control,
  rules,
  disabled = false,
  showLabel = true,
  ...rest
}: TextFormFieldProps): Node => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    shouldUnregister={false}
    render={({ field: { onChange, value, onBlur } }) => {
      const onChangeText = text => {
        if (!text) {
          onChange('');
          return;
        }
        let filteredText = parenthesizedNumberToNegative(text);
        const asNumber = Number(filteredText);
        if (Number.isNaN(asNumber)) {
          return;
        }
        onChange(text);
      };
      const restWithKeyboard = {
        ...rest,
        keyboardType: 'numeric',
      };
      return renderTextField({
        label: label,
        onChange: onChangeText,
        value: value,
        onBlur: onBlur,
        error: '',
        disabled: disabled,
        showLabel: showLabel,
        ...restWithKeyboard,
      });
    }}
  />
);

export const EmailFormField = ({
  control,
  optional = false,
  showLabel = true,
}: FormFieldProps): Node => (
  <TextFormField
    label="Enter your email address"
    name="email"
    placeholder="Email address"
    autoCapitalize="none"
    keyboardType="email-address"
    control={control}
    rules={RULES(optional).EMAIL}
    showLabel={showLabel}
  />
);

export const FirstNameFormField = ({
  control,
  optional = false,
  showLabel = true,
}: FormFieldProps): Node => (
  <TextFormField
    label="First name"
    name="firstName"
    placeholder="First name"
    control={control}
    rules={RULES(optional).FIRST_NAME}
    showLabel={showLabel}
  />
);

export const LastNameFormField = ({
  control,
  optional = false,
  showLabel = true,
}: FormFieldProps): Node => (
  <TextFormField
    label="Last name"
    name="lastName"
    placeholder="Last name"
    control={control}
    rules={RULES(optional).LAST_NAME}
    showLabel={showLabel}
  />
);

export const TrialCodeFormField = ({
  control,
  hasLabel = false,
  showLabel = true,
}: FormFieldProps): Node => (
  <TextFormField
    label={hasLabel ? 'Trial Code' : undefined}
    name="trialCode"
    control={control}
    placeholder="Trial Code"
    showLabel={showLabel}
  />
);

export const PasswordFormField = ({
  control,
  name = 'password',
  label = 'Password',
  optional = false,
  showLabel = true,
  ...rest
}: FormFieldProps): Node => (
  <Controller
    control={control}
    name={name}
    rules={!optional && { required: `${label} is required` }}
    render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
      <PasswordField
        label={label}
        value={value}
        onBlur={onBlur}
        onChangeText={onChange}
        error={error}
        errorMessage={error?.message}
        showLabel={showLabel}
        {...rest}
      />
    )}
  />
);

export const RoleFormField = ({ control }: FormFieldProps): Node => {
  const theme = useTheme();
  return (
    <Controller
      control={control}
      name="role"
      rules={{
        required: 'Role is required',
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) =>
        renderRolesRadioGroup(onChange, value, error, theme.colors.primary)
      }
    />
  );
};

export const PhoneNumberFormField = ({
  label = 'Phone Number',
  name = 'phoneNumber',
  control,
  optional = false,
  rules = RULES(optional).PHONE,
  disabled = false,
  showLabel = true,
  ...rest
}: TextFormFieldProps): Node => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    shouldUnregister={false}
    render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => {
      const phoneNumber = Phone.parse(value ?? '');
      const val = phoneNumber?.isValid() ? phoneNumber.formatNational() : value;

      return (
        <TextField
          label={showLabel && label}
          value={val}
          onBlur={onBlur}
          onChangeText={onChange}
          error={error}
          errorMessage={error?.message}
          disabled={disabled}
          placeholder={rest?.placeholder ?? label}
          keyboardType="number-pad"
          {...rest}
        />
      );
    }}
  />
);

const dropdownStyles = {
  height: 56,
  minWidth: 280,
};

export const DropDownField = ({
  label,
  control,
  name,
  options,
  theme,
  rules,
}) => (
  <FormRow>
    <FormCol flex={2}>
      <Text style={{ marginBottom: '0.5rem' }}>{label}</Text>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <>
              <SearchField
                value={value?.name ? value : ''}
                options={options}
                onChange={onChange}
                getOptionLabel={o => o.name}
                getOptionValue={o => o.name}
                isValidNewOption={() => false}
                style={dropdownStyles}
                controlStyle={{
                  height: dropdownStyles.height,
                  backgroundColor: theme.colors.sidebarBackground,
                  color: theme.colors.text,
                  borderColor: theme.colors.fieldBorder,
                }}
                optionStyle={{
                  height: undefined,
                  backgroundColor: theme.colors.sidebarBackground,
                }}
              />
              {error && (
                <TextWrapper ml="5px" color="secondary">
                  {error.message}
                </TextWrapper>
              )}
            </>
          );
        }}
      />
    </FormCol>
  </FormRow>
);

const renderTextField = ({
  label,
  onChange,
  value,
  onBlur,
  error,
  disabled,
  showLabel = true,
  ...rest
}) => (
  <TextField
    label={showLabel && label}
    placeholder={rest?.placeholder ?? label}
    value={value}
    onBlur={onBlur}
    onChangeText={onChange}
    error={error}
    errorMessage={error?.message}
    disabled={disabled}
    {...rest}
  />
);

const renderRolesRadioGroup = (onChange, value, error, color) => {
  const options = [
    {
      value: UserRole.OWNER,
      label: 'I am an Aircraft Owner',
    },
    {
      value: UserRole.PILOT,
      label: 'I am a Pilot',
    },
    {
      value: UserRole.PASSENGER,
      label: 'I am a Passenger',
    },
    {
      value: 'none',
      label: 'I am none of the above and I want to learn more',
    },
  ];

  return (
    <RadioGroup
      options={options}
      value={value}
      color={color}
      onChange={onChange}
      errorMessage={error?.message}
    />
  );
};

export const DialogDateTimeFormField = ({
  control,
  mode,
  label,
  name,
}): Node => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: `Departure ${mode} is required`,
      }}
      render={({ field: { onChange, value } }) =>
        renderDialogDateTimePicker(label, value, onChange, mode)
      }
    />
  );
};

const renderDialogDateTimePicker = (label, value, onChange, mode) => (
  <DialogDatePicker
    label={label}
    mode={mode}
    value={value}
    onChange={onChange}
  />
);

export const CodeField = ({ control, showLabel = true }) => (
  <TextFormField
    control={control}
    label="Company ID"
    name="signUpCode"
    placeholder="Company ID"
    rules={{
      required: 'Ask your management company for their signup code',
      pattern: {
        value: /^C[A-Z\d]{4}$/i,
        message: 'Invalid code',
      },
    }}
    showLabel={showLabel}
  />
);

export const UserMessageField = ({ control, mode, style }) => (
  <TextFormField
    multiline
    mode={mode}
    control={control}
    label="What would you like to know about FlightApp!?"
    name="message"
    style={style}
    rules={{
      maxLength: {
        value: 500,
        message: 'Maximum message length reached',
      },
    }}
  />
);

export const FullNameField = ({ control, required = true }) => (
  <TextFormField
    multiline
    control={control}
    label="Name"
    name="name"
    rules={{
      required: required ? 'Please enter your name' : false,
    }}
  />
);

const FormRow = styled.View`
  flex-direction: row;
  align-items: 'center';
  justify-content: space-between;
  width: ${({ width = '100%' }) =>
    Number.isInteger(width) ? width + 'px' : width};
  min-height: ${({ height = 82 }) => height.toString()}px;
  flex-wrap: ${({ wrap }) => wrap};
  margin-top: ${({ theme, mt }) =>
    mt && `${theme.layout.space(mt).toString()}px`};
`;

const FormCol = styled.View(
  ({ theme, flex = 1, alignSelf = 'center', mr, mh, ml }) => ({
    flexDirection: 'column',
    flex,
    alignSelf,
    marginHorizontal: mh && theme.layout.space(mh),
    marginRight: mr && theme.layout.space(mr),
    marginLeft: ml && theme.layout.space(ml),
  }),
);

export const NumberField = ({
  label,
  control,
  name,
  prefix = '',
  decimalScale = undefined,
  right,
  style,
}) => {
  return (
    <FormRow>
      {label && (
        <FormCol alignSelf="center">
          <HeaderText>{label}</HeaderText>
        </FormCol>
      )}
      <FormCol flex={2}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => {
            if (value) {
              const formattedValue = formatNumber({
                value,
                prefix,
                decimalScale,
              });
              if (formattedValue !== value) {
                onChange(formattedValue);
              }
            }
            return (
              <TextFormField
                light
                label={label}
                control={control}
                value={value}
                name={name}
                right={right}
                style={style}
              />
            );
          }}
        />
      </FormCol>
    </FormRow>
  );
};

export const MoneyField = ({ label, control, name }) => (
  <NumberField
    label={label}
    control={control}
    name={name}
    prefix="$"
    decimalScale={2}
  />
);

export const IntegerFormField = ({
  name,
  label,
  control,
  rules,
  allowNegative = false,
  useComma = false, // Control for comma formatting
  isIOS = true,
  ...rest
}) => {
  const formatValue = value => {
    // Allow empty string or just the negative sign
    if (value === '-' || value === '') {
      return value;
    }
    if (value === undefined || isNaN(value)) {
      return '';
    }
    // Apply comma formatting if applicable
    if (useComma && !isNaN(parseInt(value, 10))) {
      // Format with commas using toLocaleString
      return parseInt(value).toLocaleString();
    }
    return value.toString();
  };

  const parseValue = value => {
    // Allow empty string or just the negative sign
    if (value === '' || value === '-') {
      return value;
    }
    // Remove commas before converting to integer
    const numValue = parseInt(value.replace(/,/g, ''), 10);
    return isNaN(numValue) ? '' : numValue;
  };

  const validateInput = text => {
    // Properly handle numbers, optionally negative, with any number of digits and commas
    const regex = allowNegative ? /^-?\d*(,\d{3})*$/ : /^\d*(,\d{3})*$/;
    if (text === '') {
      return true;
    } // Allow empty input to clear the field
    return regex.test(text.replace(/\s/g, ''));
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <TextField
          label={label}
          value={formatValue(value)}
          onChangeText={text => {
            if (validateInput(text.replace(/,/g, ''))) {
              onChange(parseValue(text));
            }
          }}
          onBlur={onBlur}
          error={error}
          errorMessage={error?.message}
          keyboardType={isIOS ? 'numbers-and-punctuation' : 'number-pad'}
          {...rest}
        />
      )}
    />
  );
};

export const DecimalFormField = ({
  name,
  label,
  control,
  rules,
  decimalPlaces = 2,
  allowNegative = false,
  useComma = false,
  isIOS = true,
  ...rest
}) => {
  const [localValue, setLocalValue] = useState('');
  const watchedValue = useWatch({
    control,
    name,
  });

  const formatValue = value => {
    if (value === '' || value === '-' || value === '.') {
      return value;
    }
    if (value === undefined || isNaN(value)) {
      return '';
    }

    let numValue = parseFloat(value);
    if (isNaN(numValue)) {
      return '';
    }

    let formattedValue = numValue.toString();

    if (useComma) {
      const parts = formattedValue.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      formattedValue = parts.join('.');
    }

    return formattedValue;
  };

  const parseValue = value => {
    if (value === '' || value === '-' || value === '.') {
      return value;
    }
    const cleanValue = value.replace(/,/g, '');
    const numValue = parseFloat(cleanValue);
    return isNaN(numValue) ? '' : numValue;
  };

  const validateInput = text => {
    const cleanText = text.replace(/,/g, '');
    const regex = allowNegative
      ? new RegExp(`^-?\\d*\\.?\\d{0,${decimalPlaces}}$`)
      : new RegExp(`^\\d*\\.?\\d{0,${decimalPlaces}}$`);
    return regex.test(cleanText);
  };

  React.useEffect(() => {
    setLocalValue(formatValue(watchedValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedValue]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur }, fieldState: { error } }) => (
        <TextField
          label={label}
          value={localValue}
          onChangeText={text => {
            if (validateInput(text)) {
              setLocalValue(text);
              onChange(parseValue(text));
            }
          }}
          onBlur={() => {
            onBlur();
            const formattedValue = formatValue(parseValue(localValue));
            setLocalValue(formattedValue);
            onChange(parseValue(formattedValue));
          }}
          error={error}
          errorMessage={error?.message}
          keyboardType={isIOS ? 'numbers-and-punctuation' : 'numeric'}
          {...rest}
        />
      )}
    />
  );
};

type TextFormFieldProps = {
  label: String,
  name: String,
  control: Control,
  rules?: Object,
  disabled?: Boolean,
};

type FormFieldProps = {
  control: Control,
};
