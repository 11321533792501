/**
 * @file
 * A component for a Select dropdown with a radio button next to it
 *
 * @format
 * @flow strict-local
 */

import React from 'react';
import Select from 'react-select';
import styled from '@emotion/native';

import Radio from '@appComponents/Radio';

type DropDownRadioProps = {
  value: ValueItem,
  valuePicker: ValueItem => any,
  onChange: ValueItem => void,
  options?: String[],
  checked: Boolean,
};

export type ValueItem = {
  label: string,
  value: string,
};

const DropDownRadioButton = ({
  value,
  valuePicker,
  onChange,
  options,
  checked,
}: DropDownRadioProps) => (
  <Wrapper>
    <SelectWrapper>
      <Select
        value={valuePicker(value)}
        options={options}
        // menuPortalTarget is needed to ensure the dropdown menu is on top and not behind everything else
        menuPortalTarget={document.body}
        onChange={onChange}
      />
    </SelectWrapper>
  </Wrapper>
);

const Wrapper = styled.View`
  flex-direction: row;
  align-items: center;
`;

const SelectWrapper = styled.View`
  min-width: 200px;
`;

export default DropDownRadioButton;
