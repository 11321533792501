import React from 'react';

import { UserRole } from '@appUtils/tripConverter';
import UserRoleList from './UserRoleList';

const ArchivedPassengers = () => (
  <UserRoleList role={UserRole.PASSENGER} withActions showArchived />
);

export default ArchivedPassengers;
