/**
 * @file
 * Component used for inputting flight duration
 *
 * @format
 * @flow strict-local
 */

import React, { useState } from 'react';
import { TextInput } from 'react-native-paper';
import TextField, { useColors } from '../TextField';
import { flightTimeToMinutes, minutesToFlightTime } from '@appUtils/trip';

const FlightTimeField: typeof TextInput = ({ onChange, value, ...rest }) => {
  const { outline, ...colors } = useColors(rest);
  const [textValue, setTextValue] = useState(() => minutesToFlightTime(value));

  return (
    <TextField
      theme={{ colors }}
      label="Flight Time"
      placeholder="hh:mm"
      mode="outlined"
      value={textValue}
      onChangeText={text => {
        setTextValue(text);
        onChange(flightTimeToMinutes(text));
      }}
      {...rest}
    />
  );
};

export default FlightTimeField;
