import { useRef } from 'react';
import * as FS from 'react-native-fs';
import _ from 'lodash';
import app, { getUid } from '@appFirebase';
import { useAsync } from 'react-async-hook';

/**
 * Removes deleted images from the local file system and attempts to also remove remote copies (not waited for)
 * Saves any newly added images locally and remotely
 * The remote push operations are not waited for
 * @param {AppImage[]} nextImageList
 * @param {AppImage[]} prevImageList
 * @param {FirebaseStorageTypes.Reference} storageRef
 * @returns {Promise<AppImage[]>}
 */
export const updateImages = async (
  nextImageList,
  prevImageList = [],
  storageRef,
) => {
  const deleted = _.differenceWith(
    prevImageList,
    nextImageList,
    (a, b) => a.relativePath === b.relativePath,
  );
  await deleteImages(deleted);

  const images = await saveImages(nextImageList, storageRef);
  return images;
};

/**
 * Deletes local and remote copies of the given images
 * @param {AppImage[]} images
 * @param {FirebaseStorageTypes.Reference} storageRef
 * @returns {Promise}
 */
export const deleteImages = async images => {
  const tasks = images.map(image => {
    const cloudRef = app.storage().ref(image.relativePath ?? image.path);
    deleteFile(cloudRef);
  });
  return Promise.all(tasks);
};

/**
 * Tries to resolve the image locally if it's available
 * @param {string} relativePath
 * @returns {Promise<string>}
 */
export const resolveLocalPhoto = async relativePath => {
  const localPath = getLocalPath(relativePath);
  const isAvailable = await FS.exists(localPath);

  if (isAvailable) {
    return localPath;
  }

  throw new Error('This image is not yet synced locally');
};

/**
 * Saves newly added trip images using {@link saveFile}
 * Skips any images that are already processed and contain a `relativePath` key
 * @param {AppImage[]} images
 * @param {FirebaseStorageTypes.Reference} storageRef
 * @returns {Promise<AppImage[]>} Resolves with a list of images
 */
const saveImages = async (images, storageRef) => {
  const tasks = images.map(async ({ uri, ...image }) => {
    if (image.relativePath) {
      return image;
    }

    const updates = await saveFile(uri, storageRef);

    return {
      ...image,
      ...updates,
    };
  });

  return Promise.all(tasks);
};

/**
 * Use this function to save a local file to our in-app storage
 * And attempt to push it to the cloud
 * @param {{ uri: string, fileName: string, type: string }} asset
 * @param {string} [path]
 * @returns {Promise<{ task: Task, relativePath: string, name: string, ref: firebase.storage.Reference, name: string, localUri: string }>}
 * Resolves with the relative path to the file
 */
export const saveFile = async (asset, path = getUid()) => {
  const storageRef = app.storage().ref(path);

  // 1. copy/move to app local space - destFolder
  const localDir = getLocalPath(storageRef.fullPath);
  const cloudRef = storageRef.child(asset.fileName ?? asset.name);
  const localDestination = getLocalPath(cloudRef.fullPath);

  // Ensure local sub-folder exists
  await FS.mkdir(localDir, { NSURLIsExcludedFromBackupKey: true });

  // Remove any existing file with the same name
  await tryRemoveFile(localDestination);
  await FS.moveFile(asset.uri, localDestination);

  // 2. push to cloud (but don't wait for it)
  const task = cloudRef.putFile(localDestination, { contentType: asset.type });
  task.catch(e => console.warn(e.message));

  return {
    task,
    relativePath: cloudRef.fullPath,
    ref: cloudRef,
    localUri: localDestination,
    name: cloudRef.name,
  };
};

/**
 * Use this function to remove a file from local storage and from the cloud
 * @param {string} relativePath
 * @returns {Promise<*>}
 */
export const removeFile = async relativePath => {
  const cloudRef = app.storage().ref(relativePath);
  const localDestination = getLocalPath(relativePath);

  return Promise.all([tryRemoveFile(localDestination), cloudRef.delete()]);
};

/**
 * @param {string[]} relativePaths
 */
export const useFiles = relativePaths => {
  const resultCache = useRef(new Map());

  const images = useAsync(
    async () => {
      const promises = (relativePaths || []).map(async path => {
        if (resultCache.current.has(path)) {
          return resultCache.current.get(path);
        }

        const task = resolveLocalPhoto(path)
          .then(uri => ({ uri, path }))
          .catch(async error => {
            if (error.message === 'This image is not yet synced locally') {
              const ref = app.storage().ref(path);
              const localPath = getLocalPath(path);
              ref.writeToFile(localPath).catch(console.warn);

              const uri = await ref.getDownloadURL();
              return { uri, path };
            }

            resultCache.current.delete(path);

            throw error;
          });

        resultCache.current.set(path, task);

        return task;
      });

      return Promise.all(promises);
    },
    [relativePaths],
    {
      setLoading: state => ({ ...state, loading: true }),
    },
  );

  return images.result || [];
};

/**
 * Deletes a file locally and attempts to delete from the cloud storage
 * @param {FirebaseStorageTypes.Reference} storageRef
 * @returns {Promise<void>}
 */
export const deleteFile = async storageRef => {
  console.info('deleteFile: ', storageRef.name);
  const localDestination = getLocalPath(storageRef.fullPath);
  await tryRemoveFile(localDestination);

  // delete from cloud (but don't wait for it)
  storageRef.delete().catch(console.warn);
};

/**
 * Tries to remove the the file if it exists
 * @param {string} filePath
 * @returns {Promise<boolean>} Resolves to `true` when a file was removed
 */
export const tryRemoveFile = async filePath => {
  try {
    await FS.unlink(filePath);
    return true;
  } catch (e) {
    if (/ENOENT/.test(e.message) === false) {
      throw e;
    }
    return false;
  }
};

/**
 * Gets the local path for the passed relative Path
 * @param {string} relativePath
 * @returns {string}
 */
export const getLocalPath = (relativePath = '') =>
  `${FS.CachesDirectoryPath}/${relativePath}`;

/**
 * @typedef AppImage
 * @property {string} uri
 * @property {string} relativePath
 * @property {string} name
 */
