/**
 * @file
 * Squawk related hooks and APIs
 *
 * @format
 * @flow strict-local
 */

import { useEffect, useMemo } from 'react';
import _ from 'lodash';
import app, {
  FieldValue,
  getUid,
  useCollection,
  Timestamp,
} from '@appFirebase';
import { useMyCompanyRef } from '@appUtils/api';
import { DateTime } from 'luxon';
import { updateAircraft } from './aircraft';
import makePalette from '@appComponents/theme/makePalette';

export const SquawkStatus = {
  UNDEFINED: 'UD',
  MEL: 'MEL',
  UNFLIGHTWORTHY: 'UFW',
  ARCHIVED: 'Archived',
  NO_SQUAWKS: 'NS',
};

export const SquawkPriority = {
  UFW: 1,
  UD: 2,
  MEL: 3,
  NS: 4,
  Archived: 5,
};

export const SquawkTitleRules = {
  required: 'Squawk Title is required',
  pattern: {
    value: /^(?!\s*$).+$/i,
    message: 'Squawk Title cannot be blank',
  },
};

export const getSquawkColorByPriority = squawkPriority => {
  const palette = makePalette();
  switch (squawkPriority) {
    case SquawkPriority.UFW:
      return palette.squawkUFW;
    case SquawkPriority.UD:
      return palette.squawkUD;
    case SquawkPriority.MEL:
      return palette.squawkMEL;
    case SquawkPriority.NS:
    default:
      return palette.squawkNS;
  }
};

export const useAircraftSquawkList = ({ aircraftId, priority, operator }) => {
  const [company, , companyError] = useMyCompanyRef();
  const query = useMemo(() => {
    if (!company) {
      return undefined;
    }
    if (!aircraftId) {
      return undefined;
    }

    const ref = company
      .collection(`aircraft/${aircraftId}/squawks`)
      .where('priority', operator, priority)
      .orderBy('priority', 'asc')
      .orderBy('dateCreated', 'asc');

    return ref;
  }, [aircraftId, company, operator, priority]);

  const [snapshot, , snapError] = useCollection<TItem>(query);
  const error = companyError || snapError;
  useEffect(() => error && console.error(error), [error]);

  const data = useMemo(
    () =>
      _.map(snapshot?.docs, doc => {
        const path = doc.ref.path;

        return { ...doc.data(), path };
      }),
    [snapshot?.docs],
  );

  return {
    loading: !snapshot && !error,
    error,
    data,
    snapshot,
  };
};

export const useTripSquawkList = ({ aircraftId, tripId }) => {
  const [company, , companyError] = useMyCompanyRef();
  const query = useMemo(() => {
    if (!company) {
      return undefined;
    }
    if (!tripId) {
      return undefined;
    }
    if (!aircraftId) {
      return undefined;
    }

    const ref = company
      .collection(`aircraft/${aircraftId}/squawks`)
      .where('trip.identifier', '==', tripId);

    return ref;
  }, [aircraftId, company, tripId]);

  const [snapshot, , snapError] = useCollection<TItem>(query);
  const error = companyError || snapError;
  useEffect(() => error && console.error(error), [error]);

  const data = useMemo(
    () =>
      _.map(snapshot?.docs, doc => {
        const path = doc.ref.path;

        return { ...doc.data(), path };
      }),
    [snapshot?.docs],
  );

  return {
    loading: !snapshot && !error,
    error,
    data,
    snapshot,
  };
};

export const updateSquawk = async ({
  payload,
  squawkPath,
  statusChanged = false,
}) => {
  const squawkRef = app.firestore().doc(squawkPath);
  let data = {
    ...payload,
    dateUpdated: FieldValue.serverTimestamp(),
    updatedBy: getUid(),
  };
  if (statusChanged) {
    data.priority = SquawkPriority[data.currentStatus];
    data.status = [
      ...data.status,
      {
        // time: Timestamp.fromMillis(DateTime.now().toMillis()),
        setBy: getUid(),
        value: data.currentStatus,
      },
    ];
  }
  return await squawkRef.set(data, { merge: true });
};

export const deleteSquawk = async squawkPath => {
  return await app.firestore().doc(squawkPath).delete();
};

export const createSquawk = async ({ payload, collection }) => {
  const squawkRef = app.firestore().collection(collection).doc();
  await squawkRef.set({
    ...payload,
    dateCreated: FieldValue.serverTimestamp(),
    currentStatus: SquawkStatus.UNDEFINED,
    priority: SquawkPriority[SquawkStatus.UNDEFINED],
    status: [
      {
        // time: Timestamp.fromMillis(DateTime.now().toMillis()),
        setBy: getUid(),
        value: SquawkStatus.UNDEFINED,
      },
    ],
  });
  return squawkRef;
};

export const updateAircraftSquawkStatus = ({
  squawks,
  aircraftPath,
  newSquawkStatus = '',
  squawkId,
}) => {
  const squawkPriorities = [
    ...squawks.map(squawk => {
      if (squawkId) {
        const currentSquawkId = _.last(_.split(squawk.path, '/'));
        if (squawkId === currentSquawkId) {
          return;
        }
      }
      return SquawkPriority[squawk.currentStatus];
    }),
    SquawkPriority[newSquawkStatus],
    SquawkPriority[SquawkStatus.NO_SQUAWKS],
  ];

  updateAircraft({ squawkPriority: _.min(squawkPriorities) }, aircraftPath);
};
