import React from 'react';

import { UserRole } from '@appUtils/tripConverter';
import UserRoleList from './UserRoleList';

const ArchivedOwners = () => (
  <UserRoleList role={UserRole.OWNER} withActions showArchived />
);

export default ArchivedOwners;
