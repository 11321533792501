/**
 * @file
 * User avatar and menu
 *
 * @format
 * @flow strict-local
 */
import React, { useState } from 'react';
import type { Node } from 'react';
import styled from '@emotion/native';
import { useNavigation } from '@react-navigation/native';

import { Icon } from './theme';
import Text from './Text';
import Avatar from './Avatar';
import { useTheme } from '@appComponents/theme';

const UserMenu = ({ user }: UserMenuProps): Node => {
  const { navigate } = useNavigation();
  const [noticeVisible, setNoticeVisible] = useState(false);
  const theme = useTheme();

  return (
    <Container>
      <NameContainer>
        <Text size="small" weight={300} onPress={() => navigate('Settings')}>
          {user.firstName} <Text weight={900}>{user.lastName}</Text>
        </Text>
        {noticeVisible && <CopiedTextNotice />}
        <Text
          size="small"
          weight={300}
          onPress={() =>
            navigator.clipboard
              .writeText(user.managementCompany?.identifier)
              .then(() => {
                setNoticeVisible(true);
                setTimeout(() => {
                  setNoticeVisible(false);
                }, 2000);
              })
          }>
          Company ID:{' '}
          <Text weight={900}>{user.managementCompany?.identifier}</Text>
          <CopyIcon name="copy" color={theme.colors.primary} />
        </Text>
      </NameContainer>

      <AvatarContainer onPress={() => navigate('Settings')}>
        <Avatar {...user} />
      </AvatarContainer>

      {/*<MenuButton>*/}
      {/*  <MenuIcon name="more-dots" size={18} color="#ffffff" />*/}
      {/*</MenuButton>*/}
    </Container>
  );
};

const CopyIcon = styled(Icon)`
  margin-left: 5px;
`;

const CopiedTextNotice = () => (
  <CopiedTextContainer>
    <Text>Company ID copied to clipboard.</Text>
  </CopiedTextContainer>
);

type UserMenuProps = {
  user: {
    firstName: string,
    lastName: string,
    image?: string,
  },
};

const Container = styled.Pressable(() => ({
  flexDirection: 'row',
  alignItems: 'center',
}));

const AvatarContainer = styled.Pressable(({ theme }) => ({
  marginLeft: theme.layout.gap(3),
}));

const NameContainer = styled.View(() => ({
  alignItems: 'flex-end',
}));

const CopiedTextContainer = styled.View(({ theme }) => ({
  zIndex: 10,
  backgroundColor: theme.colors.messageSelected,
  position: 'absolute',
  opacity: 0.9,
  width: theme.layout.space(14),
  marginTop: theme.layout.space(1.5),
  paddingHorizontal: theme.layout.space(0.5),
  borderRadius: 6,
}));

const MenuButton = styled.View(({ theme }) => ({
  backgroundColor: theme.colors.surface,
  marginLeft: theme.layout.gap(1),
  width: 14,
  height: 32,
  borderRadius: 7,
  alignItems: 'center',
  justifyContent: 'center',
}));

const MenuIcon = styled(Icon)`
  transform: rotate(-90deg);
`;

export default UserMenu;
