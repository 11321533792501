/**
 * @file
 * Document related helpers and hooks
 *
 * @format
 * @flow strict-local
 */

import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

export const useDocumentImages = (
  images: Array<ImageReference | LoadedImage>,
  useStorageImages: ImageLoaderHook,
) => {
  const [pathToLoad, setPathToLoad] = useState([]);

  useEffect(() => {
    // images that are just selected would have a local `uri`, the rest would have only `path` and need to be fetched
    const paths = _.filter(images, image => !image.uri).map(
      image => image.path,
    );

    setPathToLoad(paths);
  }, [images]);

  const loadedImages = useStorageImages(pathToLoad);

  const result = useMemo(
    () =>
      _.map(images, image => {
        if (!image.uri) {
          const remoteImg = _.find(
            loadedImages,
            entry => entry.path === image.path,
          );
          return {
            ...image,
            ...remoteImg,
          };
        }

        return image;
      }),
    [images, loadedImages],
  );

  return result;
};

type ImageReference = {
  name: string,
  path: string,
};

type LoadedImage = {
  ...ImageReference,
  uri: string,
};

type ImageLoaderHook = (ImageReference[]) => LoadedImage[];
