import React, { useState } from 'react';
import { View, StyleSheet, ScrollView, Text } from 'react-native';
import { useTheme } from '@appComponents/theme';
import { VorRvsmModal } from './Modals/VorRvsmModal';
import { useMeteringContext } from '@appUtils/context/MeteringContext';
import { StyledIconButton } from '@appComponents/Metering/StyledIconButton';

export const VorRvsm = ({ trip, handleSelectedLegChange }) => {
  const theme = useTheme();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { watch, setSelectedLeg } = useMeteringContext();
  const updateMode = watch('updateMode');

  const openModal = leg => {
    setSelectedLeg(leg);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const getVorRvsmData = (field, legId) => {
    if (updateMode === 'leg' && legId) {
      return trip?.metering?.legs?.[legId]?.vorRvsm?.[field] || '-';
    }

    return trip?.metering?.overall?.vorRvsm?.[field] || '-';
  };

  const renderMainInfo = leg => {
    return (
      <View style={styles.infoContainer}>
        {updateMode === 'leg' ? (
          <Text style={[styles.legHeader, { color: theme.colors.text }]}>
            {leg?.from} &gt; {leg?.to}
          </Text>
        ) : null}
        <View style={styles.infoRow}>
          <View style={styles.infoColumn}>
            <Text
              style={[
                styles.infoLabel,
                styles.ratLabel,
                { color: theme.colors.text },
              ]}>
              VOR CHECK
            </Text>
            <View
              style={[
                styles.infoValue,
                { backgroundColor: theme.colors.background },
              ]}>
              <Text
                style={[
                  styles.infoText,
                  styles.ratText,
                  {
                    color: theme.colors.text,
                    backgroundColor: theme.colors.background,
                  },
                ]}>
                {getVorRvsmData('vorCheck', leg?.id)}
              </Text>
            </View>
          </View>
          <View style={[styles.infoColumn, styles.infoColumnAlt]}>
            <Text style={[styles.infoLabel, { color: theme.colors.text }]}>
              ALTITUDE AT RVSM (FT)
            </Text>
            <View
              style={[
                styles.infoValue,
                { backgroundColor: theme.colors.background },
              ]}>
              <Text style={{ color: theme.colors.text }}>
                {getVorRvsmData('rvsm', leg?.id)}
              </Text>
            </View>
          </View>
          <View>
            <Text style={[styles.infoLabel, { color: theme.colors.text }]}>
              READING TAKEN
            </Text>
            <View
              style={[
                styles.infoValue,
                { backgroundColor: theme.colors.background },
              ]}>
              <Text
                style={[
                  {
                    color: theme.colors.text,
                  },
                  styles.upperCase,
                ]}>
                {getVorRvsmData('readingTaken', leg?.id)}
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  };

  const renderSecondaryInfo = leg => (
    <View style={styles.infoContainer}>
      <View style={styles.infoRow}>
        <View style={styles.infoColumn}>
          <Text
            style={[
              styles.infoLabel,
              styles.ratLabel,
              { color: theme.colors.text },
            ]}>
            #1 (º)
          </Text>
          <View
            style={[
              styles.infoValue,
              { backgroundColor: theme.colors.background },
            ]}>
            <Text
              style={[
                styles.infoText,
                styles.ratText,
                {
                  color: theme.colors.text,
                  backgroundColor: theme.colors.background,
                },
              ]}>
              {getVorRvsmData('vor1', leg?.id)}
            </Text>
          </View>
        </View>
        <View style={styles.infoColumn}>
          <Text style={[styles.infoLabel, { color: theme.colors.text }]}>
            #2 (º)
          </Text>
          <View
            style={[
              styles.infoValue,
              { backgroundColor: theme.colors.background },
            ]}>
            <Text style={{ color: theme.colors.text }}>
              {getVorRvsmData('vor2', leg?.id)}
            </Text>
          </View>
        </View>
        <View>
          <Text style={[styles.infoLabel, { color: theme.colors.text }]}>
            DIFFERENCE
          </Text>
          <View
            style={[
              styles.infoValue,
              { backgroundColor: theme.colors.background },
            ]}>
            <Text style={{ color: theme.colors.text }}>
              {getVorRvsmData('vorDifference', leg?.id)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );

  return (
    <>
      <ScrollView style={styles.container}>
        {updateMode === 'leg' ? (
          trip.legs.map((leg, index) => (
            <View key={leg.id}>
              <View
                style={[
                  styles.legHeaderContainer,
                  { borderBottomColor: theme.colors.white },
                ]}>
                <Text
                  style={[styles.legIndexText, { color: theme.colors.text }]}>
                  Leg {index + 1}
                </Text>
                <StyledIconButton
                  icon="edit"
                  backgroundColor={theme.colors.primary}
                  onPress={() => {
                    handleSelectedLegChange(leg);
                    openModal(leg);
                  }}
                />
              </View>
              {renderMainInfo(leg)}
              <View style={styles.vorCheckContainer}>
                {renderSecondaryInfo(leg)}
              </View>
            </View>
          ))
        ) : (
          <View>
            <View
              style={[
                styles.legHeaderContainer,
                { borderBottomColor: theme.colors.white },
              ]}>
              <StyledIconButton
                icon="edit"
                backgroundColor={theme.colors.primary}
                onPress={() => {
                  openModal(null);
                }}
                hasMargin
              />
            </View>
            {renderMainInfo(null)}
            <View style={styles.vorCheckContainer}>
              {renderSecondaryInfo(null)}
            </View>
          </View>
        )}
      </ScrollView>
      {isModalVisible ? (
        <VorRvsmModal
          trip={trip}
          updateMode={updateMode}
          onClose={closeModal}
        />
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  legHeaderContainer: {
    borderBottomWidth: 1,
    padding: 12,
    marginTop: 22,
    flexDirection: 'row',
    alignItems: 'center',
  },
  legIndexText: {
    fontSize: 18,
  },
  infoContainer: {
    marginVertical: 32,
  },
  legHeader: {
    flexShrink: 1,
    marginBottom: 32,
    marginHorizontal: 12,
    fontWeight: 'semibold',
    fontSize: 18,
  },
  infoRow: {
    width: '60%',
    flexDirection: 'row',
  },
  infoColumn: {
    minWidth: '30%',
  },
  infoColumnAlt: {
    minWidth: '40%',
  },
  infoLabel: {
    fontWeight: 'bold',
  },
  ratLabel: {
    marginLeft: 12,
  },
  infoValue: {
    marginVertical: 8,
    paddingVertical: 14,
  },
  infoText: {},
  ratText: {
    marginLeft: 12,
  },
  vorCheckContainer: {
    flex: 1,
  },
  engineWrapper: {},
  engineContent: {
    flexDirection: 'row',
  },
  engineLabelColumn: {
    width: '25%',
  },
  engineColumn: {
    flex: 0.5,
  },
  engineHeader: {
    fontWeight: 'bold',
    margin: 8,
  },
  placeholderLabel: {
    marginLeft: 12,
  },
  engineRow: {
    flexDirection: 'row',
    marginVertical: 2,
    padding: 14,
  },
  engineLabel: {
    color: 'white',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
});
