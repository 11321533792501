/**
 * @file
 * Firebase query helper for Calendar events
 *
 * @format
 * @flow strict-local
 */

import { useRef } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { DateTime } from 'luxon';
import _ from 'lodash';

import tripConverter, { UserRole } from '@appUtils/tripConverter';
import { getUserData, useMyCompanyRef } from '@appUtils/api';
import db, { useCollection } from '@appFirebase';

import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
} from '@appFirebase';

type QueryFilter = {
  user?: { id: string, role: UserRole },
  aircraft?: { tailNumber: string },
};

type RangeInfo = {
  start: string,
  end: string,
};

type RangeResult = {
  from?: DateTime,
  to?: DateTime,
  loading: boolean,
  hasData: boolean,
};

/**
 * Paging callback for events
 * Loads a range of events (e.g. month, week, or the day)
 * @param filters
 */
export const useEventsRangeCallback = (filters: QueryFilter) => {
  const userRef = useRef();

  return useAsyncCallback(async (info: RangeInfo) => {
    if (!userRef.current) {
      userRef.current = await getUserData();
    }

    const companyRef = userRef.current.managementCompany.ref;
    const baseQuery = createQuery(filters, companyRef);

    // Firestore does not allow multiple inequality operators on different fields
    // We can't query for dateDeparting and dateArriving in the same query
    // So we use 2 queries and filter unique trips

    // departing trips for the queried period
    const queryDeparting = rangeQuery(baseQuery, 'dateDeparting', info);

    // arriving trips for the selected period
    const queryArriving = rangeQuery(baseQuery, 'dateArriving', info);

    // trips starting or ending during the selected period
    const snaps = await Promise.all([queryDeparting, queryArriving]).then(
      ([q1, q2]) => _.uniqBy([...q1.docs, ...q2.docs], 'id'),
    );

    return snaps.reduce((filtered, next) => {
      try {
        const trip = tripConverter.fromFirestore(next);
        filtered.push(trip);
      } finally {
        return filtered;
      }
    }, []);
  });
};

/**
 * Get the available calendar range for the selected filters
 * @param filter
 */
export const useAvailableRange = (filter: QueryFilter): RangeResult => {
  const [companyRef] = useMyCompanyRef();

  const startPoint = createQuery(filter, companyRef)
    .orderBy('dateDeparting', 'asc')
    .limit(1);

  const endPoint = createQuery(filter, companyRef)
    .orderBy('dateDeparting', 'desc')
    .limit(1);

  const [start, startLoading] = useCollection(startPoint);
  const [end, endLoading] = useCollection(endPoint);

  const from = _.first(start?.docs)?.get('dateDeparting');
  const to = _.last(end?.docs)?.get('dateDeparting');
  const loading = startLoading || endLoading;
  const hasData = _.size(filter) > 0 && Boolean(from) && !loading;

  return {
    from: from && DateTime.fromSeconds(from.seconds),
    to: to && DateTime.fromSeconds(to.seconds),
    loading,
    hasData,
  };
};

/**
 * Construct the base query with user/aircraft filtering applied
 * @param filter
 * @param ref
 * @returns {CollectionReference<DocumentData>}
 */
const createQuery = (
  filter: QueryFilter,
  ref: DocumentReference,
): CollectionReference<DocumentData> => {
  if (!ref) {
    // Because this is used in hooks and initially the user company ref is not loaded
    return db.firestore().collection('FAKE_DUMMY');
  }

  let qb = ref.collection('trips');

  if (filter.user) {
    // TODO FW-742 Make sure this works for passengers
    if (filter.user.role === UserRole.OWNER) {
      qb = qb.where('owner.id', '==', filter.user.id);
    } else if (
      filter.user.role === UserRole.PILOT ||
      filter.user.role === UserRole.MANAGER ||
      filter.user.role === UserRole.EMPLOYEE
    ) {
      qb = qb.where('_pilotIds', 'array-contains', filter.user.id);
    }
  }

  if (filter.aircraft?.tailNumber) {
    qb = qb.where('aircraft.tailNumber', '==', filter.aircraft.tailNumber);
  }

  return qb;
};

const rangeQuery = (baseQuery, rangeField, info) =>
  baseQuery
    .where(rangeField, '>=', new Date(info.start))
    .where(rangeField, '<=', new Date(info.end))
    .get();
