// AircraftMeteringFields.js
import React from 'react';
import { View } from 'react-native';
import { Controller } from 'react-hook-form';
import { RadioButton } from 'react-native-paper';
import styled from '@emotion/native';
import Text from '@appComponents/Text';
import Radio from '@appComponents/Radio';

const METERING_SYSTEM_OPTIONS = [
  { value: 'Hobbs', label: 'Hobbs' },
  { value: 'FMS', label: 'FMS' },
];

const APU_METERING_SYSTEM_OPTIONS = [
  { value: 'Hobbs', label: 'Hobbs' },
  { value: 'FMS', label: 'FMS' },
  { value: 'None', label: 'None' },
];

export const AircraftMeteringFields = ({ control }) => {
  return (
    <>
      <MeteringSystemField control={control} />
      <APUMeteringSystemField control={control} />
    </>
  );
};

const MeteringSystemField = ({ control }) => {
  const label = 'Metering System';
  return (
    <FormRow>
      <FormCol alignSelf="center">
        <Text>{label}</Text>
      </FormCol>
      <FormCol flex={2}>
        <Controller
          control={control}
          name="meteringSystem"
          defaultValue="Hobbs"
          render={({ field: { onChange, value } }) => (
            <RadioButton.Group value={value} onValueChange={onChange}>
              <Row>
                {METERING_SYSTEM_OPTIONS.map(option => (
                  <CenteredRadio
                    key={option.value}
                    label={option.label}
                    value={option.value}
                  />
                ))}
              </Row>
            </RadioButton.Group>
          )}
        />
      </FormCol>
    </FormRow>
  );
};

const APUMeteringSystemField = ({ control }) => {
  const label = 'APU Metering System';
  return (
    <FormRow>
      <FormCol alignSelf="center">
        <Text>{label}</Text>
      </FormCol>
      <FormCol flex={2}>
        <Controller
          control={control}
          name="apuMeteringSystem"
          defaultValue="None"
          render={({ field: { onChange, value } }) => (
            <RadioButton.Group value={value} onValueChange={onChange}>
              <Row>
                {APU_METERING_SYSTEM_OPTIONS.map(option => (
                  <CenteredRadio
                    key={option.value}
                    label={option.label}
                    value={option.value}
                  />
                ))}
              </Row>
            </RadioButton.Group>
          )}
        />
      </FormCol>
    </FormRow>
  );
};

const FormRow = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 82px;
`;

const FormCol = styled.View(({ flex = 1, alignSelf = 'flex-start' }) => ({
  flexDirection: 'column',
  flex,
  alignSelf,
}));

const Row = styled(View)`
  flex-direction: row;
  justify-content: start;
`;

const CenteredRadio = styled(Radio)`
  margin-top: 7%;
`;
