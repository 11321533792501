/**
 * @file Icon for flagging the state of expenses: flagged, with comments, or neither
 */
import React from 'react';
import styled from '@emotion/native';
import Icon from '@appComponents/theme/Icon';

export const ExpenseFlagIcon = ({ isFlagged, hasComments, size, ...rest }) => {
  const flagIcon = isFlagged ? 'flag-active' : 'flag-inactive';
  const flagColor = hasComments ? '#676D7D' : 'none';
  return <FlagIcon name={flagIcon} color={flagColor} size={size} rest={rest} />;
};

const FlagIcon = styled(Icon)(({ rest }) => ({
  ...rest,
}));
