/**
 * @file
 * Common components for the Calendar screen
 *
 * @format
 * @flow strict-local
 */

import React from 'react';
import { DateTime } from 'luxon';
import styled from '@emotion/native';
import color from 'color';

import Text from '@appComponents/Text';
import { useAvailableRange } from './query';
import { Icon } from '@appComponents/theme';
import { Box } from '@appComponents/ScreenLayout';
import { TripState } from '@appUtils/tripConverter';
import { minutesToFlightTime } from '@appUtils/trip';

export const FilterDescription = ({ entity }) => (
  <Text size="smallest" lh={20}>
    Showing events for the selected{' '}
    <Text size="smallest" weight="bold">
      {entity}
    </Text>
  </Text>
);

export const AvailableRange = ({ aircraft, user, entity }) => {
  const range = useAvailableRange({ aircraft, user });

  if (range.loading) {
    return null;
  }

  if (!range.hasData) {
    return (
      <Text size="smallest">We couldn't find any events for this {entity}</Text>
    );
  }

  const from = range.from.toLocaleString(DateTime.DATE_MED);
  const to = range.to.toLocaleString(DateTime.DATE_MED);

  return (
    <Text size="smallest">
      Available data: {from} - {to}
    </Text>
  );
};

export const EventCard = ({
  event,
  timeText,
  title = event.title,
  icon = 'trips',
  children,
}) => (
  <EventContentLayout
    archived={event.extendedProps.trip.archived}
    state={event.extendedProps.trip.state}>
    <Box width={40} bg="surface" ai="center" jc="center">
      <Icon size={28} color="white" name={icon} />
    </Box>
    <Box dir="column" flex={1} pv={0.25} ph={0.25} jc="center">
      <Text size="smallest" weight="bold" color="accent">
        {event.id} {event.extendedProps.trip.archived && '(Archived)'}
        {!event.extendedProps.trip.archived &&
          event.extendedProps.trip.state === TripState.CANCELLED &&
          '(Cancelled)'}
      </Text>

      <Text size="smallest" weight="bold" color="primary">
        {event.extendedProps.trip.aircraft?.tailNumber}
      </Text>

      <Text>{title}</Text>

      <Text>{timeText}</Text>

      {children}
    </Box>
  </EventContentLayout>
);

export const LegEventCard = ({ event, timeText }) => {
  const leg = event.extendedProps.leg;

  return (
    <EventCard event={event} title={`${leg.from} - ${leg.to}`} icon="aircraft">
      <Text color="dark">
        <FlightStartEnd leg={leg} timeText={timeText} />
        <FlightDuration flightTime={leg.flightTime} />
      </Text>
    </EventCard>
  );
};

const FlightStartEnd = ({ leg, timeText }) => {
  if (!leg.flightTime) {
    return timeText;
  }

  const endTime = leg.departureDate.plus({ minutes: leg.flightTime });
  if (endTime.hasSame(leg.departureDate, 'day')) {
    return timeText;
  }

  return (
    <>
      {timeText} ({leg.departureDate.weekdayShort}-{endTime.weekdayShort})
    </>
  );
};

const FlightDuration = ({ flightTime }) =>
  flightTime > 0 && <> ({minutesToFlightTime(flightTime, 'explicit')})</>;

const EventContentLayout = styled.View(({ archived, state, theme }) => {
  let backgroundColor;

  if (archived) {
    backgroundColor = color(theme.colors.disabled).fade(0.8).rgb();
  } else if (state === TripState.CANCELLED) {
    backgroundColor = color(theme.colors.error).fade(0.85).rgb();
  } else {
    backgroundColor = color(theme.colors.primary).fade(0.8).rgb();
  }

  return {
    minHeight: '100%',
    flexDirection: 'row',
    cursor: 'pointer',
    overflow: 'hidden',
    backgroundColor,
    borderColor: backgroundColor,
    borderWidth: 1,
  };
});
