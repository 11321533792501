/**
 * @format
 * @flow strict-local
 */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { DrawerContentScrollView } from '@react-navigation/drawer';
import _ from 'lodash';

import {
  useLinkBuilder,
  useLinkProps,
  useNavigation,
} from '@react-navigation/native';
import styled from '@emotion/native';
import { Badge, Divider, Drawer, List } from 'react-native-paper';
import Text from '@appComponents/Text';
import { logOut } from '@appUtils/auth';
import { useTripList } from '@appUtils/api';
import { versionText } from '@appUtils/app';

import AppLogo from '../../../assets/logo/app-logo.svg';
import AppLogoNoText from '../../../assets/logo/app-logo-no-text.svg';
import pkg from '../../../package.json';
import { AccountState, useAccountRequests } from '@appUtils/manager';
import { useUnreadCompanyMessagesCount } from '@appUtils/messages';
import Button from '@appComponents/Button';
import { Menu, useMenuState } from '@appComponents/Menu';
import { useMyData } from '@appUtils/api';
import app from '@appFirebase';

const COMPLETE_SIDEBAR_SUBSCRIPTION_STATUSES = [
  'complete',
  'trialing',
  'active',
];

const hideSidebarHandler = company => {
  const trialEndDate = company?.trialCodeExpirationDate;
  const currentDate = new Date();

  const trialEndDateTime = trialEndDate
    ? new Date(trialEndDate.seconds * 1000 + trialEndDate.nanoseconds / 1000000)
    : null;
  const inTrialCodePeriod = trialEndDateTime && currentDate < trialEndDateTime;

  return (
    COMPLETE_SIDEBAR_SUBSCRIPTION_STATUSES.includes(
      company?.subscriptionStatus,
    ) || inTrialCodePeriod
  );
};

const Sidebar = props => {
  const focusedRoute = props.state.routes[props.state.index];
  const focusedDescriptor = props.descriptors[focusedRoute.key];
  const focusedOptions = focusedDescriptor.options;

  const tripsCount = useTripsCount();
  const userRequestCount = useAccountRequestsCount();
  const unreadMessagesCount = useUnreadCompanyMessagesCount();
  const unreadArchivedMessagesCount = useUnreadCompanyMessagesCount(true);

  const { drawerContentStyle, drawerContentContainerStyle } = focusedOptions;
  const [drawerOpen, setDrawerOpen] = useState(true);

  const [user] = useMyData();
  const [company, setCompany] = useState(null);
  const managementCompany = user?.managementCompany;

  // Navigation doesn't work if it's setup inside child nav components for unknown reasons
  // Child nav components (e.g.  archived) need onPress and linkProps defined here
  const { onPress: archivedTripOnPress, ...archivedTripLinkProps } =
    useLinkProps({ to: { screen: 'Archived Trips' } });

  const { onPress: archivedMessageOnPress, ...archivedMessageLinkProps } =
    useLinkProps({ to: { screen: 'Archived Messages' } });

  const { onPress: archivedManagersOnPress, ...archivedManagersLinkProps } =
    useLinkProps({ to: { screen: 'Archived Managers' } });

  const { onPress: archivedOwnersOnPress, ...archivedOwnersLinkProps } =
    useLinkProps({ to: { screen: 'Archived Owners' } });

  const { onPress: archivedPassengersOnPress, ...archivedPassengersLinkProps } =
    useLinkProps({ to: { screen: 'Archived Passengers' } });

  const { onPress: archivedPilotsOnPress, ...archivedPilotsLinkProps } =
    useLinkProps({ to: { screen: 'Archived Pilots' } });

  const { onPress: archivedAircraftOnPress, ...archivedAircraftLinkProps } =
    useLinkProps({ to: { screen: 'Archived Aircraft' } });

  const { onPress: helpQuickstartOnPress, ...helpQuickstartLinkProps } =
    useLinkProps({ to: { screen: 'Help Quickstart' } });

  const { onPress: helpTermsOnPress, ...helpTermsLinkProps } = useLinkProps({
    to: { screen: 'Help Terms and Definitions' },
  });

  const { onPress: helpManualOnPress, ...helpManualLinkProps } =
    useLinkProps({ to: { screen: 'Help Manual' } });

  const { onPress: helpFaqOnPress, ...helpFaqLinkProps } =
    useLinkProps({ to: { screen: 'Help FAQ' } });

  const { onPress: helpBestPracticesOnPress, ...helpBestPracticesLinkProps } =
    useLinkProps({ to: { screen: 'Help Best-Practices' } });

  useEffect(() => {
    if (managementCompany && !company) {
      const companyRef = app
        .firestore()
        .collection('managementCompanies')
        .doc(managementCompany.docId);
      companyRef.onSnapshot(doc => {
        setCompany(doc.data());
      });
    }
  }, [company, user, managementCompany]);

  useEffect(() => {
    if (drawerOpen) {
      props.setDrawerWidth(DrawerWidths.open);
    } else {
      props.setDrawerWidth(DrawerWidths.closed);
    }
  }, [drawerOpen, props]);

  return (
    <DrawerContent>
      {drawerOpen ? (
        <AppLogo style={logoStyle} width={152} height={50} />
      ) : (
        <AppLogoNoText style={logoStyle} width={'auto'} height={50} />
      )}
      <CollapseButton
        icon={drawerOpen ? 'circled-arrow-left' : 'circled-arrow-right'}
        onPress={() => setDrawerOpen(!drawerOpen)}
        iconSize={28}
      />
      <NavDivider mt={1.5} />

      <DrawerContentScrollView
        contentContainerStyle={[
          contentContainerStyle,
          drawerContentContainerStyle,
        ]}
        style={drawerContentStyle}>
        <DrawerState.Provider value={focusedRoute}>
          {company && hideSidebarHandler(company) && (
            <>
              <NavSection pt={0}>
                <NavItem
                  name="Trips"
                  badge={tripsCount}
                  drawerOpen={drawerOpen}
                />
                <NavItem
                  name="Messages"
                  badge={unreadMessagesCount}
                  drawerOpen={drawerOpen}
                />
                <NavItem name="Aircraft" badge={0} drawerOpen={drawerOpen} />
                <NavItem name="Owners" badge={0} drawerOpen={drawerOpen} />
                <NavItem name="Pilots" badge={0} drawerOpen={drawerOpen} />
                <NavItem name="Passengers" badge={0} drawerOpen={drawerOpen} />
                <NavItem name="Managers" badge={0} drawerOpen={drawerOpen} />
                <NavItem
                  name="Account Requests"
                  label="Account Requests"
                  badge={userRequestCount}
                  drawerOpen={drawerOpen}
                />
                <NavItem name="Accounting" badge={0} drawerOpen={drawerOpen} />
                <NavItem name="Calendar" badge={0} drawerOpen={drawerOpen} />
              </NavSection>

              <NavDivider />
            </>
          )}

          <NavSection pt={0}>
            {company && hideSidebarHandler(company) && (
              <NavItem
                name="Archived"
                badge={unreadArchivedMessagesCount}
                drawerOpen={drawerOpen}>
                <ChildNavItem
                  name="Archived Trips"
                  onPress={archivedTripOnPress}
                  linkProps={archivedTripLinkProps}
                  focusedRoute={focusedRoute}
                />
                <ChildNavItem
                  name="Archived Messages"
                  badge={unreadArchivedMessagesCount}
                  onPress={archivedMessageOnPress}
                  linkProps={archivedMessageLinkProps}
                  focusedRoute={focusedRoute}
                />
                <ChildNavItem
                  name="Archived Aircraft"
                  onPress={archivedAircraftOnPress}
                  linkProps={archivedAircraftLinkProps}
                  focusedRoute={focusedRoute}
                />
                <ChildNavItem
                  name="Archived Owners"
                  onPress={archivedOwnersOnPress}
                  linkProps={archivedOwnersLinkProps}
                  focusedRoute={focusedRoute}
                />
                <ChildNavItem
                  name="Archived Pilots"
                  onPress={archivedPilotsOnPress}
                  linkProps={archivedPilotsLinkProps}
                  focusedRoute={focusedRoute}
                />
                <ChildNavItem
                  name="Archived Passengers"
                  onPress={archivedPassengersOnPress}
                  linkProps={archivedPassengersLinkProps}
                  focusedRoute={focusedRoute}
                />
                <ChildNavItem
                  name="Archived Managers"
                  onPress={archivedManagersOnPress}
                  linkProps={archivedManagersLinkProps}
                  focusedRoute={focusedRoute}
                />
              </NavItem>
            )}
            <NavItem name="Settings" drawerOpen={drawerOpen} />
            <NavItem name="Help" drawerOpen={drawerOpen}>
              <ChildNavItem
                name="Help Quickstart"
                onPress={helpQuickstartOnPress}
                linkProps={helpQuickstartLinkProps}
                focusedRoute={focusedRoute}
                icon="help"
              />
              <ChildNavItem
                name="Help Terms and Definitions"
                onPress={helpTermsOnPress}
                linkProps={helpTermsLinkProps}
                focusedRoute={focusedRoute}
                icon="help"
              />
              <ChildNavItem
                name="Help Manual"
                onPress={helpManualOnPress}
                linkProps={helpManualLinkProps}
                focusedRoute={focusedRoute}
                icon="help"
                disabled
              />
              <ChildNavItem
                name="Help FAQ"
                onPress={helpFaqOnPress}
                linkProps={helpFaqLinkProps}
                focusedRoute={focusedRoute}
                icon="help"
              />
              <ChildNavItem
                name="Help Best-Practices"
                onPress={helpBestPracticesOnPress}
                linkProps={helpBestPracticesLinkProps}
                focusedRoute={focusedRoute}
                icon="help"
              />
            </NavItem>
          </NavSection>

          <LogoutSection pt={0}>
            <NavItem name="Logout" onPress={logOut} drawerOpen={drawerOpen} />
          </LogoutSection>
        </DrawerState.Provider>
      </DrawerContentScrollView>

      <DrawerFooter drawerOpen={drawerOpen} />
    </DrawerContent>
  );
};

const useTripsCount = () => {
  const { snapshot, loading } = useTripList(tripRequestsRef);
  return loading ? 0 : snapshot.size;
};

const useAccountRequestsCount = () => {
  const { snapshot } = useAccountRequests([AccountState.NEW]);
  return snapshot?.size ?? 0;
};

const tripRequestsRef = ref =>
  ref.collection('trips').where('state', '==', 'Owner Request');

const DrawerState = createContext({});

const PrimaryNavItem = ({
  label,
  active,
  icon,
  badge,
  drawerOpen,
  onPress,
  open,
  disabled,
  linkProps,
}) => (
  <WideItem
    label={drawerOpen ? label : ''}
    active={active}
    icon={icon}
    right={
      badge
        ? () => <NavBadge drawerOpen={drawerOpen}>{badge}</NavBadge>
        : undefined
    }
    onPress={disabled ? undefined : drawerOpen ? onPress : open}
    disabled={disabled}
    {...(!disabled && drawerOpen && linkProps)}
  />
);

const CollapsedNavItem = ({
  label,
  active,
  icon,
  badge,
  onPress,
  disabled,
  linkProps,
}) => (
  <WideItem
    label={label}
    active={active}
    icon={icon}
    right={badge ? () => <NavBadge>{badge}</NavBadge> : undefined}
    onPress={disabled ? undefined : onPress}
    disabled={disabled}
    {...(!disabled && linkProps)}
  />
);

const NavItem = ({ name, disabled, badge, children, drawerOpen, ...props }) => {
  const buildLink = useLinkBuilder();
  const focusedRoute = useContext(DrawerState);
  const linkTo =
    React.Children.count(children) > 0
      ? _.get(children, [0, 'props', 'name'])
      : name;

  const { onPress, ...linkProps } = useLinkProps({ to: { screen: linkTo } });
  linkProps.href = buildLink(linkTo);

  const hasActiveChild = React.Children.toArray(children).some(
    child => focusedRoute.name === child.props.name,
  );

  const active =
    focusedRoute.name === name ||
    hasActiveChild ||
    (name === 'Trips' && _.startsWith(focusedRoute?.name, 'Trip')) ||
    (name === 'Aircraft' && _.startsWith(focusedRoute?.name, 'Aircraft')) ||
    (name === 'Managers' && _.startsWith(focusedRoute?.name, 'Manager')) ||
    (name === 'Owners' && _.startsWith(focusedRoute?.name, 'Owner')) ||
    (name === 'Passengers' && _.startsWith(focusedRoute?.name, 'Passenger')) ||
    (name === 'Pilots' && _.startsWith(focusedRoute?.name, 'Pilot'));
  const label = props.label || name.split(' ').pop();
  const icon = props.icon || label.toLowerCase().replaceAll(' ', '-');

  const { anchorEl, isOpen, open, close } = useMenuState();

  return (
    <>
      <PrimaryNavItem
        label={label}
        active={active}
        icon={icon}
        badge={badge}
        drawerOpen={drawerOpen}
        onPress={props?.onPress ?? onPress}
        open={children ? open : props?.onPress ?? onPress}
        disabled={disabled}
        linkProps={linkProps}
      />
      {children && !drawerOpen && (
        <NavMenu anchor={anchorEl} visible={isOpen} onDismiss={close}>
          <CollapsedNavItem
            label={label}
            active={active}
            icon={icon}
            badge={badge}
            onPress={e => {
              close();
              props?.onPress ? props.onPress(e) : onPress(e);
            }}
            disabled={disabled}
            linkProps={linkProps}
          />
          <AccordionContent>
            {React.Children.map(children, c => {
              return React.cloneElement(c, { close: close });
            })}
          </AccordionContent>
        </NavMenu>
      )}
      {children && drawerOpen && (active || name === 'Help') && (
        <AccordionContent>{children}</AccordionContent>
      )}
    </>
  );
};

const ChildNavItem = ({
  name,
  disabled,
  badge,
  children,
  onPress,
  linkProps,
  focusedRoute,
  close = () => null,
  ...props
}) => {
  const buildLink = useLinkBuilder();
  let linkTo =
    React.Children.count(children) > 0
      ? _.get(children, [0, 'props', 'name'])
      : name;

  if (linkProps) {
    linkProps.href = buildLink(linkTo);
  }

  const hasActiveChild = React.Children.toArray(children).some(
    child => focusedRoute?.name === child.props.name,
  );

  const active = focusedRoute?.name === name || hasActiveChild;
  const label = props.label || name.split(' ').pop();
  const icon = props.icon || label.toLowerCase().replaceAll(' ', '-');

  return (
    <CollapsedNavItem
      label={label}
      active={active}
      icon={icon}
      badge={badge}
      onPress={e => {
        close();
        onPress(e);
      }}
      disabled={disabled}
      linkProps={linkProps}
    />
  );
};

const DrawerFooter = ({ drawerOpen }) => {
  const { navigate } = useNavigation();

  return (
    <>
      <DrawerFooterLayout>
        {drawerOpen ? (
          <>
            <FooterLink onPress={() => navigate('Terms')}>Terms</FooterLink>
            <FooterLink accessibilityRole="none" color="dark">
              |
            </FooterLink>
            <FooterLink onPress={() => navigate('Privacy')}>Privacy</FooterLink>
          </>
        ) : (
          <FooterLink accessibilityRole="none"> </FooterLink>
        )}
      </DrawerFooterLayout>

      <Text align="center" color="dark" size={12} font="medium">
        {versionText(pkg.version)}
      </Text>
    </>
  );
};

const logoStyle = { margin: 'auto' };

const contentContainerStyle = { height: '100%' };

const NavDivider = styled(Divider)(({ theme, mt = 0.5 }) => ({
  marginTop: theme.layout.space(mt),
  marginBottom: theme.layout.space(0.5),
  marginHorizontal: theme.layout.space(1.875),
}));

const DrawerContent = styled.View(({ theme }) => ({
  paddingTop: theme.layout.space(2),
  paddingBottom: theme.layout.space(1.5),
  width: '100%',
  height: theme.layout.height,
  backgroundColor: theme.colors.sidebarBackground,
}));

const DrawerFooterLayout = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: theme.layout.space(1),
  paddingBottom: theme.layout.gap(2),
}));

const NavSection = styled(List.Section)(({ theme, pt = 2 }) => ({
  paddingTop: theme.layout.verticalSpace(pt),
}));

const LogoutSection = styled(NavSection)(() => ({
  marginTop: 'auto',
}));

const WideItem = styled(Drawer.Item)(({ theme, disabled }) => ({
  marginHorizontal: theme.layout.gap(2.5),
  marginVertical: theme.layout.gap(0.25),
  ...(disabled && { opacity: 0.33, pointerEvents: 'none' }),
  height: '40px',
  justifyContent: 'center',
  overflow: 'visible',
}));

const AccordionContent = styled.View(({ theme }) => ({
  marginLeft: theme.layout.space(1.25),
}));

const NavBadge = styled(Badge)(({ drawerOpen = true }) => ({
  marginBottom: drawerOpen ? '0px' : '25px',
  zIndex: '1',
}));

const FooterLink = styled(Text)`
  font-size: 12px;
  margin: 0 2px;
`;

FooterLink.defaultProps = {
  accessibilityRole: 'link',
  color: 'primary',
};

const NavMenu = styled(Menu)`
  margin-top: 15px;
`;

const CollapseButton = styled(Button)`
  width: fit-content;
  align-self: flex-end;
  margin-right: -18px;
  background: transparent;
`;

const DrawerWidths = {
  open: 260,
  closed: 80,
};

export default Sidebar;
