/**
 * @file
 * Aircraft related hooks and APIs
 *
 * @format
 * @flow strict-local
 */

import { useCallback, useMemo } from 'react';
import _ from 'lodash';

import app, { FieldValue, getUid, useDocumentData } from '@appFirebase';
import {
  getMyCompanyRef,
  getOverlappingTrips,
  queryTripsInRange,
  useList,
  useTripList,
} from '@appUtils/api';
import { Trip, UserRole } from '@appUtils/tripConverter';
import { SquawkStatus, SquawkPriority } from './squawks';
import { defaultChecklistItems } from './constants';

const defaultPaymentMethods = [
  {
    name: 'Pilot Card',
    billedToRole: UserRole.PILOT,
    billedToName: 'Pilot',
  },
  {
    name: 'Pilot Cash',
    billedToRole: UserRole.PILOT,
    billedToName: 'Pilot',
  },
  {
    name: 'Owner Card',
    billedToRole: UserRole.OWNER,
    billedToName: 'Owner',
  },
  {
    name: 'Company Card',
    billedToRole: UserRole.MANAGER,
  },
];

export const useAircraftList = (includeArchived = false) => {
  const query = useCallback(ref => {
    return ref.collection('aircraft');
  }, []);

  let archiveFilter = aircraft => aircraft.archived;
  if (!includeArchived) {
    archiveFilter = aircraft =>
      _.isUndefined(aircraft?.archived) || !aircraft?.archived;
  }

  return useList(query, null, archiveFilter);
};

export const useUserAircraft = ({ id = '', role = UserRole.OWNER } = {}) => {
  const query = useCallback(
    ref => {
      const field = role === UserRole.OWNER ? 'ownerIds' : 'pilotIds';
      return ref.collection('aircraft').where(field, 'array-contains', id);
    },
    [id, role],
  );

  const archiveFilter = aircraft =>
    // is not archived or if it doesn't have an archived field we'll assume not archived
    _.isUndefined(aircraft?.archived) || !aircraft?.archived;

  return useList(query, null, archiveFilter);
};

export const useAircraftData = documentPath => {
  return useDocumentData(documentPath && app.firestore().doc(documentPath));
};

export const createAircraft = async payload => {
  const companyRef = await getMyCompanyRef();
  const aircraftRef = companyRef.collection('aircraft').doc();

  return aircraftRef.set({
    ...payload,
    dateCreated: FieldValue.serverTimestamp(),
    createdBy: getUid(),
    ownerIds: _.map(payload.owners, 'id'),
    pilotIds: _.map(payload.pilots, 'id'),
    squawkPriority: SquawkPriority[SquawkStatus.NO_SQUAWKS],
    paymentMethods: defaultPaymentMethods,
    postFlightChecklist: payload.postFlightChecklist || defaultChecklistItems,
    meteringSystem: payload.meteringSystem || 'Hobbs',
    apuMeteringSystem: payload.apuMeteringSystem || 'None',
  });
};

export const removeUserFromAircraft = ({ id, role, aircraft = [] }) => {
  aircraft?.map(a => {
    const { path, ...rest } = a;
    const updatedAircraft = { ...rest };
    if ([UserRole.PILOT, UserRole.MANAGER, UserRole.EMPLOYEE].includes(role)) {
      updatedAircraft.pilots = _.filter(a.pilots, p => p.id !== id);
    } else if (role === UserRole.OWNER) {
      updatedAircraft.owners = _.filter(a.owners, o => o.id !== id);
    }
    updateAircraft(updatedAircraft, path);
  });
};
export const updateAircraft = async (payload, documentPath) => {
  const aircraftRef = app.firestore().doc(documentPath);
  const updatedData = {
    ..._.omit(payload, ['dateCreated', 'createdBy']),
    dateUpdated: FieldValue.serverTimestamp(),
    updatedBy: getUid(),
  };
  if (payload.owners) {
    updatedData.ownerIds = _.map(payload.owners, 'id');
  }
  if (payload.pilots) {
    updatedData.pilotIds = _.map(payload.pilots, 'id');
  }
  await aircraftRef.get().then(snapshot => {
    const data = snapshot.data();
    if (snapshot.exists) {
      if (!data.paymentMethods) {
        updatedData.paymentMethods = [
          ...defaultPaymentMethods,
          ...(updatedData.paymentMethods || []),
        ];
      }
      if (!data.postFlightChecklist) {
        updatedData.postFlightChecklist = defaultChecklistItems;
      }
    }
  });
  return aircraftRef.set(updatedData, { merge: true });
};

export const useTripAircraft = (trip: Trip) => {
  const { data: aircraft } = useUserAircraft(trip.owner);
  const from = _.head(trip.legs)?.departureDate;
  const to = _.last(trip.legs)?.departureDate.endOf('day');

  const queryCb = useCallback(
    companyRef => {
      // Don't run the query if we don't yet have aircraft list loaded
      if (_.isEmpty(aircraft) || !from) {
        return undefined;
      }

      const adjustedFrom = from.minus({ days: 10 });

      return queryTripsInRange(companyRef, adjustedFrom, to).where(
        'aircraft.tailNumber',
        'in',
        aircraft.map(a => a.tailNumber),
      );
    },
    [aircraft, from, to],
  );

  const { data: trips, loading } = useTripList(queryCb);

  const [list, unavailable, available] = useMemo(() => {
    const options = { trips, from, to };
    const overlappingTrips = getOverlappingTrips(trip.identifier, options);

    const mapped = aircraft.map(craft => ({
      ...craft,
      overlappingTrips: overlappingTrips.filter(
        t => t.aircraft?.tailNumber === craft.tailNumber,
      ),
    }));

    return [mapped, ..._.partition(mapped, isUnavailable)];
  }, [aircraft, from, to, trip.identifier, trips]);

  return { available, unavailable, loading, list };
};

export const updateAircraftFuelOff = ({ aircraftPath, fuelOff, fuelDate }) => {
  updateAircraft({ fuelOff, fuelDate }, aircraftPath);
};

const isUnavailable = aircraft =>
  _.size(aircraft.overlappingTrips) > 0 ||
  aircraft.squawkPriority === SquawkPriority[SquawkStatus.UNFLIGHTWORTHY];
