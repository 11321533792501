import React, { Node, useState } from 'react';
import { Controller } from 'react-hook-form';
import { PasswordField } from '@appComponents/TextField';
import * as Phone from '@appUtils/phone';
import TextField from '@appComponents/TextField';

type FormFieldProps = {
  control: Control,
};

const renderTextField = (
  label,
  onChange,
  value,
  onBlur,
  error,
  disabled,
  rest,
) => (
  <TextField
    label={label}
    value={value}
    onBlur={onBlur}
    onChangeText={onChange}
    error={error}
    errorMessage={error?.message}
    disabled={disabled}
    {...rest}
  />
);

export const TextFormField = ({
  label,
  name,
  control,
  rules,
  disabled = false,
  ...rest
}: TextFormFieldProps): Node => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    shouldUnregister={false}
    render={({ field: { onChange, value, onBlur }, fieldState: { error } }) =>
      renderTextField(label, onChange, value, onBlur, error, disabled, rest)
    }
  />
);

export const EmailFormField = ({
  control,
  optional = false,
}: FormFieldProps): Node => (
  <TextFormField
    label="Email"
    name="email"
    placeholder="Email"
    autoCapitalize="none"
    keyboardType="email-address"
    control={control}
    rules={RULES(optional).EMAIL}
  />
);

export const FirstNameFormField = ({
  control,
  optional = false,
}: FormFieldProps): Node => (
  <TextFormField
    label="First Name"
    name="firstName"
    placeholder="First name"
    control={control}
    rules={RULES(optional).FIRST_NAME}
  />
);

export const LastNameFormField = ({
  control,
  optional = false,
}: FormFieldProps): Node => (
  <TextFormField
    label="Last Name"
    name="lastName"
    placeholder="Last name"
    control={control}
    rules={RULES(optional).LAST_NAME}
  />
);

export const WeightFormField = ({
  control,
  optional = false,
}: FormFieldProps): Node => (
  <TextFormField
    label="Weight (lbs)"
    name="weight"
    placeholder="Weight (lbs)"
    control={control}
  />
);

export const PasswordFormField = ({
  control,
  optional = false,
}: FormFieldProps): Node => (
  <Controller
    control={control}
    name="password"
    rules={RULES(optional).PASSWORD}
    render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => {
      return (
        <PasswordFormFieldWithIcon
          label="Enter new password"
          name="password"
          control={control}
          rules={RULES(optional).PASSWORD}
        />
      );
    }}
  />
);

export const ConfirmPasswordFormField = ({
  control,
  optional = false,
}: FormFieldProps): Node => (
  <Controller
    control={control}
    name="currentPassword"
    rules={RULES(optional).PASSWORD}
    render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => {
      return (
        <PasswordFormFieldWithIcon
          label="Confirm your password"
          name="currentPassword"
          control={control}
          rules={RULES(optional).PASSWORD}
          style={{ height: 45 }}
          iconStyle={{ marginTop: 12 }}
        />
      );
    }}
  />
);

export const TrialCodeFormField = ({
  control,
  hasLabel = false,
}: FormFieldProps): Node => (
  <TextFormField
    label={hasLabel ? 'Trial Code' : undefined}
    name="trialCode"
    control={control}
    placeholder="Trial Code"
  />
);

export const PhoneNumberFormField = ({
  label = 'Phone number',
  name = 'phoneNumber',
  control,
  placeholder = 'Phone no',
  optional = false,
  rules = RULES(optional).PHONE,
  disabled = false,
  ...rest
}: TextFormFieldProps): Node => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    shouldUnregister={false}
    render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => {
      const phoneNumber = Phone.parse(value ?? '');
      const val = phoneNumber?.isValid() ? phoneNumber.formatNational() : value;

      return (
        <TextFormField
          label={label}
          name={name}
          control={control}
          rules={rules}
          onBlur={onBlur}
          onChange={onChange}
          value={val}
          error={error}
          disabled={disabled}
          placeholder={placeholder}
          keyboardType="number-pad"
          {...rest}
        />
      );
    }}
  />
);

const renderPasswordField = (
  label,
  onChange,
  value,
  onBlur,
  error,
  disabled,
  rest,
) => (
  <PasswordField
    label={label}
    value={value}
    onBlur={onBlur}
    onChangeText={onChange}
    error={error}
    disabled={disabled}
    {...rest}
  />
);

export const PasswordFormFieldWithIcon = ({
  label,
  name,
  control,
  rules,
  disabled = false,
  icon,
  ...rest
}: TextFormFieldProps): Node => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      shouldUnregister={false}
      render={({ field: { onChange, value, onBlur }, fieldState: { error } }) =>
        renderPasswordField(
          label,
          onChange,
          value,
          onBlur,
          error,
          disabled,
          rest,
        )
      }
    />
  );
};

export const RULES = optional => ({
  EMAIL: {
    ...(!optional && { required: 'Email is required' }),
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: 'Enter a valid email address',
    },
  },
  FIRST_NAME: {
    ...(!optional && { required: 'First Name is required' }),
    pattern: {
      value: /^(?!\s*$).+$/i,
      message: 'First Name cannot be blank',
    },
  },
  LAST_NAME: {
    ...(!optional && { required: 'Last Name is required' }),
    pattern: {
      value: /^(?!\s*$).+$/i,
      message: 'Last Name cannot be blank',
    },
  },
  COMPANY_NAME: {
    ...(!optional && { required: 'Company Name is required' }),
    pattern: {
      value: /^(?!\s*$).+$/i,
      message: 'Company Name cannot be blank',
    },
  },
  PHONE: {
    ...(!optional && { required: 'Phone Number is required' }),
    validate: value => {
      if (optional && !value) {
        return true;
      }

      return Phone.parse(value)?.isValid()
        ? true
        : 'Enter a valid 10-digit phone number';
    },
  },
});
