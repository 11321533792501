import React from 'react';
import { useWindowDimensions } from 'react-native';
import styled from '@emotion/native';
import AuthImage from '../../../../assets/img/login-image.png';

const AuthImageBackground = () => {
  const { width } = useWindowDimensions();
  if (width >= 768) {
    return <ImageBackground source={{ uri: AuthImage }} />;
  }
  return null;
};

const ImageBackground = styled.ImageBackground`
  flex-grow: 1;
  resize-mode: cover;
  justify-content: center;
`;

export default AuthImageBackground;
