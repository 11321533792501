import React, { useCallback, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useAsyncCallback } from 'react-async-hook';
import { Menu } from 'react-native-paper';
import _ from 'lodash';

import Button from '@appComponents/Button';
import { useMenuState } from '@appComponents/Menu';
import { ScreenLoader } from '@appComponents/ScreenLayout';
import ArchiveDialog from './UserDetails/ArchiveDialog';
import {
  useCompanyData,
  useCompanyUsers,
  useCompanyUsersFull,
  archiveOrDeleteUser,
  restoreUser,
} from '@appUtils/api';
import { useUserAircraft, removeUserFromAircraft } from '@appUtils/aircraft';
import { UserRole } from '@appUtils/tripConverter';
import { Cell, NameCell, PhoneCell, PlainCell, UsersTable } from './TableCells';

const UserSupplier = ({ company, roles, users, setUsers, archived }) => {
  const { data } = useCompanyUsersFull({
    roles,
    company,
    archived,
  });
  if (data && !_.isEqual(data, users)) {
    setUsers(data);
  }

  return null;
};

const UserList = ({
  role = UserRole.OWNER,
  withActions = false,
  showArchived = false,
}: UserListProps) => {
  const [company, companyLoading] = useCompanyData();
  const [users, setUsers] = useState([]);
  const [owners, ownersLoading] = useCompanyUsers(UserRole.OWNER);
  const waitForOwners = role === UserRole.PASSENGER && ownersLoading;
  const roles =
    role === UserRole.MANAGER ? [UserRole.MANAGER, UserRole.EMPLOYEE] : [role];

  const [isArchiving, setIsArchiving] = useState(false);
  const [archiveMode, setArchiveMode] = useState('archive');
  const [selectedUser, setSelectedUser] = useState(null);
  const { data: aircraft = [] } = useUserAircraft({
    id: selectedUser?.id,
    role: selectedUser?.role,
  });

  const onConfirmArchive = async () => {
    if (!selectedUser) {
      return;
    }

    try {
      setIsArchiving(false);

      if (archiveMode === 'archive') {
        await archiveOrDeleteUser(selectedUser.id, 'archive');
        if (selectedUser?.id && selectedUser?.role && !_.isEmpty(aircraft)) {
          removeUserFromAircraft({
            id: selectedUser.id,
            role: selectedUser.role,
            aircraft,
          });
        }
      } else if (archiveMode === 'restore') {
        await restoreUser(selectedUser.id);
      } else if (archiveMode === 'delete') {
        await archiveOrDeleteUser(selectedUser.id, 'delete');
      }
    } catch (error) {
      console.error(`Error during ${archiveMode}:`, error);
    }
  };

  if (!company?.id || companyLoading || waitForOwners) {
    return <ScreenLoader />;
  }

  return (
    <>
      <UserSupplier
        company={company}
        roles={roles}
        users={users}
        setUsers={setUsers}
        archived={showArchived}
      />
      <UsersTable list={users} listType={role}>
        <NameCell title="Name" flex={40} />
        <PlainCell title="Email" path="email" flex={30} />
        <PhoneCell title="Phone" flex={15} />
        {role === UserRole.PASSENGER && (
          <OwnersCell title="Owners" owners={owners} flex={15} />
        )}
        {role !== UserRole.PASSENGER && (
          <AircraftCell title="Aircraft" role={role} flex={15} />
        )}
        {withActions && (
          <ActionsCell
            numeric
            name="actions"
            icon="more-dots"
            role={role}
            flex={3}
            setSelectedUser={setSelectedUser}
            setIsArchiving={setIsArchiving}
            setArchiveMode={setArchiveMode}
          />
        )}
      </UsersTable>

      <ArchiveDialog
        show={isArchiving}
        archiveMode={archiveMode}
        onConfirm={onConfirmArchive}
        onCancel={() => setIsArchiving(false)}
      />
    </>
  );
};

const AircraftCell = ({ item: user = {}, role = '', flex }) => {
  const { data = [] } = useUserAircraft({ id: user?.id, role });

  return (
    <Cell flex={flex}>
      {_.isEmpty(data) && '-'}
      {_.map(data, craft => (
        <>
          {craft.tailNumber}
          <br />
        </>
      ))}
    </Cell>
  );
};

const OwnersCell = ({ item: user = {}, owners, flex }) => {
  const userOwners = user?.ownerIds?.map(id => {
    const owner = _.find(owners, o => o.id === id);
    return `${owner?.firstName} ${owner?.lastName}`;
  });

  return (
    <Cell flex={flex}>
      {_.isEmpty(userOwners)
        ? '-'
        : userOwners.map(u => (
            <>
              {u}
              <br />
            </>
          ))}
    </Cell>
  );
};

const ActionsCell = ({
  item: user,
  role,
  setSelectedUser,
  setIsArchiving,
  setArchiveMode,
  ...cellProps
}) => {
  const { open, isOpen, close } = useMenuState();
  const { actions, onAction } = useActions(user, role);

  const action = useAsyncCallback(userAction => {
    close();

    if (userAction === 'View') {
      return onAction({ action: userAction, user });
    }

    setSelectedUser(user);
    setIsArchiving(true);
    setArchiveMode(
      userAction === 'Archive'
        ? 'archive'
        : userAction === 'Restore'
        ? 'restore'
        : 'delete',
    );
  });

  const anchor = (
    <Button
      mode="text"
      icon="more-dots"
      loading={action.loading}
      onPress={open}
    />
  );

  return (
    <Cell {...cellProps}>
      <Menu anchor={anchor} visible={isOpen} onDismiss={close}>
        {_.map(actions, title => (
          <Menu.Item
            key={title}
            title={title}
            onPress={() => action.execute(title)}
          />
        ))}
      </Menu>
    </Cell>
  );
};

const useActions = (user, role) => {
  const { navigate } = useNavigation();

  const onAction = useCallback(
    async ({ action }) => {
      if (action === 'View') {
        navigate(`${_.capitalize(user.role)} Details`, {
          userId: user.id,
          title: `${user.firstName} ${user.lastName}`,
        });
      }
    },
    [navigate, user],
  );

  const actions = ['View'];

  if (user.archived) {
    actions.push('Restore', 'Delete');
  } else {
    actions.push('Archive');
  }

  return {
    actions,
    onAction,
  };
};

type UserListProps = {
  role: 'owner' | 'employee' | 'manager' | 'pilot',
  withActions?: boolean,
  showArchived?: boolean,
};

export default UserList;
