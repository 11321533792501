/**
 * @file
 * List passengers and their documents
 * Allow adding/editing/removing documents for passengers and uploading
 * images for the documents
 *
 * @format
 * @flow strict-local
 */
import Documents from '@webComponents/UserDetails/Documents';
import React from 'react';
import { Divider } from 'react-native-paper';

import { SectionHeader, Box } from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import { usePassengers } from '@appUtils/user';

const PassengerDocuments = ({ ownerId }) => {
  const { passengers, updatePassenger } = usePassengers(ownerId);

  return (
    <Box>
      <SectionHeader>
        <Text color="dark" weight={500}>
          PASSENGERS DOCUMENTS
        </Text>
      </SectionHeader>
      <Box ph={1}>
        {passengers.map((passenger, i) => (
          <Box key={passenger.name} pt={1}>
            <Text>{passenger.name.toUpperCase()}</Text>
            <Documents
              sectionTitle={false}
              user={passenger}
              storagePath={`${ownerId}/documents`}
              saveDocuments={documents => updatePassenger({ documents }, i)}
            />
            <Divider />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PassengerDocuments;
