/**
 * @file
 * User Documents list with add/edit functionality
 *
 * @format
 * @flow strict-local
 */
import React, { useCallback, useState } from 'react';
import { ImageViewer } from '@appComponents/ImageViewer';
import { AddButton, RemoveButton } from '@appComponents/List';
import TextField from '@appComponents/TextField';
import _ from 'lodash';

import { Box, SectionHeader, Spacer } from '@appComponents/ScreenLayout';
import { useDocumentImages } from '@appUtils/documents';
import Text from '@appComponents/Text';
import { List as PaperList, Switch } from 'react-native-paper';
import { removeFile, uploadFile, useStorageImages } from '../../utils/storage';
import EditCell from './EditCell';

const Documents = ({
  user,
  saveDocuments,
  storagePath = `${user.id}/documents`,
  sectionTitle = 'DOCUMENTS',
}: DocumentProps) => {
  const { documents, addNew, save } = useDocuments(user, saveDocuments);
  const disableSaving =
    _.last(documents) && canSaveDocument(_.last(documents)) === false;

  return (
    <>
      {sectionTitle ? (
        <SectionHeader>
          <Text color="dark" weight={500}>
            {sectionTitle.toUpperCase()}
          </Text>
        </SectionHeader>
      ) : null}

      <Box ph={0.5} pv={1}>
        <DocumentList
          storagePath={storagePath}
          items={documents}
          onSave={save}
        />
        <Spacer />
        <AddButton
          onPress={addNew}
          disabled={_.size(documents) === MAX_DOCUMENTS || disableSaving}>
          Add Document
        </AddButton>
      </Box>
    </>
  );
};

const DocumentList = ({ items, storagePath, onSave }) =>
  items.map((item, index) => (
    <DocumentItem
      key={`${index}${item?.name}`}
      item={item}
      storagePath={storagePath}
      defaultExpanded={index === 0}
      onRemove={() => onSave(items.filter((entry, i) => i !== index))}
      onSave={updatedItem =>
        onSave(items.map((entry, i) => (i === index ? updatedItem : entry)))
      }
    />
  ));

const DocumentItem = ({
  item,
  storagePath,
  onSave,
  onRemove,
  defaultExpanded,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded || !item.name);
  const [isEditing, setIsEditing] = useState(!item.name);
  const [state, setState] = useState(item);

  const edit = useCallback(() => setIsEditing(true), []);

  const remove = useCallback(() => {
    state.images.forEach(image => removeFile(image.path));
    onRemove();
  }, [onRemove, state.images]);

  const changeText = useCallback(
    text => setState(existing => ({ ...existing, name: text })),
    [],
  );

  const save = useCallback(
    (nextState, shouldStopEditing = true) => {
      if (shouldStopEditing) {
        setIsEditing(false);
      }

      setState(nextState);
      // Remap so we don't save local URIs to DB
      const payload = {
        ...nextState,
        images: nextState.images.map(img => ({
          path: img.path,
          name: img.name,
        })),
      };
      onSave(payload);
    },
    [onSave],
  );

  const changeSecurityStatus = useCallback(() => {
    save(
      {
        ...state,
        isSecurityDocument: !state.isSecurityDocument,
      },
      false,
    );
  }, [save, state]);

  return (
    <PaperList.Accordion
      title={state.name || 'New Document'}
      expanded={isExpanded}
      onPress={() =>
        setIsExpanded(current => {
          const next = !current;
          if (isEditing && !next) {
            return current;
          }

          return next;
        })
      }>
      <Box
        dir="row"
        style={{
          position: 'absolute',
          top: 8,
          right: 52,
        }}>
        <SecurityDocumentSwitch
          value={state.isSecurityDocument}
          onToggle={changeSecurityStatus}
        />
        <Spacer dir="horizontal" />
        {isEditing && <RemoveButton onPress={remove} mr={0.5} />}
        <EditCell
          onEdit={edit}
          onSave={() => canSaveDocument(state) && save(state)}
          editMode={isEditing}
        />
      </Box>

      {isEditing && (
        <TextField
          dense
          selectTextOnFocus
          label="Name"
          placeholder="New Document"
          value={state.name}
          editable={isEditing}
          autoFocus={isEditing}
          blurOnSubmit={false}
          onChangeText={changeText}
          underlineColor="#ffffffff"
          activeUnderlineColor="#ffffffff"
          wrapStyle={{
            position: 'absolute',
            marginBottom: 0,
            top: 0,
            left: 2,
          }}
        />
      )}

      <Box pv={1} flex={1}>
        <DocumentImages
          storagePath={storagePath}
          images={state.images}
          onChange={images => save({ ...state, images }, false)}
        />
      </Box>
    </PaperList.Accordion>
  );
};

const DocumentImages = ({ images, storagePath, onChange }) => {
  const docImages = useDocumentImages(images, useStorageImages);

  const addImage = useCallback(
    files => {
      // Accept multiple files up to the max limit, but also consider already uploaded (initial) files
      const filesToUpload = files
        .slice(0, MAX_IMAGES - _.size(images))
        .map(file => {
          const task = uploadFile(file, storagePath);
          return {
            // Show local preview immediately
            uri: URL.createObjectURL(file),
            path: task.snapshot.ref.fullPath,
            name: file.name,
          };
        });

      onChange([...images, ...filesToUpload]);
    },
    [images, onChange, storagePath],
  );

  const removeImage = useCallback(
    path => {
      onChange(images.filter(entry => entry.path !== path));
      removeFile(path).catch(console.error);
    },
    [images, onChange],
  );

  return (
    <ImageViewer
      displayFileNames
      images={docImages}
      canAddImage={_.size(images) < MAX_IMAGES}
      handleUpload={addImage}
      handleRemove={removeImage}
    />
  );
};

const SecurityDocumentSwitch = ({ value, onToggle, style }) => (
  <Box dir="row" ai="center" style={style}>
    <Text>Security Document</Text>
    <Spacer dir="horizontal" />
    <Switch value={value} onValueChange={onToggle} />
  </Box>
);
const canSaveDocument = item => Boolean(item?.name);

const MAX_DOCUMENTS = 10;
const MAX_IMAGES = 3;

const useDocuments = (
  userData: UserData,
  saveDocuments: DocumentProps['saveDocuments'],
) => {
  // Capture remote data to local state
  // We prefer local state as the only source of truth in order to represent draft changes
  const [state, setState] = useState(userData.documents || []);

  const addNew = useCallback(() => {
    setState(existing => [...existing, { name: '', images: [] }]);
  }, []);

  const save = useCallback(
    nextDocuments => {
      setState(nextDocuments);
      return saveDocuments(nextDocuments);
    },
    [saveDocuments],
  );

  return { documents: state, addNew, save };
};

type DocumentProps = {
  user: UserData,
  saveDocuments: (documents: Document[]) => Promise,
  sectionTitle?: string,
  // The folder, where we should store document files
  storagePath?: string,
};

type UserData = {
  firstName: string,
  lastName: string,
  documents: Document[],
};

type Document = {
  name: string,
  isSecurityDocument: boolean,
  images: Array<{
    uri: string,
    path: string,
    name: string,
  }>,
};

export default Documents;
