import { isEmpty } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useMeteringContext } from '../../context/MeteringContext';

export const useDefaultValues = trip => {
  const { reset, setIsLoading, setSelectedLeg, selectedLeg, setValue } =
    useMeteringContext();

  const getDefaultValues = useCallback(
    () => ({
      vorRvsm: {
        readingTaken: '-',
      },
      trendMonitoring: {},
      flightTimes: {},
    }),
    [],
  );

  const resetDefaultValues = useCallback(() => {
    if (isEmpty(trip)) {
      return;
    }
    setIsLoading(true);

    const baseLeg = trip.legs?.[0];

    if (!selectedLeg?.id) {
      setSelectedLeg({
        id: baseLeg?.id,
        value: 0,
        label: `${baseLeg?.from} ------ ${baseLeg?.to}`,
      });
    }

    const relevantData =
      trip?.metering?.updateMode === 'leg'
        ? trip?.metering?.legs?.[selectedLeg?.id || baseLeg?.id] ||
          getDefaultValues()
        : trip?.metering?.overall || getDefaultValues();

    reset({
      ...relevantData,
      updateMode: trip?.metering?.updateMode || 'trip',
    });
    setIsLoading(false);
  }, [
    getDefaultValues,
    reset,
    selectedLeg?.id,
    setIsLoading,
    setSelectedLeg,
    trip,
  ]);

  useEffect(() => {
    resetDefaultValues();
  }, [resetDefaultValues, trip]);

  const handleUpdateModeChange = newUpdateMode => {
    setIsLoading(true);

    try {
      const relevantData =
        newUpdateMode === 'leg'
          ? trip?.metering?.legs?.[selectedLeg.id] || getDefaultValues()
          : trip?.metering?.overall || getDefaultValues();

      reset({ ...relevantData, updateMode: newUpdateMode });
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setIsLoading(false), 100);
    }
  };

  const handleSelectedLegChange = newSelectedLeg => {
    setIsLoading(true);
    try {
      setSelectedLeg(newSelectedLeg);
      if (newSelectedLeg?.id) {
        const legData =
          trip?.metering?.legs?.[newSelectedLeg.id] || getDefaultValues();

        reset({ ...legData, updateMode: 'leg' });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setIsLoading(false), 100);
    }
  };

  return {
    handleUpdateModeChange,
    handleSelectedLegChange,
  };
};
