/**
 * @file The main messages screen to display messages from all trips
 */
import React, { useState } from 'react';
import { Badge } from 'react-native-paper';
import styled from '@emotion/native';
import _ from 'lodash';
import { useNavigation } from '@react-navigation/native';

import {
  ScreenLayout,
  Box,
  MainSurface,
  Spacer,
} from '@appComponents/ScreenLayout';
import Button from '@appComponents/Button';
import { useCompanyConversations } from '@appUtils/messages';
import Text from '@appComponents/Text';
import MessageChat from '../../components/TripMessages/MessageChat';
import { useQuery } from './Trips';
import { TripTab } from '@appUtils/tripConverter';

export const LiveMessages = () => <Messages archived={false} />;
export const ArchivedMessages = () => <Messages archived />;

const Messages = ({ archived }) => {
  const [selectedTrip, setSelectedTrip] = useState({});
  let conversations = useCompanyConversations();
  const trips = useQuery({
    activeTab: TripTab.ALL,
    sort: {
      title: 'dateDeparting',
      dir: 'asc',
    },
    archived,
  });
  const { navigate } = useNavigation();

  let combinedTrips = [];
  if (!conversations.loading && !trips.loading) {
    conversations = _.mapKeys(conversations.data, value => value.tripId);
    combinedTrips = _.chain(trips.data)
      .map(trip => ({
        trip: { ...trip },
        conversation: conversations[trip.id],
      }))
      .sortBy(
        combinedTrips,
        item => item?.conversation?.managementUnreadMessages ?? 0,
      )
      .reverse()
      .value();
  }
  const tripName = `${selectedTrip.trip?.identifier} - ${selectedTrip.trip?.customName}`;

  return (
    <ScreenLayout alignItems="stretch">
      <MainSurface>
        <MainLayout dir="row">
          <TripList ai="center">
            {_.map(combinedTrips, item => (
              <TripSummary
                key={`${item.trip.id}-${item?.conversation?.id}`}
                trip={item.trip}
                conversation={item?.conversation}
                selected={item.trip.id === selectedTrip?.trip?.id}
                onClick={() => setSelectedTrip(item)}
              />
            ))}
          </TripList>
          <ChatLayout>
            <TopBar>
              <GoToTripButton
                onPress={() =>
                  navigate('Trip', {
                    documentPath: selectedTrip.trip.path,
                    title: tripName,
                    from: { name: 'Messages' },
                  })
                }
                disabled={_.isEmpty(selectedTrip)}>
                GO TO TRIP
              </GoToTripButton>
            </TopBar>
            <ChatWrapper>
              <MessageChat trip={selectedTrip.trip} height={'100%'} visible />
            </ChatWrapper>
          </ChatLayout>
        </MainLayout>
      </MainSurface>
    </ScreenLayout>
  );
};

const MainLayout = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: theme.layout.space(1),
}));

const ChatLayout = styled(Box)(() => ({
  flexGrow: 1,
  height: '100%',
  margin: 0,
}));

const TopBar = styled.View(({ theme }) => ({
  width: '100%',
  height: 48,
  paddingTop: 19,
  paddingRight: 16,
  paddingBottom: 17,
  paddingLeft: 18,
  backgroundColor: theme.colors.messageLight,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
}));

const GoToTripButton = styled(Button)(() => ({
  height: 36,
}));

const ChatWrapper = styled.View(() => ({
  boxShadow:
    '0px 0px 4px rgba(0, 0, 0, 0.12), 0px 4px 10px rgba(0, 0, 0, 0.05)',
  borderRadius: '0 0 8px 8px',
  overflow: 'hidden',
  height: 'calc(100% - 48px)',
  width: '100%',
}));

const TripList = styled(Box)(({ theme }) => ({
  width: 320,
  height: '100%',
  border: '1px rgba(0, 0, 0, 0.12) solid',
  borderRadius: 8,
  marginRight: theme.layout.space(1),
  padding: 0,
  paddingBottom: theme.layout.space(1),
  overflowY: 'auto',
  overflowX: 'hidden',
}));

const TripSummary = ({ trip, conversation, selected, onClick }) => {
  const owner = `${trip?.owner?.firstName} ${trip?.owner?.lastName} (Owner)`;
  const pilots = trip?.pilots.map((pilot, index) => {
    const name = `${pilot?.firstName} ${pilot?.lastName} `;
    const title = index > 0 ? '(SIC)' : '(PIC)';
    return name + title;
  });
  const users = [owner, ...pilots].join(', ');
  return (
    <TripSummaryBox selected={selected} onClick={onClick}>
      <Text size="medium" color="primary">
        {`${trip?.identifier} ${trip?.customName}`}
      </Text>
      <Text size="small" color="dark">
        {`${_.first(trip?.legs)?.from} > ${_.last(trip?.legs)?.to}`}
      </Text>
      <Spacer />
      <Box dir="row" ai="center">
        {conversation?.managementUnreadMessages > 0 && (
          <TripSummaryBadge>
            {conversation?.managementUnreadMessages}
          </TripSummaryBadge>
        )}
        <Text size="small" color="dark">
          {users}
        </Text>
      </Box>
    </TripSummaryBox>
  );
};

const TripSummaryBox = styled(Box)(({ theme, selected }) => ({
  padding: theme.layout.space(1),
  backgroundColor: selected
    ? theme.colors.messageSelected
    : theme.colors.messageLight,
  marginTop: theme.layout.space(1),
  marginHorizontal: theme.layout.space(1),
  borderRadius: 4,
  width: '90%',
  cursor: 'pointer',
}));

const TripSummaryBadge = styled(Badge)(() => ({
  marginRight: 5,
  alignSelf: 'center',
}));
