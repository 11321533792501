/**
 * @file
 * User (Pilot/Owner/Passenger) management page
 *
 * @format
 * @flow strict-local
 */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/native';
import _ from 'lodash';

import { FitToContentButton } from '@appComponents/Button';
import {
  ScreenLoader,
  SectionHeader,
  Spacer,
} from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import { useTheme } from '@appComponents/theme';
import {
  useCompanyUsers,
  getUserData,
  archiveOrDeleteUser,
} from '@appUtils/api';
import { useUserAircraft, removeUserFromAircraft } from '@appUtils/aircraft';
import {
  saveUserDocuments,
  saveUserOwnerIds,
  saveOwnerPassengerIds,
  useUserData,
} from '@appUtils/user';
import { UserRole } from '@appUtils/tripConverter';
import SelectOwner from '@webComponents/SelectOwner';
import { PhoneCell, PlainCell, UsersTable } from '@webComponents/TableCells';

import ArchiveDialog from './ArchiveDialog';
import EditCell from './EditCell';
import Documents from './Documents';

type UserProps = {
  userId: string,
  role: string,
  from: Object,
  children: React$Node,
};

const UserDetails = ({ userId, role, from, children }: UserProps) => {
  const [user, loading] = useUserData(userId);
  const [owners, ownersLoading] = useCompanyUsers(UserRole.OWNER);

  if (!loading && !ownersLoading) {
    return (
      <Wrapper>
        <Details
          user={user}
          role={role}
          owners={owners}
          hasFrom={Boolean(from)}>
          {children}
        </Details>
      </Wrapper>
    );
  }

  return <ScreenLoader />;
};

const Details = ({ user, role, owners, hasFrom, children }) => {
  const saveDocuments = useSaveDocumentsCallback(user.id);

  return (
    <Wrapper>
      <ContactDetails user={user} role={role} />
      {role === UserRole.PASSENGER && (
        <PassengerOwners user={user} owners={owners} hasFrom={hasFrom} />
      )}
      <Spacer size={2} />
      <Documents user={user} saveDocuments={saveDocuments} />
      {children}
    </Wrapper>
  );
};

const ContactDetails = ({ user, role = 'user' }) => {
  const [isEditing] = useState(false);

  const [isArchiving, setIsArchiving] = useState(false);
  const [archiveMode, setArchiveMode] = useState('archive'); // 'archive' | 'delete'
  const { data: aircraft = [] } = useUserAircraft({
    id: user?.id,
    role: user?.role,
  });

  const onArchive = () => {
    setIsArchiving(true);
    if (user?.archived) {
      setArchiveMode('delete');
    }
  };

  const onConfirmArchive = () => {
    try {
      setIsArchiving(false);
      archiveOrDeleteUser(user.id);
      if (
        archiveMode === 'archive' &&
        user?.id &&
        user?.role &&
        !_.isEmpty(aircraft)
      ) {
        removeUserFromAircraft({ id: user.id, role: user.role, aircraft });
      }
    } catch (error) {
      console.error('Error archiving user', error);
    }
  };

  const onCancelArchive = () => setIsArchiving(false);

  return (
    <>
      <SectionHeader>
        <Text color="dark" weight={500}>
          {_.upperCase(role)} DETAILS
        </Text>
      </SectionHeader>
      <UsersTable list={[user]} listType={role}>
        <PlainCell title="Email" path="email" flex={1} />
        <PhoneCell title="Phone" flex={1} />
        <EditCell
          numeric
          name="edit"
          editMode={isEditing}
          flex={2}
          onArchive={onArchive}
        />
      </UsersTable>

      <ArchiveDialog
        show={isArchiving}
        archiveMode={archiveMode}
        onCancel={onCancelArchive}
        onConfirm={onConfirmArchive}
      />
    </>
  );
};

const PassengerOwners = ({ user, owners, hasFrom }) => {
  const ownerRef = useRef(null);
  const userId = user.id;
  const [userOwners, setUserOwners] = useState([]);
  const theme = useTheme();
  const fieldStyle = useOwnerFieldStyle();
  useEffect(() => {
    const uOwners =
      user?.ownerIds?.map(uId => _.find(owners, o => o.id === uId)) ?? [];
    if (!_.isEqual(uOwners, userOwners)) {
      setUserOwners(uOwners);
    }
  }, [owners, user?.ownerIds, userOwners]);

  const userOwnerIds = useMemo(() => user?.ownerIds ?? [], [user?.ownerIds]);
  const [addingOwner, setAddingOwner] = useState(false);
  const { addOwner, replaceOwner, removeOwner } = useHandleOwnerChanges({
    userId,
    owners: userOwners,
    setAddingOwner,
  });

  useEffect(() => {
    // useState(hasFrom) doesn't trigger owner focus like it should so check it here instead
    if (hasFrom) {
      setAddingOwner(true);
    }
  }, [hasFrom]);

  useEffect(() => {
    if (addingOwner) {
      ownerRef?.current?.focus();
    }
  }, [addingOwner]);

  return (
    <>
      <SectionHeader>
        <Text color="dark" weight={500}>
          OWNERS
        </Text>
      </SectionHeader>
      <OwnerRows
        userOwners={userOwners}
        replaceOwner={replaceOwner}
        removeOwner={removeOwner}
        userOwnerIds={userOwnerIds}
        theme={theme}
        fieldStyle={fieldStyle}
      />
      <Spacer />
      {!addingOwner && (
        <FitToContentButton onPress={() => setAddingOwner(true)}>
          + Assign Additional Owner
        </FitToContentButton>
      )}
      {addingOwner && (
        <Row>
          <PassengerOwner
            index={userOwners.length}
            save={addOwner}
            remove={() => {
              setAddingOwner(false);
            }}
            ownerIds={userOwnerIds}
            theme={theme}
            fieldStyle={fieldStyle}
            innerRef={ownerRef}
          />
        </Row>
      )}
    </>
  );
};

const PassengerOwner = ({
  index,
  owner,
  ownerIds,
  theme,
  fieldStyle,
  save,
  remove,
  innerRef,
}) => {
  const ownerRef = useRef(null);

  return (
    <OwnerWrapper>
      <SelectOwner
        key={`${index}${owner?.id}`}
        owner={owner}
        onChange={change => {
          save({ change, index });
        }}
        style={fieldStyle}
        controlStyle={{
          backgroundColor: theme.colors.background,
          color: theme.colors.text,
          borderColor: theme.colors.fieldBorder,
        }}
        isOptionDisabled={o => ownerIds?.includes(o.id)}
        innerRef={innerRef ?? ownerRef}
      />
      <FitToContentButton
        color={'secondary'}
        icon={'bin'}
        onPress={() => {
          remove({ index });
        }}
        aSelf="center"
        mr="2"
      />
    </OwnerWrapper>
  );
};

const OWNERS_PER_ROW = 3;

const OwnerWrapper = styled.View`
  flex-direction: row;
  width: 33%;
`;

const OwnerRows = ({
  userOwners,
  replaceOwner,
  removeOwner,
  userOwnerIds,
  theme,
  fieldStyle,
}) => {
  const rows = [];
  const numRows = 1 + Math.round(userOwners.length / OWNERS_PER_ROW);
  for (let i = 0; i < numRows; i++) {
    const currentIndex = OWNERS_PER_ROW * i;
    const row = [];
    for (let j = 0; j < OWNERS_PER_ROW; j++) {
      if (userOwners[currentIndex + j]) {
        row.push(
          <PassengerOwner
            index={currentIndex + j}
            owner={userOwners[currentIndex + j]}
            save={replaceOwner}
            remove={removeOwner}
            ownerIds={userOwnerIds}
            theme={theme}
            fieldStyle={fieldStyle}
          />,
        );
      }
    }
    rows.push(<Row>{row}</Row>);
  }
  return rows;
};

const Row = styled.View`
  flex-direction: row;
  margin-top: 20;
`;

const useOwnerFieldStyle = () => {
  const theme = useTheme();
  return {
    marginRight: theme.layout.space(1),
    width: '100%',
  };
};

const useHandleOwnerChanges = ({ userId, owners, setAddingOwner }) => {
  const addOwner = useCallback(
    ({ change }) => {
      const ownerIds = owners?.map(o => o.id) ?? [];
      ownerIds.push(change.id);
      saveUserOwnerIds(userId, ownerIds);
      setAddingOwner(false);
      getUserData(change.id).then(owner => addPassengerId({ owner, userId }));
    },
    [owners, setAddingOwner, userId],
  );

  const replaceOwner = useCallback(
    ({ change, index }) => {
      const ownerIds = owners?.map(o => o.id) ?? [];
      const oldOwnerId = ownerIds[index];
      ownerIds[index] = change.id;
      saveUserOwnerIds(userId, ownerIds);
      getUserData(change.id).then(owner => addPassengerId({ owner, userId }));
      getUserData(oldOwnerId).then(owner =>
        removePassengerId({ owner, userId }),
      );
    },
    [owners, userId],
  );

  const removeOwner = useCallback(
    ({ index }) => {
      const ownerIds = owners?.map(o => o.id) ?? [];
      const ownerId = ownerIds[index];
      ownerIds.splice(index, 1);
      saveUserOwnerIds(userId, ownerIds);
      getUserData(ownerId).then(owner => removePassengerId({ owner, userId }));
    },
    [owners, userId],
  );

  return { addOwner, replaceOwner, removeOwner };
};

const addPassengerId = ({ owner, userId }) => {
  const passengerIds = owner?.passengerIds ?? [];
  passengerIds.push(userId);
  saveOwnerPassengerIds({ ownerId: owner.id, passengerIds });
};

const removePassengerId = ({ owner, userId }) => {
  let passengerIds = owner?.passengerIds ?? [];
  _.remove(passengerIds, i => i === userId);
  saveOwnerPassengerIds({ ownerId: owner.id, passengerIds });
};

const useSaveDocumentsCallback = userId => {
  return useCallback(
    documents => saveUserDocuments(userId, documents),
    [userId],
  );
};

const Wrapper = styled.ScrollView(({ theme }) => ({
  flex: 1,
  paddingVertical: theme.layout.space(2),
  paddingHorizontal: theme.layout.space(2),
}));

export default UserDetails;
