/**
 * @file
 * ImageThumbnails component
 *
 * @format
 * @flow strict-local
 */
import * as React from 'react';
import type { Node } from 'react';
import styled from '@emotion/native';
import { Platform } from 'react-native';
import { Icon } from '../theme';
import Text from '@appComponents/Text';
import { useTheme } from '@appComponents/theme';
import _ from 'lodash';

const CLOSE_SIZE = '18';

// TODO Make sure the download button actually downloads the document instead of just opening it in a new tab
// Also make sure it works for mobile

export const ImageThumbnails = ({
  currentIndex,
  images,
  onPress,
  modal,
  canAddImage = false,
  handleRemove,
  displayFileNames,
  uploadData,
}: ImageThumbnailsProps): Node => {
  const theme = useTheme();
  return (
    <ImageListWrap modal={modal}>
      {images.map(({ uri, path, name = path?.split('/').pop() }, index) => {
        const isPDF = path?.endsWith('.pdf');
        const imageData = _.find(uploadData, data => data.url === path);
        return (
          <ImageButton
            activeOpacity={0.8}
            last={!canAddImage && index === images.length - 1}
            key={`${uri}_${index}`}
            onPress={() => onPress(index)}>
            {isPDF ? (
              <Icon
                name="pdf-icon"
                style={PdfIconStyle}
                size={
                  Platform.OS === 'web'
                    ? theme.layout.verticalSpace(12)
                    : theme.layout.verticalSpace(modal ? 6.5 : 7.2)
                }
              />
            ) : (
              <ImageThumbnail
                active={index === currentIndex}
                source={{ uri }}
                modal={modal}
              />
            )}
            {/*
            <DownloadImageOverlay>
              <a href={uri} target="_blank" download={name}>
                <Icon name="download" size={CLOSE_SIZE - 3} />
              </a>
            </DownloadImageOverlay>
            */}
            {handleRemove && !modal && (
              <DeleteImageOverlay onPress={() => handleRemove(path)}>
                <Icon
                  name="close"
                  size={CLOSE_SIZE}
                  color={theme.colors.white}
                />
              </DeleteImageOverlay>
            )}
            {displayFileNames && (
              <Text lh={30} align="center" numberOfLines={1}>
                {name}
              </Text>
            )}
            {imageData && (
              <Text lh={15} align="center" numberOfLines={1}>
                Uploaded By: {imageData.uploadedBy.name},{' '}
                {imageData.uploadedBy.role}
              </Text>
            )}
          </ImageButton>
        );
      })}
    </ImageListWrap>
  );
};

const PdfIconStyle = {
  alignSelf: 'center',
};

type ImageThumbnailsProps = {
  images: Array<{
    uri: string,
    path: string,
  }>,
  currentIndex?: number,
  onPress?: () => void,
  displayFileNames?: boolean,
};

const ImageListWrap = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  ${Platform.OS !== 'web' && 'justify-content: space-between'};
`;

export const ImageButton = styled.TouchableOpacity(({ theme, last }) => ({
  marginTop: theme.layout.space(1),
  marginRight: Platform.OS === 'web' && !last ? theme.layout.space(1.5) : 0,
  maxWidth: '100%',
}));

const ImageThumbnail = styled.Image(({ theme, active, modal }) => ({
  height:
    Platform.OS === 'web'
      ? theme.layout.verticalSpace(12)
      : theme.layout.verticalSpace(modal ? 6.5 : 7.2),
  aspectRatio: 1,
  ...(active && {
    borderColor: theme.colors.primary,
    borderWidth: 3,
  }),
  alignSelf: 'center',
}));

const DeleteImageOverlay = styled.TouchableOpacity`
  position: absolute;
  top: 0;
  right: 0;
  height: ${CLOSE_SIZE}px;
  z-index: 10;
`;

const DownloadImageOverlay = styled.TouchableOpacity`
  position: absolute;
  top: 0;
  padding-right: 5px;
  right: ${CLOSE_SIZE}px;
  height: ${CLOSE_SIZE}px;
  z-index: 10;
`;

export default ImageThumbnails;
