import { useEffect } from 'react';

export const useCycleIncrement = ({
  setValue,
  trip,
  aircraft,
  updateMode,
  watchFlightTimes,
}) => {
  useEffect(() => {
    if (!watchFlightTimes || !trip?.legs) {
      return;
    }

    const incrementCycles = (
      flightTimes,
      legCount,
      numEngines,
      showAPU,
      isHobbs,
      isAPUHobbs,
    ) => {
      const updatedFlightTimes = { ...flightTimes };
      const increment = updateMode === 'leg' ? 1 : legCount;

      const updateField = (field, newVal) => {
        if (updatedFlightTimes[field] === undefined) {
          updatedFlightTimes[field] = newVal.toString();
        }
      };

      for (let i = 1; i <= numEngines; i++) {
        if (isHobbs) {
          // TODO: This will be more useful when prefill is working
          // For now this isn't helpful without also tracking if the Beginning field is
          // still focused and only setting the Ending value when Beginning loses focus
          /*
          if (
            updatedFlightTimes[`engine${i}CyclesBeginning`] &&
            !updatedFlightTimes[`engine${i}CyclesEnding`]
          ) {
            updateField(
              `engine${i}CyclesEnding`,
              updatedFlightTimes[`engine${i}CyclesBeginning`] + increment,
            );
            updateField('landingEnding', increment);
          }
          */
        } else {
          updateField(`engine${i}CyclesCurrent`, increment);
          updateField('landingCurrent', increment);
        }
      }

      if (showAPU) {
        if (isAPUHobbs) {
          // updateField('apuCycleEnding', increment);
        } else {
          updateField('apuCycleCurrent', increment);
        }
      }

      return updatedFlightTimes;
    };

    const legCount = trip.legs.length;
    const isHobbs = aircraft?.meteringSystem === 'Hobbs';
    const isAPUHobbs = aircraft?.apuMeteringSystem === 'Hobbs';
    const showAPU = ['Hobbs', 'FMS'].includes(aircraft?.apuMeteringSystem);

    const updatedFlightTimes = incrementCycles(
      watchFlightTimes,
      legCount,
      aircraft?.numEngines,
      showAPU,
      isHobbs,
      isAPUHobbs,
    );

    Object.entries(updatedFlightTimes).forEach(([key, value]) => {
      if (
        value !== watchFlightTimes[key] &&
        watchFlightTimes[key] === undefined
      ) {
        // console.info(`Setting ${key} to ${value}`);
        setValue(`flightTimes.${key}`, value, { shouldDirty: false });
      }
    });
  }, [watchFlightTimes, trip?.legs, aircraft, updateMode, setValue]);
};
