import app from '@appFirebase';
import { useAsync } from 'react-async-hook';
import { useRef } from 'react';

export const useStorageImages = paths => {
  const resultCache = useRef(new Map());

  const images = useAsync(
    async () => {
      const promises = (paths || []).map(async path => {
        if (resultCache.current.has(path)) {
          return resultCache.current.get(path);
        }

        const cache = app
          .storage()
          .ref(path)
          .getDownloadURL()
          .then(uri => ({ uri, path }))
          .catch(error => {
            resultCache.current.delete(path);
            throw error;
          });

        resultCache.current.set(path, cache);

        return cache;
      });

      return Promise.all(promises);
    },
    [paths],
    {
      setLoading: state => ({ ...state, loading: true }),
    },
  );

  if (images.error) {
    console.error(images.error);
  }

  return images.result || [];
};

export const uploadFile = (file, path = '') => {
  const ref = app.storage().ref(`${path}/${file?.name ?? Date.now()}`);
  const task = ref.put(file);
  task.on('state_changed', snap => console.log('upload progress', snap));
  task.then(() => console.log('Upload done'));
  return task;
};

export const removeFile = path => app.storage().ref(path).delete();
