import React from 'react';
import Dialog from '@appComponents/Dialog';
import Text from '@appComponents/Text';
import Button from '@appComponents/Button';
import { Spacer } from '@appComponents/ScreenLayout';

type Props = {
  show: boolean,
  archiveMode: 'archive' | 'delete' | 'restore',
  onConfirm(): void,
  onCancel(): void,
};

const ArchiveDialog = ({ show, archiveMode, onConfirm, onCancel }: Props) => {
  let text = '';
  let title = '';
  let buttonText = '';
  let isDeleting = false;

  switch (archiveMode) {
    case 'restore':
      text =
        'Are you sure you want to restore this user? You can archive them again later.';
      title = 'Restore User';
      buttonText = 'Restore';
      break;
    case 'delete':
      text =
        'Are you sure you want to delete this user? Deleted users cannot be recovered.';
      title = 'Delete User';
      buttonText = 'Delete';
      isDeleting = true;
      break;
    case 'archive':
    default:
      text =
        'Are you sure you want to archive this user? You can restore them later.';
      title = 'Archive User';
      buttonText = 'Archive';
  }

  return (
    <Dialog
      visible={show}
      title={title}
      actionSlot={
        <>
          <Button mode="outlined" color="dark" onPress={onCancel}>
            Cancel
          </Button>
          <Spacer dir="horizontal" />
          <Button
            mode="outlined"
            color={isDeleting ? 'error' : 'primary'}
            onPress={onConfirm}>
            {buttonText}
          </Button>
          <Spacer dir="horizontal" />
        </>
      }>
      <Text>{text}</Text>
    </Dialog>
  );
};

export default ArchiveDialog;
