/**
 * @file The window that overlays the trip builder and displays messages for the trip
 */
import React from 'react';
import { Badge } from 'react-native-paper';
import styled from '@emotion/native';

import Button from '@appComponents/Button';
import { Trip } from '@appUtils/tripConverter';
import MessageChat from './MessageChat';
import Text from '@appComponents/Text';

type TripMessagesProps = {
  visible: Boolean,
  onClose: Function,
  trip: Trip,
};

const TripMessages = ({ visible, onClose, trip }: TripMessagesProps) => {
  return (
    <MessageBox visible={visible}>
      <TopBar>
        <TopBarText>Messages</TopBarText>
        <CloseButton
          icon="close"
          iconSize="24"
          color="dark"
          onPress={onClose}
        />
      </TopBar>
      <MessageChat trip={trip} height={471} visible={visible} />
    </MessageBox>
  );
};

const MessageBox = styled.View(({ theme, visible }) => ({
  width: 360,
  height: 531,
  backgroundColor: theme.colors.messageLight,
  borderRadius: 20,
  display: visible ? 'block' : 'none',
  borderWidth: 1,
  borderColor: theme.colors.messageDark,
  borderStyle: 'solid',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 0px 10px rgba(0, 0, 0, 0.15)',
  overflow: 'hidden',
}));

const TopBar = styled.View(({ theme, largeViewer }) => ({
  width: '100%',
  height: 60,
  paddingTop: 19,
  paddingRight: 16,
  paddingBottom: 17,
  paddingLeft: 18,
  backgroundColor: theme.colors.messageDark,
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  flexDirection: 'row',
  justifyContent: largeViewer ? 'flex-end' : 'space-between',
  alignItems: 'center',
}));

const TopBarText = styled.Text(({ theme }) => ({
  color: theme.colors.white,
  fontFamily: theme.fonts.medium.fontFamily,
  fontSize: theme.fonts.size.medium,
}));

const CloseButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.colors.messageSelected,
}));

export const MessagesButton = ({
  messageIcon,
  trip,
  messagesVisible,
  setMessagesVisible,
  unreadMessages,
  builder,
  ml = 'auto',
  mr,
}) => {
  const labelStyle = {
    marginVertical: 8,
    marginHorizontal: 4,
    overflow: 'visible',
  };

  return (
    <Button
      ml={ml}
      mr={mr}
      mode="outlined"
      icon={messageIcon}
      disabled={!trip.id}
      onPress={() => setMessagesVisible(!messagesVisible)}
      labelStyle={labelStyle}>
      <MessageButtonContentWrap builder={builder}>
        <Text color="primary">Messages</Text>
        <FloatingBadge size={18} unreadMessages={unreadMessages}>
          {unreadMessages}
        </FloatingBadge>
      </MessageButtonContentWrap>
    </Button>
  );
};

const MessageButtonContentWrap = styled.View(({ builder }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  height: builder ? '26px' : '20px',
}));

const FloatingBadge = styled(Badge)(({ unreadMessages }) => ({
  position: 'relative',
  marginLeft: '-2%',
  top: '-40%',
  visibility: unreadMessages > 0 ? 'visible' : 'hidden',
}));

export default TripMessages;
