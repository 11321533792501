/**
 * @format
 * @flow strict-local
 */

import React, { Node } from 'react';
import type { NavigationProp } from '@react-navigation/native';

import AuthLayout from './AuthLayout';
import Text from '@appComponents/Text';
import ForgotPasswordForm from '@appComponents/forms/ForgotPasswordForm';
import { sendSignInLink } from '@appUtils/auth';
import { Spacer } from '@appComponents/ScreenLayout';
import Icon from '@appComponents/theme/Icon';
import { useTheme } from '@appComponents/theme';

const ForgotPassword = ({
  navigation,
}: {
  navigation: NavigationProp,
}): Node => {
  const theme = useTheme();
  return (
    <AuthLayout>
      <Spacer size={10} />
      <Icon name="forgot-password" color={theme.colors.background} size="90" />
      <Spacer />
      <Text color="dark" size="extraExtraLarge">
        Forgot Password
      </Text>
      <Spacer />
      <Text size="medium">No worries, we'll send you reset instructions</Text>
      <Spacer size={2} />
      <ForgotPasswordForm
        navigation={navigation}
        onSubmit={({ email }) => sendSignInLink({ email, actionCodeSettings })}
      />
    </AuthLayout>
  );
};

const actionCodeSettings = {
  handleCodeInApp: true,
  url: window.location.origin,
};

export default ForgotPassword;
