import React from 'react';

import { ScreenLayout, MainSurface } from '@appComponents/ScreenLayout';
import UserDetails from '@webComponents/UserDetails';
import { UserRole } from '@appUtils/tripConverter';

const PassengerDetails = ({ route }) => (
  <ScreenLayout alignItems="stretch">
    <MainSurface>
      <UserDetails
        userId={route.params.userId}
        role={UserRole.PASSENGER}
        from={route.params.from}
      />
    </MainSurface>
  </ScreenLayout>
);

export default PassengerDetails;
