import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import styled from '@emotion/native';
import { Menu } from 'react-native-paper';
import Text from '@appComponents/Text';
import { TripPostFlightOptions } from '@appUtils/constants';
import { usePostFlightChecklist } from '@appUtils/trip';

export const PostFlightDashboard = ({ trip, documentPath, save, update }) => {
  const {
    checklist,
    menuVisibility,
    handleStatusChange,
    toggleMenuVisibility,
  } = usePostFlightChecklist(trip, documentPath, save, update);

  return (
    <ChecklistContainer>
      <ChecklistGrid>
        {checklist.map((item, index) => (
          <ChecklistItem
            key={index}
            column={index % 2 === 0 ? 'left' : 'right'}>
            <ItemText>{item.name || 'Unnamed'}</ItemText>
            <DropdownWrapper>
              <Menu
                visible={menuVisibility[index] || false}
                onDismiss={() => toggleMenuVisibility(index)}
                anchor={
                  <TouchableStatus
                    status={item.status}
                    onPress={() => toggleMenuVisibility(index)}>
                    <StatusText status={item.status}>
                      {item.status || '-'}
                    </StatusText>
                  </TouchableStatus>
                }>
                {Object.values(TripPostFlightOptions).map(state => (
                  <Menu.Item
                    key={state}
                    onPress={() => handleStatusChange(index, state)}
                    title={state}
                  />
                ))}
              </Menu>
            </DropdownWrapper>
          </ChecklistItem>
        ))}
      </ChecklistGrid>
    </ChecklistContainer>
  );
};

const ChecklistContainer = styled.View`
  padding: 16px;
`;

const ChecklistGrid = styled.View`
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
`;

const ChecklistItem = styled(View)(({ theme, column }) => ({
  flexDirection: 'row',
  width: '48%',
  alignItems: 'center',
  height: '45px',
  marginBottom: theme.layout.space(0.5),
  alignSelf: column === 'left' ? 'flex-start' : 'flex-end',
}));

const ItemText = styled(Text)(({ theme }) => ({
  color: theme.colors.text,
  fontSize: theme.fonts.size.large,
  width: '70%',
}));

const DropdownWrapper = styled(View)`
  width: 30%;
`;

const TouchableStatus = styled(TouchableOpacity)(({ theme, status }) => ({
  paddingVertical: 8,
  paddingHorizontal: 16,
  borderColor:
    status === TripPostFlightOptions.PASS
      ? theme.colors.accepted
      : status === TripPostFlightOptions.FAIL
      ? theme.colors.error
      : theme.colors.primary,
  borderWidth: 1,
  borderRadius: 8,
  justifyContent: 'center',
  alignItems: 'center',
}));

const StatusText = styled(Text)(({ theme, status }) => ({
  color:
    status === TripPostFlightOptions.PASS
      ? theme.colors.accepted
      : status === TripPostFlightOptions.FAIL
      ? theme.colors.error
      : theme.colors.primary,
  fontSize: theme.fonts.size.medium,
}));
