import React from 'react';

import { ScreenLayout, MainSurface } from '@appComponents/ScreenLayout';
import UserDetails from '@webComponents/UserDetails';
import { UserRole } from '@appUtils/tripConverter';

const ManagerDetails = ({ route }) => (
  <ScreenLayout alignItems="stretch">
    <MainSurface>
      <UserDetails userId={route.params.userId} role={UserRole.MANAGER} />
    </MainSurface>
  </ScreenLayout>
);

export default ManagerDetails;
