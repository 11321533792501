/**
 * @file
 * A component for viewing, uploading, and deleting images on Web.
 * Currently used for squawks and expenses.
 *
 * @format
 * @flow strict-local
 */
import React from 'react';
import { Controller } from 'react-hook-form';
import { ImageViewer } from '@appComponents/ImageViewer';
import { uploadFile, removeFile } from '../utils/storage';
import { useMyData } from '@appUtils/api';

const ControlledImageViewer = ({
  control,
  images,
  editable,
  storagePath = '',
  addImageText,
  displayFileNames,
  name = 'photoUrls',
  uploadData,
}) => {
  const [userData] = useMyData();

  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <ImageViewer
          images={images || []}
          canAddImage={editable}
          addImageText={addImageText}
          displayFileNames={displayFileNames}
          uploadData={uploadData}
          handleUpload={async ([file]) => {
            const task = uploadFile(
              file,
              `${userData?.managementCompany?.docId}${storagePath}`,
            );
            await task;
            onChange([...value, task.snapshot.ref.fullPath]);
          }}
          handleRemove={async path => {
            onChange(value.filter(entry => entry !== path));
            removeFile(
              path,
              `${userData?.managementCompany?.docId}${storagePath}`,
              console.error,
            );
          }}
        />
      )}
      name={name}
      control={control}
    />
  );
};

export default ControlledImageViewer;
