import React from 'react';
import { Box } from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import Icon from '@appComponents/theme/Icon';

const GuestPassengerSubTitle = () => (
  <Box dir="row" ai="center" p={1} style={{ gap: 8 }}>
    <Icon name="guest-passenger" size={15} />
    <Text size="small" weight="500" style={{ margin: 10 }}>
      Guest passenger
    </Text>
  </Box>
);

export default GuestPassengerSubTitle;
