/**
 * @format
 * @flow strict-local
 */

import React, { Fragment, Node, useCallback } from 'react';
import styled from '@emotion/native';

import Text, { Title } from '@appComponents/Text';
import {
  Box,
  ScreenLoader,
  SectionBody,
  SectionHeader,
  Spacer,
} from '@appComponents/ScreenLayout';
import { useAircraftData } from '@appUtils/aircraft';
import { useUserData } from '@appUtils/user';
import PassengerList, {
  usePassengerListControl,
} from '@webComponents/PassengerList';
import type { PassengerItem } from '@webComponents/PassengerEdit';
import GuestPassengerSubTitle from './common/GuestPassengerSubtitle';
import { formatNumber } from '@appUtils/numbers';
import { useTheme } from '@appComponents/theme';
import { FitToContentButton } from '@appComponents/Button';
import { getTripUserIds, useTripUserDocuments } from '@appUtils/user';
import _ from 'lodash';

type PassengerProps = {
  trip: Object,
  legs: Leg[],
  changeLegs: (Leg[]) => void,
  owner: Object,
  aircraft: Object,
};

type Leg = {
  from: string,
  to: string,
  passengers: Array<PassengerItem>,
};

const Passengers = ({
  trip,
  legs = [],
  changeLegs,
  owner,
  aircraft,
}: PassengerProps): Node => {
  const { addPassenger, removePassenger, replacePassenger, allPassengers } =
    usePassengerListControl({ legs, changeLegs });
  const ownerId = owner?.id;
  const [fullOwner, ownerLoading] = useUserData(ownerId);
  const [fullAircraft, aircraftLoading] = useAircraftData(aircraft?.path);
  const tripUserIds = getTripUserIds(trip);
  const { data: tripUserDocs } = useTripUserDocuments(tripUserIds);

  const shouldRenderLegLists =
    legs.length > 1 && legs.some(leg => leg.passengers.length > 0);

  const setLeadPassenger = useChangeLeadPassengerCallback(legs, changeLegs);

  const legRenderProps = {
    legs,
    addPassenger,
    removePassenger,
    replacePassenger,
    setLeadPassenger,
    ownerId,
  };

  if (ownerLoading || aircraftLoading) {
    return <ScreenLoader />;
  }

  return (
    <PassengersLayout>
      <SectionHeader>
        <Title size="large" color="dark" weight="500">
          ACTIVE TRIP PASSENGERS
        </Title>
      </SectionHeader>
      <GuestPassengerSubTitle />
      <WeightTotals trip={trip} tripUserDocs={tripUserDocs} />
      <Section>
        <PassengerList
          ownerId={ownerId}
          numColumns={1}
          passengers={allPassengers}
          onAdd={addPassenger}
          onRemove={removePassenger}
          onReplace={replacePassenger}
          aircraft={fullAircraft}
          owner={fullOwner}
          setLead={
            legs.length === 1
              ? passenger => setLeadPassenger(legs[0], passenger)
              : undefined
          }
        />
      </Section>

      {shouldRenderLegLists && (
        <>
          <Spacer />
          <SectionHeader>
            <Title size="large" color="dark" weight="500">
              ASSIGN PASSENGERS FOR EACH LEG
            </Title>
          </SectionHeader>
          <LegRows {...legRenderProps} />
        </>
      )}
      <Spacer />
    </PassengersLayout>
  );
};

const LegRows = ({
  legs,
  addPassenger,
  removePassenger,
  updatePassenger,
  setLeadPassenger,
  ownerId,
}) =>
  legs.map((leg, legIndex) => (
    <Fragment key={leg.id}>
      <SectionHeader>
        <Title color="dark" weight={500}>
          {getLegLabel(leg, legIndex)}
        </Title>
      </SectionHeader>

      <Section>
        <PassengerList
          ownerId={ownerId}
          numColumns={1}
          passengers={leg.passengers}
          onAdd={passenger => addPassenger(passenger, legIndex)}
          onRemove={passenger => removePassenger(passenger, legIndex)}
          onReplace={updatePassenger}
          setLead={passenger => setLeadPassenger(leg, passenger)}
        />
      </Section>
    </Fragment>
  ));

const useChangeLeadPassengerCallback = (legs, changeLegs) =>
  useCallback(
    (leg, passenger) =>
      changeLegs(
        legs.map(existingLeg => {
          if (existingLeg.id === leg.id) {
            return {
              ...existingLeg,
              passengers: [
                passenger,
                ...existingLeg.passengers.filter(
                  p => p.name !== passenger.name,
                ),
              ],
            };
          }

          return existingLeg;
        }),
      ),
    [legs, changeLegs],
  );

const getLegLabel = ({ from, to }: Leg, index) =>
  `${index >= 0 ? `Leg ${index + 1} ` : ''}${from}>${to}`;

const PassengersLayout = styled.View(({ theme }) => ({
  flex: 1,
}));

const Section = styled(SectionBody)(({ theme, minHeight }) => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  minHeight,
  marginBottom: theme.layout.space(1),
  paddingHorizontal: theme.layout.space(0.5),
}));

const WeightTotals = ({ trip, tripUserDocs }) => {
  const theme = useTheme();
  const legWeights = [];

  if (trip && tripUserDocs) {
    trip.legs?.map((leg, index) => {
      const route = `${leg?.from}>${leg?.to}`;
      let passengerWeight = 0;
      let baggageWeight = 0;
      const passengers = leg.passengers ?? [];
      passengers.map(p => {
        p.baggage?.map(b => {
          baggageWeight += Number(b?.weight) ?? 0;
        });
        const passengerWithDocs = _.find(
          tripUserDocs,
          u => u.id === p.id || u.name === p.name,
        );
        if (passengerWithDocs) {
          passengerWeight += _.isEmpty(passengerWithDocs?.weight)
            ? 0
            : Number(passengerWithDocs.weight);
        } else {
          passengerWeight += _.isEmpty(p?.weight) ? 0 : Number(p.weight);
        }
      });
      legWeights.push({
        passengerWeight,
        baggageWeight,
        index,
        totalWeight: passengerWeight + baggageWeight,
        route,
      });
    });
  }

  const heaviestLeg = _.last(_.sortBy(legWeights, ['totalWeight', 'index']));

  return (
    <>
      <Spacer />
      <Box dir="row" jc="space-between" width="60%">
        <TotalBox>
          <Text>Total Passenger Weight</Text>
          <Spacer size={0.5} />
          <Text size="large" weight="bold" color={theme.colors.lightGreen}>
            {formatNumber({ value: heaviestLeg.passengerWeight })} lbs
          </Text>
        </TotalBox>
        <TotalBox>
          <Text>Total Baggage Weight</Text>
          <Spacer size={0.5} />
          <Text size="large" weight="bold" color={theme.colors.lightGreen}>
            {formatNumber({ value: heaviestLeg.baggageWeight })} lbs
          </Text>
        </TotalBox>
        <TotalBox>
          <Text>Total Weight for Heaviest Leg</Text>
          <Spacer size={0.5} />
          <Text size="large" weight="bold" color={theme.colors.lightGreen}>
            Leg {heaviestLeg.index + 1} {heaviestLeg.route} -{' '}
            {formatNumber({ value: heaviestLeg.totalWeight })} lbs
          </Text>
        </TotalBox>
      </Box>
      <Spacer />
    </>
  );
};

const TotalBox = styled(Box)(({}) => ({
  borderRadius: 8,
  backgroundColor: 'rgba(255, 255, 255, 0.06)',
  width: '32%',
  padding: 5,
}));

export default Passengers;
