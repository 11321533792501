import React from 'react';
import { ActivityIndicator } from 'react-native-paper';
import styled from '@emotion/native';
import Text from '@appComponents/Text';
import { PostFlightChecklist } from './PostFlightChecklist';
import {
  MainSurface,
  SectionHeader,
  Spacer,
} from '@appComponents/ScreenLayout';

export const AircraftChecklistTab = ({ aircraft, loading, documentPath }) => {
  return (
    <MainSurface>
      <Wrapper>
        <SectionHeader>
          <Text color="dark" weight={500}>
            POST FLIGHT CHECKLIST
          </Text>
        </SectionHeader>

        {loading ? (
          <ActivityIndicator size="large" />
        ) : (
          <>
            <Spacer />
            <PostFlightChecklist
              aircraft={aircraft}
              documentPath={documentPath}
            />
          </>
        )}
      </Wrapper>
    </MainSurface>
  );
};

const Wrapper = styled.ScrollView(({ theme }) => ({
  padding: theme.layout.space(3),
}));
