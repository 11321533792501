/**
 * @format
 * @flow strict-local
 */

import React, { useState } from 'react';
import { TextInput } from 'react-native-paper';
import styled from '@emotion/native';

import { Box, Spacer } from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import Button, { FitToContentButton } from '@appComponents/Button';
import { TextFormField } from '@appComponents/forms/FormFields';
import { useTheme } from '@appComponents/theme';
import { Controller } from 'react-hook-form';

const AddBaggageComponent = ({
  baggage = [],
  update,
  remove,
  append,
  control,
  canUpdate,
}) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const WeightStyle = {
    textAlign: 'right',
    height: theme.layout.space(2),
  };

  return (
    <Box>
      <Box dir="row" ai="center">
        <FitToContentButton
          mode="text"
          icon={expanded ? 'minus' : 'plus'}
          onPress={() => setExpanded(!expanded)}
        />
        <Text size="medium">
          {baggage.length} Bag{baggage.length === 1 ? '' : 's'}
        </Text>
      </Box>
      {expanded && (
        <Box width="80%">
          {baggage.length > 0 && (
            <LeftBorderedBox>
              {baggage?.map((b, index) => (
                <Box
                  dir="row"
                  ai="center"
                  jc="space-evenly"
                  ph={1}
                  key={b.id}
                  height={theme.layout.space(3)}>
                  <Text align="left" size="mediumLarge">
                    BAG {index + 1}:{' '}
                  </Text>
                  <Box width="40%">
                    <Controller
                      control={control}
                      name={`baggage[${index}].weight`}
                      render={({ field: { value } }) => {
                        if (value) {
                          const rawValue = value.replace(',', '');
                          if (rawValue !== b.weight) {
                            setTimeout(() => {
                              console.info('update');
                              update(index, { weight: rawValue });
                            }, 1000);
                          }
                        }
                        return (
                          <TextFormField
                            light
                            control={control}
                            value={value}
                            name={`baggage[${index}].weight`}
                            right={<TextInput.Affix text="lbs" />}
                            style={WeightStyle}
                            disabled={!canUpdate}
                          />
                        );
                      }}
                    />
                  </Box>
                  {canUpdate && (
                    <Button
                      icon="close"
                      size={24}
                      color={theme.colors.text}
                      onPress={() => {
                        remove(index);
                      }}
                    />
                  )}
                </Box>
              ))}
            </LeftBorderedBox>
          )}
          {canUpdate && (
            <>
              <Spacer />
              <FitToContentButton
                mode="outlined"
                color="white"
                onPress={() => append({ weight: '' })}>
                + ADD BAG
              </FitToContentButton>
            </>
          )}
          <Spacer />
        </Box>
      )}
    </Box>
  );
};

const LeftBorderedBox = styled(Box)(({ theme }) => ({
  borderWidth: 1,
  borderTopColor: 'transparent',
  borderRightColor: 'transparent',
  borderBottomColor: 'transparent',
  borderLeftColor: theme.colors.text,
}));

export default AddBaggageComponent;
