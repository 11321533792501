import app from '@appFirebase';

export const subscriptionManagementFunction = app
  .functions()
  .httpsCallable('subscriptionManagement');
export const updateSubscriptionFunction = app
  .functions()
  .httpsCallable('updateSubscription');
const getSubscriptionDataFunction = app
  .functions()
  .httpsCallable('getSubscriptionData');
const createConnectAccountForManagerCompany = app
  .functions()
  .httpsCallable('createConnectAccountForManagerCompany');
const getStripeDashboardLoginLinkFunction = app
  .functions()
  .httpsCallable('getStripeDashboardLoginLinkForManagerCompany');
const retriveConnectAccountFunction = app
  .functions()
  .httpsCallable('retriveConnectAccountForManagerCompany');
const createSubscriptionFunction = app
  .functions()
  .httpsCallable('createSubscription');
export const validateAndUseTrialCodeFunction = app
  .functions()
  .httpsCallable('validateAndUseTrialCode');

export const buildCheckoutUrl = async (company, user, aircraftData) => {
  let quantity = company.aircraftQuantity;
  if (!quantity) {
    quantity = aircraftData.length;
  }
  try {
    const data = await createSubscriptionFunction({
      companyDocId: user.managementCompany.docId,
      userEmail: user.email,
      quantity: quantity,
    });
    return data.data.checkoutSessionRedirect;
  } catch (error) {
    console.error('Error creating subscription:', error);
    throw error;
  }
};

export const getSubscriptionData = async companyDocId => {
  const subscriptionData = await getSubscriptionDataFunction({
    companyDocId,
  });
  return subscriptionData;
};

export const buildConnectAccountUrl = async (company, user) => {
  try {
    const data = await createConnectAccountForManagerCompany({
      companyDocId: user.managementCompany.docId,
      userEmail: user.email,
    });
    return data.data.accountLinkUrl;
  } catch (error) {
    console.error('Error creating connect account:', error);
    throw error;
  }
};

export const buildStripeDashboardLoginUrl = async user => {
  try {
    const data = await getStripeDashboardLoginLinkFunction({
      companyDocId: user.managementCompany.docId,
    });
    return data.data.loginLinkUrl;
  } catch (error) {
    console.error('Error fetching stripe dashboard login link:', error);
    throw error;
  }
};

export const retriveConnectAccount = async companyDocId => {
  try {
    const data = await retriveConnectAccountFunction({ companyDocId });
    return data.data;
  } catch (error) {
    console.error('Error fetching connect account:', error);
    throw error;
  }
};
