import React from 'react';

import { UserRole } from '@appUtils/tripConverter';
import UserRoleList from './UserRoleList';

const ArchivedPilots = () => (
  <UserRoleList role={UserRole.PILOT} withActions showArchived />
);

export default ArchivedPilots;
