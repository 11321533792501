import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { Spacer } from '@appComponents/ScreenLayout';
import { TabsContainer } from './TabsContainer';
import { TrendMonitoring } from './TrendMonitoring';
import { useMeteringContext } from '@appUtils/context/MeteringContext';
import { VorRvsm } from './VorRvsm';
import { FlightTimes } from './FlightTimes';
import { useDefaultValues } from '@appUtils/hooks/metering/useDefaultValues';

export const MeteringTab = ({ trip, ...props }) => {
  const [activeTab, setActiveTab] = useState({
    key: 'flightTimes',
    title: 'Flight Times',
  });
  const { setTripPath } = useMeteringContext();
  const { handleUpdateModeChange, handleSelectedLegChange } =
    useDefaultValues(trip);

  useEffect(() => {
    if (trip?.path) {
      setTripPath(trip.path);
    }
  }, [setTripPath, trip]);

  const tabs = [
    { key: 'flightTimes', title: 'Flight Times' },
    { key: 'trendMonitoring', title: 'Trend Monitoring' },
    { key: 'vorRvsm', title: 'VOR/RVSM' },
  ];

  const renderContent = () => {
    switch (activeTab.key) {
      case 'trendMonitoring':
        return (
          <TrendMonitoring
            trip={trip}
            aircraft={trip?.aircraft}
            handleSelectedLegChange={handleSelectedLegChange}
          />
        );
      case 'vorRvsm':
        return (
          <VorRvsm
            trip={trip}
            handleSelectedLegChange={handleSelectedLegChange}
          />
        );
      case 'flightTimes':
        return (
          <FlightTimes
            trip={trip}
            handleSelectedLegChange={handleSelectedLegChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <View style={styles.container}>
      <Spacer />
      <TabsContainer
        trip={trip}
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleUpdateModeChange={handleUpdateModeChange}
      />
      {renderContent()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 32,
  },
  centerView: {
    padding: 150,
  },
});
