import React, { createContext, useContext, useState, useCallback } from 'react';
import { useForm, FormProvider, useFormState } from 'react-hook-form';
import {
  saveMeteringData as saveMeteringDataAPI,
  saveMeteringDataMode as saveMeteringDataModeAPI,
} from '@appUtils/trip';

const MeteringContext = createContext();

export const MeteringProvider = ({ children }) => {
  const [selectedLeg, setSelectedLeg] = useState({});
  const [tripPath, setTripPath] = useState('');
  const methods = useForm();
  const { dirtyFields, touchedFields } = useFormState({
    control: methods.control,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // workaround https://github.com/react-hook-form/react-hook-form/issues/3213
  const isDirty = !!Object.keys(dirtyFields).length;

  const removeUndefinedValues = useCallback(obj => {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];

      if (value && typeof value === 'object' && !Array.isArray(value)) {
        acc[key] = removeUndefinedValues(value);
      } else if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }, []);

  const saveMeteringData = useCallback(async () => {
    setIsSaving(true);

    const formData = methods.getValues();
    const cleanedFormData = removeUndefinedValues(formData);

    const legId = formData?.updateMode === 'leg' ? selectedLeg?.id : null;

    try {
      await saveMeteringDataAPI(
        cleanedFormData,
        tripPath,
        legId,
        formData?.updateMode,
      );
      // methods.reset(formData);
      // console.log('Metering data saved successfully');
    } catch (error) {
      // console.error('Error saving metering data:', error);
    } finally {
      setIsSaving(false);
    }
  }, [methods, removeUndefinedValues, selectedLeg?.id, tripPath]);

  const saveMeteringDataMode = useCallback(
    updateMode => {
      return saveMeteringDataModeAPI(tripPath, updateMode);
    },
    [tripPath],
  );

  const value = {
    saveMeteringData,
    isDirty,
    isSaving,
    ...methods,
    isLoading,
    setIsLoading,
    setTripPath,
    touchedFields,
    selectedLeg,
    setSelectedLeg,
    saveMeteringDataMode,
  };

  return (
    <MeteringContext.Provider value={value}>
      <FormProvider {...methods}>{children}</FormProvider>
    </MeteringContext.Provider>
  );
};

export const useMeteringContext = () => {
  const context = useContext(MeteringContext);
  if (!context) {
    throw new Error(
      'useMeteringContext must be used within a MeteringProvider',
    );
  }
  return context;
};
