import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useController } from 'react-hook-form';
import Radio from '@appComponents/Radio';

export const RadioButtonField = ({
  name,
  control,
  options,
  defaultValue,
  containerStyle,
  onChangeInterceptor,
}) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <View style={[styles.container, containerStyle]}>
      {options.map(option => (
        <Radio
          key={option.label}
          label={option.label}
          value={option.value}
          checked={value === option.value}
          onChange={() => {
            onChange(option.value);
            onChangeInterceptor?.(option.value);
          }}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
