/**
 * @file
 * Company management events view
 *
 * @format
 * @flow strict-local
 */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import luxon2Plugin from '@fullcalendar/luxon2';
import styled from '@emotion/native';
import { ActivityIndicator } from 'react-native-paper';
import _ from 'lodash';
import './styles.css';

import {
  Box,
  MainSurface,
  ScreenLayout,
  Spacer,
} from '@appComponents/ScreenLayout';
import { useTheme } from '@appComponents/theme';
import PersonFilter from './PersonFilter';
import AircraftFilter from './AircraftFilter';
import { useEventsRangeCallback } from './query';

import type { Trip } from '@appUtils/tripConverter';
import { LegEventCard, EventCard } from './components';

const CompanyCalendar = ({ navigation }) => (
  <ScreenLayout alignItems="stretch">
    <MainSurface>
      <Calendar navigation={navigation} />
    </MainSurface>
  </ScreenLayout>
);

const Calendar = ({ navigation }) => {
  const calendarRef = useRef();
  const theme = useTheme();
  const [filters, setFilters] = useState({});
  const [view, setView] = useState('dayGridMonth');
  const fetchEvents = useEventsRangeCallback(filters);

  // Pass theme color(s) to style.css
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--fcToolbarBtnColor',
      theme.colors.primary,
    );
  }, [theme.colors.primary]);

  const eventSourceSuccess = useCallback(
    trips => {
      if (view.startsWith('dayGrid')) {
        return trips.map(createTripEvent);
      }

      return trips.flatMap(createLegEvents);
    },
    [view],
  );

  // Refresh events when filter or view changes. We show different events depending on the current view type
  useEffect(() => {
    // Using a timeout to prevent triggering multiple fetches due to re-renders
    const id = setTimeout(
      () => calendarRef.current.getApi().refetchEvents(),
      50,
    );
    return () => clearTimeout(id);
  }, [filters, view]);

  const openEvent = useCallback(
    ({ event }) => {
      const tripName = `${event.extendedProps.trip?.identifier} - ${event.extendedProps.trip?.customName}`;
      navigation.navigate('Trip', {
        documentPath: event.extendedProps.trip.path,
        title: tripName,
        from: { name: 'Calendar' },
      });
    },
    [navigation],
  );

  const onViewChanged = useCallback(({ view: nextView }) => {
    setView(nextView.type);
  }, []);

  return (
    <CalendarLayout>
      <AbsActivityIndicator animating={fetchEvents.loading} />
      <FullCalendar
        ref={calendarRef}
        plugins={[
          bootstrap5Plugin,
          dayGridPlugin,
          timeGridPlugin,
          luxon2Plugin,
        ]}
        headerToolbar={{
          left: 'dayGridMonth,timeGridWeek,timeGridDay',
          center: 'title',
          right: 'today prevYear,prev,next,nextYear',
        }}
        themeSystem="bootstrap5"
        initialView="dayGridMonth"
        height="100%"
        events={fetchEvents.execute}
        eventSourceSuccess={eventSourceSuccess}
        eventClick={openEvent}
        eventContent={renderEventContent}
        eventTextColor={theme.colors.text}
        eventMinHeight={40}
        slotEventOverlap={false}
        allDaySlot={false}
        eventDisplay="auto"
        viewDidMount={onViewChanged}
        views={{
          timeGridWeek: {
            eventMaxStack: 1,
          },
        }}
        defaultTimedEventDuration="01:10"
        eventBackgroundColor="transparent"
        eventBorderColor="transparent"
      />
      <Filters update={setFilters} data={filters} />
    </CalendarLayout>
  );
};

const Filters = ({ update, data }) => (
  <Box pt={1} width="100%" dir="row">
    <PersonFilter
      onChange={user => update({ user, aircraft: null })}
      selected={data?.user}
    />
    <Spacer dir="horizontal" />
    <AircraftFilter
      onChange={aircraft => update({ aircraft, user: null })}
      selected={data?.aircraft}
    />
  </Box>
);

const renderEventContent = ({ view, event, timeText, ...info }) => {
  if (event.groupId === 'legs') {
    return <LegEventCard event={event} timeText={timeText} />;
  }

  if (event.groupId === 'trips') {
    return <EventCard event={event} title={event.title} timeText={timeText} />;
  }

  return null;
};

const createTripEvent = (trip: Trip) => {
  const start = _.head(trip.legs).departureDate;
  const last = _.last(trip.legs);

  let end = last.departureDate.endOf('day');
  if (last.flightTime > 0) {
    end = last.departureDate.plus({ minutes: last.flightTime });
  }

  const title = trip.legs.map(leg => leg.from).join(' -> ') + ` -> ${last.to}`;

  return {
    id: trip.identifier,
    title: title,
    groupId: 'trips',
    start: start.toISO(),
    end: end.toISO(),
    extendedProps: { trip },
  };
};

const createLegEvents = (trip: Trip) =>
  trip.legs.map((leg, i) => ({
    id: `${trip.identifier} (Leg ${i + 1}/${_.size(trip.legs)})`,
    groupId: 'legs',
    start: leg.departureDate.toISO(),
    end:
      leg.flightTime > 0 &&
      leg.departureDate.plus({ minutes: leg.flightTime }).toISO(),
    extendedProps: {
      trip,
      leg,
      i,
    },
  }));

const CalendarLayout = styled.View(({ theme }) => ({
  flex: 1,
  padding: theme.layout.space(1),
  overflow: 'auto',
}));

const AbsActivityIndicator: typeof ActivityIndicator = styled(
  ActivityIndicator,
)(({ theme }) => ({
  position: 'absolute',
  left: theme.layout.space(1.5),
  top: theme.layout.space(7.5),
}));

export default CompanyCalendar;
