import React from 'react';
import { ActivityIndicator } from 'react-native-paper';
import styled from '@emotion/native';
import Text from '@appComponents/Text';
import { SectionHeader, Spacer } from '@appComponents/ScreenLayout';
import { PostFlightDashboard } from './PostFlightDashboard';

export const PostFlightTab = ({ loading, BackButton, ...props }) => {
  return (
    <>
      <Wrapper>
        <SectionHeader>
          <Text color="dark" weight={500}>
            POST FLIGHT
          </Text>
        </SectionHeader>

        {loading ? (
          <ActivityIndicator size="large" />
        ) : (
          <>
            <Spacer />
            <PostFlightDashboard {...props} />
          </>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.ScrollView(({ theme }) => ({
  padding: theme.layout.space(4),
}));
