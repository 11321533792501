import React, { useState } from 'react';
import { View } from 'react-native';
import styled from '@emotion/native';
import {
  Button,
  IconButton,
  TextInput,
  useTheme,
  Dialog,
  Portal,
} from 'react-native-paper';
import Text from '@appComponents/Text';
import { updateAircraft } from '@appUtils/aircraft';
import { TripPostFlightState } from '@appUtils/constants';

export const PostFlightChecklist = ({ aircraft, documentPath }) => {
  const theme = useTheme();

  const [checklist, setChecklist] = useState(
    aircraft?.postFlightChecklist || [],
  );

  const [visible, setVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [newItemName, setNewItemName] = useState('');

  const showAddDialog = () => setVisible(true);
  const hideAddDialog = () => setVisible(false);

  const showConfirmDialog = index => {
    setCurrentItem(index);
    setConfirmVisible(true);
  };

  const hideConfirmDialog = () => setConfirmVisible(false);

  const handleChecklistChange = newChecklist => {
    setChecklist(newChecklist);

    updateAircraft(
      {
        postFlightChecklist: newChecklist.map(item => ({
          name: item.name,
          status: item.status || TripPostFlightState.NO_DATA,
        })),
      },
      documentPath,
    );
  };

  const addItem = () => {
    const updatedItems = [...checklist, { name: newItemName, editing: false }];
    setNewItemName('');
    hideAddDialog();
    handleChecklistChange(updatedItems);
  };

  const updateItem = (index, newItem) => {
    const updatedItems = [...checklist];
    updatedItems[index].name = newItem;
    setChecklist(updatedItems);
  };

  const toggleEditing = index => {
    const updatedItems = [...checklist];
    updatedItems[index].editing = !updatedItems[index].editing;
    handleChecklistChange(updatedItems);
  };

  const removeItem = () => {
    const updatedItems = checklist.filter((_, i) => i !== currentItem);
    hideConfirmDialog();
    handleChecklistChange(updatedItems);
  };

  return (
    <ChecklistContainer>
      <SectionHeader>
        <AddButton mode="contained" onPress={showAddDialog}>
          Add Field
        </AddButton>
      </SectionHeader>
      <ChecklistGrid>
        {checklist.map((item, index) => (
          <ChecklistItem
            key={index}
            column={index % 2 === 0 ? 'left' : 'right'}>
            {item.editing ? (
              <>
                <ItemInput
                  mode="outlined"
                  value={item.name}
                  onChangeText={text => updateItem(index, text)}
                  maxLength={30}
                />
                <ItemActions>
                  <StyledIconButton
                    icon="check"
                    backgroundColor={theme.colors.primary}
                    onPress={() => toggleEditing(index)}
                  />
                  <StyledIconButton
                    icon="close"
                    backgroundColor={theme.colors.error}
                    onPress={() => toggleEditing(index)}
                  />
                </ItemActions>
              </>
            ) : (
              <>
                <ItemText>{item.name || 'Unnamed'}</ItemText>
                <ItemActions>
                  <StyledIconButton
                    icon="edit"
                    backgroundColor={theme.colors.primary}
                    onPress={() => toggleEditing(index)}
                  />
                  <StyledIconButton
                    icon="bin"
                    backgroundColor={theme.colors.error}
                    onPress={() => showConfirmDialog(index)}
                  />
                </ItemActions>
              </>
            )}
          </ChecklistItem>
        ))}
      </ChecklistGrid>

      <Portal>
        <StyledDialog visible={visible} onDismiss={hideAddDialog}>
          <Dialog.Title>Add New Field</Dialog.Title>
          <Dialog.Content>
            <TextInput
              label="Field Name"
              value={newItemName}
              onChangeText={setNewItemName}
              mode="outlined"
              maxLength={30}
            />
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={hideAddDialog}>Cancel</Button>
            <Button onPress={addItem}>Add</Button>
          </Dialog.Actions>
        </StyledDialog>

        <StyledDialog visible={confirmVisible} onDismiss={hideConfirmDialog}>
          <Dialog.Title>Are you sure?</Dialog.Title>
          <Dialog.Content>
            <Text>Do you really want to remove this field?</Text>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={hideConfirmDialog}>Cancel</Button>
            <Button onPress={removeItem}>Remove</Button>
          </Dialog.Actions>
        </StyledDialog>
      </Portal>
    </ChecklistContainer>
  );
};

const ChecklistContainer = styled.View`
  padding: 16px;
`;

const SectionHeader = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 36px;
  margin-bottom: 16px;
`;

const AddButton = styled(Button)`
  height: 36px;
  justify-content: center;
`;

const ChecklistGrid = styled.View`
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between; // Ensures two columns are spaced evenly
`;

const ChecklistItem = styled(View)(({ theme, column }) => ({
  flexDirection: 'row',
  width: '48%', // Half the width for two columns with space between them
  alignItems: 'center',
  height: '45px',
  marginBottom: theme.layout.space(0.5),
  alignSelf: column === 'left' ? 'flex-start' : 'flex-end',
}));

const ItemText = styled(Text)(({ theme }) => ({
  color: theme.colors.text,
  fontSize: theme.fonts.size.large,
  width: '70%', // Adjust width to fit the new layout
}));

const ItemInput = styled(TextInput)`
  width: 70%; // Adjust width to fit the new layout
`;

const ItemActions = styled(View)`
  flex-direction: row;
  width: 30%;
  align-items: center;
  justify-content: flex-end;
`;

const StyledIconButton = styled(IconButton)`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: white;
  border-radius: 20%;
`;

const StyledDialog = styled(Dialog)`
  width: 50%;
  align-self: center;
`;
