/**
 * @file
 *
 * @format
 * @flow strict-local
 */
import React from 'react';
import { Cell } from '@webComponents/TableCells';
import Button from '@appComponents/Button';

const EditCell = ({ onEdit, onSave, editMode, onArchive, ...cellProps }) => (
  <Cell {...cellProps}>
    <EditToggle onEdit={onEdit} onSave={onSave} editMode={editMode} />
    {onArchive && <ArchiveIcon onArchive={onArchive} />}
  </Cell>
);

const ArchiveIcon = ({ onArchive }) => (
  <Button
    ml={1}
    icon="bin"
    mode="text"
    color="primary"
    onPress={onArchive}
    disabled={!onArchive}
  />
);

const EditToggle = ({ onEdit, onSave, editMode, mode = 'outlined' }) =>
  editMode ? (
    <Button
      icon="check"
      color="primary"
      mode={mode}
      disabled={!onSave}
      onPress={onSave}
    />
  ) : (
    <Button
      mode={mode}
      icon="edit"
      color="dark"
      onPress={onEdit}
      disabled={!onEdit}
    />
  );

export default EditCell;
