import React from 'react';
import styled from '@emotion/native';

import {
  Box,
  MainSurface,
  ScreenLayout,
  Spacer,
} from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import { UserRole } from '@appUtils/tripConverter';
import UserList from '@webComponents/UserList';
import Icon from '@appComponents/theme/Icon';

const Notice = ({ role }) => {
  let notice;
  switch (role) {
    case UserRole.OWNER:
    case UserRole.PILOT:
      notice = `${role.charAt(0).toUpperCase()}${role
        .slice(1)
        .toLowerCase()}s must be added through the mobile app.`;
      break;
    case UserRole.PASSENGER:
      notice =
        'App Passengers must be added through the mobile app. Guest Passengers can be added in trip builder.';
      break;
    case UserRole.MANAGER:
      notice =
        'Managers must be added on the web app login screen, "Join Company".';
  }

  return notice ? (
    <NoticeBox dir="row">
      <Box ph={0.5}>
        <Icon name="exclamation" size={24} />
      </Box>
      <Text size="medium" align="left">
        {notice}
      </Text>
    </NoticeBox>
  ) : null;
};

const NoticeBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#302024',
  borderColor: '#E42855',
  borderWidth: 1,
  borderRadius: theme.roundness,
  marginHorizontal: theme.layout.space(1.5),
  height: theme.layout.space(3),
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const UserRoleList = ({ role, withActions = false, showArchived = false }) => (
  <ScreenLayout alignItems="stretch">
    <MainSurface>
      <Spacer />
      <Notice role={role} />
      <UserList
        withActions={withActions}
        role={role}
        showArchived={showArchived}
      />
    </MainSurface>
  </ScreenLayout>
);

export default UserRoleList;
