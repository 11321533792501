/**
 * @file
 * A dialog for adding and editing Trip Expenses
 *
 * @format
 * @flow strict-local
 */
import React, { useCallback, useState } from 'react';
import styled from '@emotion/native';
import _ from 'lodash';
import { useStorageImages } from '@webUtils/storage';
import { FitToContentButton } from '@appComponents/Button';
import { useTheme } from '@appComponents/theme';
import { HeaderText } from '@webComponents/Accounting/General';
import { useForm, useFormState, Controller } from 'react-hook-form';
import Dialog, { FittedDialog } from '@appComponents/Dialog';
import {
  MoneyField,
  NumberField,
  TextFormField,
} from '@appComponents/forms/FormFields';
import SearchField from '@appComponents/SearchField';
import ControlledImageViewer from '@webComponents/ControlledImageViewer';
import { TextWrapper } from './General';
import { TextInput } from 'react-native-paper';
import { formatNumber } from '@appUtils/numbers';
import ExpenseComments from './ExpenseComments';
import { useExpenseHasComments } from '@appUtils/expenseComments';
import { ExpenseFlagIcon } from '@appComponents/ExpenseFlagIcon';

type ExpenseDialogProps = {
  mode: 'Add' | 'Edit',
  closeDialog: () => {},
};

export const useExpenseDialog = ({
  mode,
  saveTrip,
  updateTrip,
  currentExpenses,
  expense,
  expenseCategories,
  paymentMethods,
  tripId,
  fuelUnits,
  setAnyDialogOpen,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const closeDialog = useCallback(() => {
    setAnyDialogOpen(false);
    setShowDialog(false);
  }, [setAnyDialogOpen]);
  const openDialog = useCallback(() => {
    setAnyDialogOpen(true);
    setShowDialog(true);
  }, [setAnyDialogOpen]);

  // We're intentionally unmounting so the form in the dialog
  // can be reset automatically on next mount
  const dialogNode = showDialog && (
    <ExpenseDialog
      mode={mode}
      saveTrip={saveTrip}
      updateTrip={updateTrip}
      currentExpenses={currentExpenses}
      expense={expense}
      expenseCategories={expenseCategories}
      paymentMethods={paymentMethods}
      closeDialog={closeDialog}
      tripId={tripId}
      fuelUnits={fuelUnits}
    />
  );

  return {
    dialogNode,
    openDialog,
    closeDialog,
  };
};

const EXPENSE_DEFAULTS = {
  amount: null,
  date: new Date(),
  description: '',
  fuelAmount: null,
  isFlagged: false,
  location: '',
  paymentMethod: {
    name: null,
  },
  photoUrls: [],
  type: {
    fuelExpense: false,
    name: null,
  },
};

const getDefaultValues = expense => {
  if (expense) {
    const defaultValues = { ...expense };
    if (expense?.amount !== undefined) {
      defaultValues.amount = formatNumber({
        value: expense.amount,
        prefix: '$',
        decimalScale: 2,
      });
    }
    if (expense?.fuelAmount !== undefined) {
      defaultValues.fuelAmount = formatNumber({
        value: expense.fuelAmount,
        decimalScale: 1,
      });
    }
    return defaultValues;
  }
  return EXPENSE_DEFAULTS;
};

const ExpenseDialog = ({
  mode,
  closeDialog,
  saveTrip,
  updateTrip,
  currentExpenses,
  expense,
  expenseCategories,
  paymentMethods,
  tripId,
  fuelUnits,
}: ExpenseDialogProps) => {
  const { control, handleSubmit, watch } = useForm({
    defaultValues: getDefaultValues(expense),
  });
  const { dirtyFields, errors } = useFormState({ control });
  const theme = useTheme();
  const type = watch('type');
  const storagePath = `/trips/${tripId}/expenses`;
  const photoUrls = watch('photoUrls');
  const isFlagged = watch('isFlagged');
  const images = useStorageImages(photoUrls);
  const hasComments = useExpenseHasComments(tripId, expense?.id);

  const useSaveExpense = ({ isNew }) =>
    useCallback(
      payload => {
        if (!_.isEmpty(errors)) {
          return;
        }
        const expenses = _.cloneDeep(currentExpenses);
        if (isNew) {
          const amount = payload?.amount
            ? Number(payload.amount.replace('$', '').replaceAll(',', ''))
            : 0;
          const fuelAmount = payload?.fuelAmount
            ? Number(payload.fuelAmount.replaceAll(',', ''))
            : 0;
          expenses.push({
            ...payload,
            amount,
            fuelAmount,
            id: Date.now(),
          });
        } else {
          let currentExpense = _.find(expenses, ['id', expense.id]);
          for (let field of Object.keys(dirtyFields)) {
            if (field === 'amount') {
              currentExpense[field] = Number(
                payload.amount.replace('$', '').replaceAll(',', ''),
              );
            } else if (field === 'fuelAmount') {
              currentExpense[field] = Number(
                payload.fuelAmount.replaceAll(',', ''),
              );
            } else {
              currentExpense[field] = payload[field];
            }
          }
        }
        updateTrip({ expenses });
        saveTrip();
        closeDialog();
      },
      [isNew],
    );

  const saveExpense = handleSubmit(useSaveExpense({ isNew: mode === 'Add' }));
  const saveDisabled = _.isEmpty(dirtyFields);

  const { confirmationNode, openConfirmation } = useConfirmationDialog({
    closeDialog,
    saveExpense,
    saveDisabled,
  });

  const handleClose = useCallback(() => {
    if (_.isEmpty(dirtyFields)) {
      closeDialog();
      return;
    }
    openConfirmation();
  }, [closeDialog, dirtyFields, openConfirmation]);

  return (
    <Dialog
      visible
      title={`${mode} expense`}
      onDismiss={handleClose}
      actionSlot={
        <>
          <FitToContentButton color="secondary" mr="10px" onPress={handleClose}>
            CANCEL
          </FitToContentButton>
          <FitToContentButton onPress={saveExpense} disabled={saveDisabled}>
            SAVE
          </FitToContentButton>
        </>
      }>
      <ScrollWrapper>
        {confirmationNode}
        <DropDownField
          control={control}
          label="Category"
          name="type"
          options={expenseCategories}
          rules={{
            validate: {
              required: () =>
                Boolean(type?.name) || 'An expense category is required',
            },
          }}
          theme={theme}
        />
        <TextField control={control} label="Location" name="location" />
        <DropDownField
          control={control}
          label="Paid Using"
          name="paymentMethod"
          options={paymentMethods}
          theme={theme}
        />
        <TextField control={control} label="Description" name="description" />
        <MoneyField control={control} label="Cost" name="amount" />
        {type?.fuelExpense && (
          <NumberField
            control={control}
            label="Fuel Amount"
            name="fuelAmount"
            right={<TextInput.Affix text={fuelUnits} />}
            decimalScale={1}
          />
        )}
        <FlagField
          control={control}
          label="Flagged"
          name="isFlagged"
          hasComments={hasComments}
        />
        {mode !== 'Add' && (isFlagged || hasComments) && (
          <CommentField tripId={tripId} expense={expense} theme={theme} />
        )}
        <ControlledImageViewer
          images={images}
          editable={type?.name !== null}
          control={control}
          storagePath={storagePath}
          addImageText="Add images"
          displayFileNames
        />
      </ScrollWrapper>
    </Dialog>
  );
};

const ScrollWrapper = styled.ScrollView(({ theme }) => ({
  height: 0.6 * theme.layout.height,
  paddingRight: '10px',
}));

const TextField = ({ label, control, name, ...rest }) => (
  <FormRow>
    <FormCol alignSelf="center">
      <HeaderText>{label}</HeaderText>
    </FormCol>
    <FormCol flex={2}>
      <TextFormField
        light
        label={label}
        name={name}
        control={control}
        {...rest}
      />
    </FormCol>
  </FormRow>
);

const dropdownStyles = {
  height: 56,
  minWidth: 280,
};

const DropDownField = ({ label, control, name, options, theme, rules }) => (
  <FormRow>
    <FormCol alignSelf="center">
      <HeaderText>{label}</HeaderText>
    </FormCol>
    <FormCol flex={2}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <>
              <SearchField
                value={value?.name ? value : ''}
                options={options}
                onChange={onChange}
                getOptionLabel={o => o.name}
                getOptionValue={o => o.name}
                isValidNewOption={() => false}
                style={dropdownStyles}
                controlStyle={{
                  height: dropdownStyles.height,
                  backgroundColor: theme.colors.sidebarBackground,
                  color: theme.colors.text,
                  borderColor: theme.colors.fieldBorder,
                }}
                optionStyle={{
                  height: undefined,
                }}
              />
              {error && (
                <TextWrapper ml="5px" color="secondary">
                  {error.message}
                </TextWrapper>
              )}
            </>
          );
        }}
      />
    </FormCol>
  </FormRow>
);

const FlagField = ({ label, control, name, hasComments }) => (
  <FormRow>
    <FormCol alignSelf="center">
      <HeaderText>{label}</HeaderText>
    </FormCol>
    <FormCol flex={2}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <FlagRow>
              <FitToContentButton
                onPress={() => {
                  onChange(!value);
                }}>
                {value ? 'Unflag' : 'Flag'}
              </FitToContentButton>
              <ExpenseFlagIcon
                isFlagged={value}
                hasComments={hasComments}
                size="32"
              />
            </FlagRow>
          );
        }}
      />
    </FormCol>
  </FormRow>
);

const CommentField = ({ tripId, expense, theme }) => (
  <FormRow>
    <FormCol alignSelf="center">
      <HeaderText>Comments</HeaderText>
    </FormCol>
    <FormCol flex={2}>
      <ExpenseComments
        tripId={tripId}
        expense={expense}
        width="100%"
        height={theme.layout.space(18)}
      />
    </FormCol>
  </FormRow>
);

const FlagRow = styled.View`
  flex-direction: row;
  align-items: 'center';
  justify-content: space-between;
  padding-right: 40%;
`;

const FormRow = styled.View`
  flex-direction: row;
  align-items: 'center';
  justify-content: space-between;
  width: ${({ width = '100%' }) =>
    Number.isInteger(width) ? width + 'px' : width};
  min-height: ${({ height = 82 }) => height.toString()}px;
  flex-wrap: ${({ wrap }) => wrap};
  margin-top: ${({ theme, mt }) =>
    mt && `${theme.layout.space(mt).toString()}px`};
`;

const FormCol = styled.View(
  ({ theme, flex = 1, alignSelf = 'center', mr, mh, ml }) => ({
    flexDirection: 'column',
    flex,
    alignSelf,
    marginHorizontal: mh && theme.layout.space(mh),
    marginRight: mr && theme.layout.space(mr),
    marginLeft: ml && theme.layout.space(ml),
  }),
);

const useConfirmationDialog = ({ closeDialog, saveExpense, saveDisabled }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const closeConfirmation = useCallback(() => setShowConfirmation(false), []);
  const openConfirmation = useCallback(() => setShowConfirmation(true), []);

  const confirmationNode = showConfirmation && (
    <ConfirmationDialog
      closeDialog={closeDialog}
      closeConfirmation={closeConfirmation}
      saveExpense={saveExpense}
      saveDisabled={saveDisabled}
    />
  );

  return {
    confirmationNode,
    openConfirmation,
    closeConfirmation,
  };
};

const ConfirmationDialog = ({
  closeDialog,
  closeConfirmation,
  saveExpense,
  saveDisabled,
}) => (
  <FittedDialog
    visible
    title="Unsaved Expense Changes"
    onDismiss={null}
    actionSlot={
      <>
        <FitToContentButton
          color="secondary"
          mr="10px"
          onPress={closeConfirmation}>
          Cancel
        </FitToContentButton>
        <FitToContentButton
          mr="10px"
          onPress={() => {
            closeConfirmation();
            closeDialog();
          }}>
          Discard Changes
        </FitToContentButton>
        <FitToContentButton
          onPress={() => {
            saveExpense();
            closeConfirmation();
          }}
          disabled={saveDisabled}>
          Save
        </FitToContentButton>
      </>
    }>
    <HeaderText>Save expense changes?</HeaderText>
  </FittedDialog>
);

export default ExpenseDialog;
