/**
 * @format
 * @flow strict-local
 */

import React from 'react';
import { Drawer } from '@appComponents/navigation';

import Settings from './Settings/Settings';
import Help from './Help/Help';

import { useMyData } from '@appUtils/api';
import { groupOptions, useDrawerContent, useScreenOptions } from '.';

export default function SubscriptionStack() {
  const [user, loading] = useMyData();
  const { drawerWidth, drawerContent } = useDrawerContent();
  const options = useScreenOptions({ drawerWidth, user, loading });

  return (
    <Drawer.Navigator
      drawerContent={drawerContent}
      defaultStatus="open"
      backBehavior="history"
      screenOptions={options}
      initialRouteName="Settings">
      <Drawer.Group screenOptions={groupOptions}>
        <Drawer.Screen name="Settings" component={Settings} />
        <Drawer.Screen name="Help" component={Help} />
      </Drawer.Group>
    </Drawer.Navigator>
  );
}
