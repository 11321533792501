/**
 * @file
 * ImageViewerModal component
 *
 * @format
 * @flow strict-local
 */
import React, { useEffect, useState } from 'react';
import type { Node } from 'react';
import ImageThumbnails from '../ImageThumbnails';
import Dialog from '@appComponents/Dialog';
import styled from '@emotion/native';
import { ScrollView } from 'react-native';

export const ImageViewerModal = ({
  images,
  defaultIndex,
  onDismiss,
  visible,
  displayFileNames,
  uploadData,
}: ImageViewerModalProps): Node => {
  const [index, setIndex] = useState(0);
  const currentImage = images[index];
  useEffect(() => setIndex(defaultIndex), [defaultIndex]);
  const isPDF = currentImage?.path?.endsWith('.pdf');

  return (
    <ModalWrap onDismiss={onDismiss} visible={visible}>
      {isPDF ? (
          <iframe src={currentImage.uri} style={iframePDFStyle} />
      ) : (
        <OriginalImage source={currentImage} />
      )}
      <ScrollWrap>
        <ImageThumbnails
          currentIndex={index}
          images={images}
          onPress={setIndex}
          modal={true}
          displayFileNames={displayFileNames}
          uploadData={uploadData}
        />
      </ScrollWrap>
    </ModalWrap>
  );
};

const iframePDFStyle = {
  flex: 1,
};

const ScrollWrap = styled(ScrollView)`
  flex: 0.3;
`;

type ImageViewerModalProps = {
  images: Array<{
    uir: string,
    path: string,
  }>,
  defaultIndex: number,
  onDismiss?: () => void,
};

const ModalWrap = styled(Dialog)`
  height: 85%;
`;

const OriginalImage = styled.Image(({ theme }) => ({
  resizeMode: 'contain',
  flex: 1,
  marginBottom: theme.layout.space(0.5),
}));

export default ImageViewerModal;
