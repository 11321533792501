import React, { useCallback, useEffect, useState } from 'react';
import { Box, MainSurface, Spacer } from '@appComponents/ScreenLayout';
import Text from '@appComponents/Text';
import {
  SquawkPriority,
  SquawkStatus,
  SquawkTitleRules,
  createSquawk,
  updateSquawk,
  updateAircraftSquawkStatus,
  useAircraftSquawkList,
} from '@appUtils/squawks';
import { ActivityIndicator, List } from 'react-native-paper';
import styled from '@emotion/native';
import { View } from 'react-native';
import { TextFormField } from '@appComponents/forms/FormFields';
import { Controller, useForm } from 'react-hook-form';
import { Icon } from '@appComponents/theme';
import { DateTime } from 'luxon';
import { FitToContentButton } from '@appComponents/Button';
import { Divider } from 'react-native-paper';
import _ from 'lodash';
import { useStorageImages } from '../utils/storage';
import { useTheme } from '@appComponents/theme';
import SearchField from '@appComponents/SearchField';
import ControlledImageViewer from './ControlledImageViewer';

const Centered = { justifyContent: 'center' };

export const AircraftSquawks = ({ aircraftPath, archived = false }) => {
  const aircraftId = _.last(_.split(aircraftPath, '/'));
  const { data: archivedSquawks, loading: archivedLoading } =
    useAircraftSquawkList({
      aircraftId,
      priority: SquawkPriority[SquawkStatus.ARCHIVED],
      operator: '==',
    });
  const { data: activeSquawks, loading: activeLoading } = useAircraftSquawkList(
    {
      aircraftId,
      priority: SquawkPriority[SquawkStatus.ARCHIVED],
      operator: '<',
    },
  );

  const [addingNew, setAddingNew] = useState(false);

  if (activeLoading || archivedLoading) {
    return (
      <MainSurface style={Centered}>
        <ActivityIndicator size="large" />
      </MainSurface>
    );
  }

  return (
    <MainSurface>
      <FitToContentButton
        aSelf="flex-end"
        mr={5}
        disabled={addingNew}
        onPress={() => {
          setAddingNew(true);
        }}>
        + ADD SQUAWK
      </FitToContentButton>
      <ScrollWrapper>
        <Box ph={2}>
          <SquawkListHeader />
          {(archived ? archivedSquawks : activeSquawks).map((squawk, index) => (
            <SquawkContent
              key={`${index}${squawk.path}${squawk.currentStatus}`}
              squawk={squawk}
              activeSquawks={activeSquawks}
              aircraftPath={aircraftPath}
            />
          ))}
          {addingNew && (
            <SquawkContent
              key={archived ? archivedSquawks.length : activeSquawks.length}
              squawk={{ title: '', description: '' }}
              activeSquawks={activeSquawks}
              aircraftPath={aircraftPath}
              setAddingNew={setAddingNew}
            />
          )}
        </Box>
      </ScrollWrapper>
    </MainSurface>
  );
};

const widths = {
  title: 400,
  tripId: 100,
  reported: 100,
  priority: 100,
};

const SquawkContent = ({
  squawk,
  activeSquawks,
  aircraftPath,
  setAddingNew,
}) => {
  const [expanded, setExpanded] = useState(!squawk.path);
  const handlePress = () => setExpanded(!expanded);
  const defaultValues = {
    title: squawk?.title ?? '',
    description: squawk?.description ?? '',
    currentStatus: squawk?.currentStatus
      ? { value: squawk.currentStatus, label: squawk.currentStatus }
      : { value: SquawkStatus.UNDEFINED, label: SquawkStatus.UNDEFINED },
    status: squawk.status,
    photoUrls: squawk.photoUrls || [],
  };

  const {
    control,
    formState: { dirtyFields, isDirty, isSubmitSuccessful },
    getValues,
    handleSubmit,
    watch,
    reset,
  } = useForm({ defaultValues });

  const images = useStorageImages(watch('photoUrls'));

  const update = handleSubmit(
    useCallback(
      async payload => {
        if (isDirty) {
          const filteredPayload = {
            ...payload,
            currentStatus: payload.currentStatus.value,
          };
          if (squawk.path) {
            await updateSquawk({
              payload: filteredPayload,
              squawkPath: squawk.path,
              statusChanged: dirtyFields.currentStatus,
            });
            if (dirtyFields.currentStatus) {
              await updateAircraftSquawkStatus({
                aircraftPath: _.first(_.split(squawk.path, '/squawks/')),
                squawks: activeSquawks,
                newSquawkStatus: getValues('currentStatus').value,
                squawkId: _.last(_.split(squawk.path, '/')),
              });
            }
          } else {
            const result = await createSquawk({
              payload: filteredPayload,
              collection: `${aircraftPath}/squawks`,
            });
            setAddingNew(false);
            await updateAircraftSquawkStatus({
              aircraftPath: _.first(_.split(result.path, '/squawks/')),
              squawks: activeSquawks,
              newSquawkStatus: getValues('currentStatus').value,
              squawkId: _.last(_.split(result.path, '/')),
            });
          }
        }
      },
      [
        activeSquawks,
        aircraftPath,
        squawk.path,
        dirtyFields.currentStatus,
        getValues,
        isDirty,
        setAddingNew,
      ],
    ),
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [getValues, isSubmitSuccessful, reset]);

  const accordionTitle = (
    <Row>
      <AccordionText w={widths.title}>{squawk.title ?? '-'}</AccordionText>
      <AccordionText w={widths.tripId}>
        {squawk.trip?.identifier ?? '-'}
      </AccordionText>
      <AccordionText w={widths.reported}>
        {squawk.dateCreated
          ? DateTime.fromSeconds(squawk.dateCreated.seconds).toLocaleString(
              DateTime.DATE_SHORT,
            )
          : '-'}
      </AccordionText>
      <AccordionText w={widths.priority}>
        {squawk.currentStatus ?? '-'}
      </AccordionText>
    </Row>
  );

  return (
    <>
      <SquawkAccordion
        title={accordionTitle}
        right={() => null}
        left={() => <ExpansionIcon name={expanded ? 'menu-up' : 'menu-down'} />}
        expanded={expanded}
        onPress={handlePress}>
        <SquawkBox ph={2}>
          <Spacer size={4} />
          <Row jc="flex-start">
            <Label text="TITLE" />
            <FitToContentButton disabled={!isDirty} onPress={update}>
              Save
            </FitToContentButton>
          </Row>
          <TextField name="title" control={control} rules={SquawkTitleRules} />
          <Spacer />
          <Label text="DESCRIPTION" />
          <TextField name="description" control={control} />
          <Spacer />
          <Label text="IMAGES" />
          <SectionDivider width="8%" />
          <ControlledImageViewer images={images} control={control} editable />
          <Spacer />
          <Label text="STATUS" />
          <SectionDivider />
          <StatusDropdown control={control} />
          <Spacer size={4} />
        </SquawkBox>
      </SquawkAccordion>
      <Spacer size={0.2} />
    </>
  );
};

const statusOptions = [
  { value: SquawkStatus.UNDEFINED, label: SquawkStatus.UNDEFINED },
  { value: SquawkStatus.MEL, label: SquawkStatus.MEL },
  { value: SquawkStatus.UNFLIGHTWORTHY, label: SquawkStatus.UNFLIGHTWORTHY },
  { value: SquawkStatus.ARCHIVED, label: SquawkStatus.ARCHIVED },
];

const StatusDropdown = ({ control, disabled }) => {
  const theme = useTheme();
  return (
    <Controller
      control={control}
      name="currentStatus"
      render={({ field: { onChange, value } }) => {
        return (
          <SelectWrap>
            <SearchField
              value={value}
              onChange={onChange}
              disabled={disabled}
              options={statusOptions}
              menuPortalTarget={document.body}
              controlStyle={getControlStyle(theme)}
            />
          </SelectWrap>
        );
      }}
    />
  );
};

const getControlStyle = theme => ({
  backgroundColor: theme.colors.background,
  color: theme.colors.text,
});

const IconSpacer = styled(View)`
  padding-horizontal: 22px;
`;

const SquawkListHeader = () => (
  <>
    <HeaderAccordion
      title={
        <Row>
          <AccordionText weight={700} w={widths.title}>
            TITLE
          </AccordionText>
          <AccordionText weight={700} w={widths.tripId}>
            TRIP ID
          </AccordionText>
          <AccordionText weight={700} w={widths.reported}>
            REPORTED
          </AccordionText>
          <AccordionText weight={700} w={widths.priority}>
            PRIORITY
          </AccordionText>
        </Row>
      }
      right={() => null}
      left={() => <IconSpacer />}
      expanded={false}
      onPress={() => null}>
      <></>
    </HeaderAccordion>
    <Spacer size={0.2} />
  </>
);

const HeaderAccordion = styled(List.Accordion)(({ theme }) => ({
  marginVertical: '-15px',
  pointerEvents: 'none',
  backgroundColor: theme.colors.tableBackground,
  justifyContent: 'space-between',
}));

const AccordionText = styled(Text)(({ w }) => ({
  width: w ?? 100,
  overflow: 'hidden',
}));

const ExpansionIcon = styled(Icon)`
  padding-horizontal: 14;
`;

const Row = styled(View)(({ jc }) => ({
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
}));

const TextField = styled(TextFormField)(({ theme }) => ({
  width: '60%',
  backgroundColor: theme.colors.background,
}));

const SelectWrap = styled(View)`
  width: 35%;
`;

const ScrollWrapper = styled.ScrollView(({ theme }) => ({
  padding: theme.layout.space(3),
}));

const Label = ({ text }) => (
  <LabelWrap weight={700} size="smallest">
    {text}
  </LabelWrap>
);

const LabelWrap = styled(Text)`
  margin-left: 16px;
`;

const SquawkAccordion = styled(List.Accordion)`
  padding-bottom: 10px;
`;

const SectionDivider = styled(Divider)(({ theme, width }) => ({
  marginVertical: theme.layout.space(1),
  width: width ?? '80%',
}));

const SquawkBox = styled(Box)(({ theme }) => ({
  borderColor: theme.colors.background,
  borderWidth: 2,
  borderRadius: 5,
  marginTop: 1,
}));
