/**
 * @file
 * Functions used for authentication.
 * 1. Login
 * 2. Log Out
 * 3. Signing in using an email and sign-in email link
 *
 * @format
 * @flow strict-local
 */

import { useCallback } from 'react';
import app from '@appFirebase';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { EmailAuthProvider } from '../firebase';
import { getMyUserDoc } from './api';

const createUser = app.functions().httpsCallable('createUser');
const createCompany = app.functions().httpsCallable('createManagementCompany');
const verifyTrialCode = app.functions().httpsCallable('verifyTrialCode');

export const login = async ({ email, password }) =>
  app
    .auth()
    .signInWithEmailAndPassword(email, password)
    .catch(defaultCatchHandler);

export const createAccount = async ({ phoneNumber, ...payload }) => {
  const phoneNum = parsePhoneNumberFromString(phoneNumber, 'US').number;
  const { data } = await createUser({ ...payload, phoneNumber: phoneNum });

  if (data.error) {
    throw data.error;
  }

  const credential = await login(payload);
  await credential.user.sendEmailVerification();
  return data;
};

export const reauthenticateUser = async ({ email, password }) => {
  return app
    .auth()
    .currentUser.reauthenticateWithCredential(
      EmailAuthProvider.credential(email, password),
    )
    .then(() => {
      return true;
    })
    .catch(error => {
      console.log(error);
      return false;
    });
};

export const useCreateAccountCallback = (
  { setError, handleSubmit },
  setIsSubmitted = () => null,
) => {
  const submit = useCallback(
    async payload => {
      setIsSubmitted(true);
      await createAccount(payload).catch(error => {
        let errorMessage;
        switch (error.code) {
          case 'auth/weak-password':
            errorMessage = 'Please pick a stronger password.';
            break;
          case 'auth/email-already-in-use':
            errorMessage =
              'That email address is already in use.\nPlease log in or enter a different email address.';
            break;
          default:
            errorMessage = error.message;
            break;
        }
        setError('general', { type: 'manual', message: errorMessage });
        setIsSubmitted(false);
      });
      setIsSubmitted(false);
    },
    [setError, setIsSubmitted],
  );

  return handleSubmit(submit);
};

export const useCreateAccountAndCompanyCallback = (
  { setError, handleSubmit },
  setIsSubmitted = () => null,
) => {
  const submit = useCallback(
    async payload => {
      setIsSubmitted(true);
      let user;
      try {
        user = await createAccount({ ...payload, companyCreator: true });
      } catch (error) {
        let errorMessage;
        switch (error.code) {
          case 'auth/weak-password':
            errorMessage = 'Please pick a stronger password.';
            break;
          case 'auth/email-already-in-use':
            errorMessage =
              'That email address is already in use.\nPlease log in or enter a different email address.';
            break;
          case 'auth/email-already-exists':
            errorMessage =
              'That email address is already in use.\nPlease log in or enter a different email address.';
            break;
          default:
            errorMessage = error.message;
            break;
        }
        setError('general', { type: 'manual', message: errorMessage });
        setIsSubmitted(false);
        return;
      }
      const phoneNum = parsePhoneNumberFromString(
        payload.phoneNumber,
        'US',
      ).number;

      const trialCodeData = await verifyTrialCode({
        trialCode: payload.trialCode || '',
      });

      const createCompanyPayload = {
        ...payload,
        aircraftQuantity: payload.aircraftQuantity.value,
        phoneNumber: phoneNum,
        uid: user.result.uid,
      };

      if (trialCodeData.data.isValid) {
        createCompanyPayload.trialCodeId = trialCodeData.data.id;
        createCompanyPayload.intervalInDays = trialCodeData.data.intervalInDays;
      }

      await createCompany(createCompanyPayload).catch(error => {
        let errorMessage;
        switch (error.code) {
          case 'No companyName given.':
            errorMessage = 'Please pick a stronger password.';
            break;
          default:
            errorMessage = error.message;
            break;
        }
        setError('general', { type: 'manual', message: errorMessage });
        setIsSubmitted(false);
      });
    },
    [setError, setIsSubmitted],
  );

  return handleSubmit(submit);
};

export const updateUser = async ({
  firstName,
  lastName,
  phoneNumber,
  weight,
}) => {
  const { currentUser } = app.auth();
  const userRef = getMyUserDoc();

  const phoneNum = parsePhoneNumberFromString(phoneNumber, 'US').number;

  await currentUser.updateProfile({
    displayName: firstName,
  });

  // TODO: update phone number in auth

  await userRef.update({
    firstName,
    lastName,
    phoneNumber: phoneNum,
    weight,
    dateUpdated: new Date(),
  });
};

export const updateAccount = async ({ email, currentPassword, password }) => {
  const { currentUser } = app.auth();

  const authCredential = EmailAuthProvider.credential(
    currentUser.email,
    currentPassword,
  );

  await currentUser.reauthenticateWithCredential(authCredential);

  if (email?.length) {
    await currentUser.updateEmail(email);
    const userRef = getMyUserDoc();
    await userRef.update({
      email,
      dateUpdated: new Date(),
    });
  }
  if (password?.length) {
    await currentUser.updatePassword(password);
  }
};

export const logOut = () => app.auth().signOut().catch(defaultCatchHandler);

export const handleMagicLink = async link => {
  try {
    const email = await AsyncStorage.getItem('emailForSignIn');
    await app.auth().signInWithEmailLink(email, link);
  } catch (error) {
    defaultCatchHandler(error);
  }
};

export const sendSignInLink = async ({
  email,
  actionCodeSettings = DEFAULT_ACTION_CODE_SETTINGS,
}) => {
  try {
    const loginMethods = await app.auth().fetchSignInMethodsForEmail(email);
    const invalid = loginMethods.length === 0;

    if (invalid) {
      return;
    }

    await AsyncStorage.setItem('emailForSignIn', email);
    await app.auth().sendSignInLinkToEmail(email, actionCodeSettings);
  } catch (error) {
    defaultCatchHandler(error);
  }
};

const defaultCatchHandler = error => {
  if (__DEV__) {
    console.error(error);
  }
  throw error;
};

const DEFAULT_ACTION_CODE_SETTINGS = {
  handleCodeInApp: true,
  url: window.location?.origin ?? 'https://managers.flightapp.io/',
  iOS: {
    bundleId: 'io.ensembleapps.flightapp',
  },
  android: {
    packageName: 'com.flightworks',
    installApp: true,
    minimumVersion: '12',
  },
};
