// src/components/Auth/AuthLogo.js
import React from 'react';
import styled from '@emotion/native';
import AppLogo from '../../../../assets/logo/app-logo.svg';

const AuthLogo = styled(AppLogo)`
  width: 216px;
  height: 72px;
  margin-top: 2%;
`;

export default AuthLogo;
