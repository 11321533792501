import React from 'react';

import { HelpBase, HelpText } from './Help';

const BestPractices = () => (
  <HelpBase>
    <ul>
      <li><HelpText>A trip is only expected to last up to ten days.</HelpText></li>
      <li><HelpText>A leg is assumed to end on the same day it starts.</HelpText></li>
      <li><HelpText>If your aircraft owners don't know the airport codes or which
        airport you'll be using they can type in city names for origins
        and destinations, and you can change them to the specific
        airport codes. Then you can communicate in the notes so they
        show up to the right airport.</HelpText></li>
      <li><HelpText>Owner Status Explanations</HelpText></li>
      <ul>
        <li>
          Draft: A trip made by the owner and not yet shared with
          managers.
        </li>
        <li>
          Manager Requested: A manager has created a trip and shared
          it with the owner.
        </li>
        <li>Canceled: The trip has been canceled.</li>
        <li>
          Accepted: The owner has accepted the manager-made trip.
        </li>
        <li>
          Rejected: The owner has rejected the manager-made trip.
        </li>
        <li>
          Updated: A manager has made an update to the trip and the
          owner hasn't seen it yet.
        </li>
        <li>Seen: The owner has seen updates made by a manager.</li>
        <li>
          Requested: An owner has made the trip, and shared it with
          the managers, but managers haven't acknowledged it yet.
        </li>
        <li>
          Acknowledged: A manager has acknowledged an owner-made trip.
        </li>
      </ul>
      <li><HelpText>Pilot Status Explanations</HelpText></li>
      <ul>
        <li>
          Manager Requested: A manager has created a trip and shared
          it with the pilot.
        </li>
        <li>
          Manager Canceled: The trip has been canceled by a manager.
        </li>
        <li>Accepted: The pilot has accepted the trip.</li>
        <li>Rejected: The pilot has rejected the trip.</li>
        <li>
          Updated: A manager has made an update to the trip and the
          pilot hasn't seen it yet.
        </li>
        <li>Seen: The pilot has seen updates made by a manager.</li>
      </ul>
    </ul>
  </HelpBase >
);

export default BestPractices;
