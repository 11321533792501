import React from 'react';
import { Platform } from 'react-native';
import { Box } from '@appComponents/ScreenLayout';
import { useTheme } from '@appComponents/theme';
import {
  IntegerFormField,
  DecimalFormField,
} from '@appComponents/forms/FormFields';
import { Controller } from 'react-hook-form';
import TextField from '@appComponents/TextField';

export const IntegerField = ({
  name,
  label,
  control,
  allowNegative = false,
  useComma = true,
  style,
  wrapStyle,
  disabled,
  mode,
}) => (
  <Box mb={2}>
    <IntegerFormField
      name={name}
      label={label}
      control={control}
      allowNegative={allowNegative}
      useComma={useComma}
      style={style}
      wrapStyle={wrapStyle}
      disabled={disabled}
      mode={mode}
    />
  </Box>
);

export const DecimalField = ({
  name,
  label,
  control,
  decimalPlaces = 2,
  disabled,
  outlineColor,
  style,
  mode,
}) => (
  <Box mb={2}>
    <DecimalFormField
      name={name}
      label={label}
      control={control}
      decimalPlaces={decimalPlaces}
      isIos={Platform.OS === 'ios'}
      allowNegative={true}
      useComma={true}
      disabled={disabled}
      outlineColor={outlineColor}
      style={style}
      mode={mode}
    />
  </Box>
);

export const EngineField = ({ name, label, control, disabled }) => {
  const theme = useTheme();

  return (
    <Box mb={2}>
      <DecimalFormField
        name={name}
        label={label}
        control={control}
        decimalPlaces={1}
        style={{
          backgroundColor: theme.colors.fieldBackground,
          color: theme.colors.tableBackground,
        }}
        isIos={Platform.OS === 'ios'}
        disabled={disabled}
      />
    </Box>
  );
};

export const VorCheckField = ({ name, label, control, disabled, style }) => {
  const handleChangeText = value => {
    // Allow letters and numbers, limit to 3 characters
    const formattedValue = value.replace(/[^A-Za-z0-9]/g, '').slice(0, 3);
    return formattedValue;
  };

  return (
    <Box mb={2}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <TextField
            label={label}
            value={value}
            onChangeText={text => onChange(handleChangeText(text))}
            disabled={disabled}
            style={style}
          />
        )}
        rules={{
          maxLength: {
            value: 3,
            message: 'VOR Check must be 3 characters or less',
          },
          pattern: {
            value: /^[A-Za-z0-9]+$/, // Allow letters and numbers
            message: 'VOR Check must only contain letters and numbers',
          },
        }}
      />
    </Box>
  );
};

export const RvsmAltitudeField = ({ name, label, control, style }) => (
  <Box mb={2}>
    <IntegerField
      name={name}
      label={label}
      control={control}
      allowNegative={false}
      useComma={true}
      style={style}
    />
  </Box>
);

export const VorNumberField = ({
  name,
  label,
  control,
  wrapStyle,
  disabled,
  style,
}) => (
  <Box mb={2}>
    <IntegerField
      name={name}
      label={label}
      control={control}
      allowNegative={false}
      useComma={false}
      wrapStyle={wrapStyle}
      disabled={disabled}
      style={style}
    />
  </Box>
);
