import React, { useState } from 'react';
import styled from '@emotion/native';
import _ from 'lodash';
import { DateTime } from 'luxon';

import Button, { LinkButton } from '@appComponents/Button';
import {
  Circle,
  MainSurface,
  ScreenLayout,
  ScreenLoader,
} from '@appComponents/ScreenLayout';
import { useAircraftList } from '@appUtils/aircraft';
import DataTable, { Cell, PlainCell } from '@appComponents/DataTable';
import Text, { PersonText } from '@appComponents/Text';
import { SquawkLegend } from '../../components/SquawkLegend';
import {
  getSquawkColorByPriority,
  SquawkPriority,
  SquawkStatus,
} from '@appUtils/squawks';
import { ScrollView } from 'react-native';
import { useCompanyData } from '@appUtils/api';
import { Menu, useMenuState } from '@appComponents/Menu';
import app from '@appFirebase';
import { useAsyncCallback } from 'react-async-hook';
import ConfirmationDialog from '../../components/ConfirmationDialog';

const ArchivedAircraftsScreen = ({ navigation }) => {
  const { loading, data, error } = useAircraftList(true);
  let emptyMessage = <Text>No Aircraft Available</Text>;
  const [companyData, loadingCompanyData] = useCompanyData();

  if (loading && _.isEmpty(data)) {
    emptyMessage = <Text>Loading...</Text>;
  }
  if (error) {
    emptyMessage = <Text>Failed to load aircraft. Try again later</Text>;
  }

  if (loadingCompanyData) {
    return <ScreenLoader />;
  }

  return (
    <ScreenLayout alignItems="stretch">
      <MainSurface>
        {loading && <ScreenLoader />}
        <SquawkLegend />
        <ScrollView>
          <DataTable
            data={data}
            emptyMessage={emptyMessage}
            keyExtractor={(aircraft, index) => aircraft?.tailNumber ?? index}>
            <TailCell title="Tail #" navigation={navigation} />
            <PlainCell title="Name" path="name" />
            <PlainCell title="Type" path="type" />
            <NameCell title="Primary Owner" />
            <PlainCell title="Location" path="location" />
            <LastTripCell title="Last Trip" />
            <FuelCell title="Fuel Off" flex={0.5} numeric />
            <ActionsCell
              name="actions"
              numeric
              flex={3}
              icon="more-dots"
              aircraftQuantityCompany={companyData?.aircraftQuantity}
            />
          </DataTable>
        </ScrollView>
      </MainSurface>
    </ScreenLayout>
  );
};

const TailCell = ({ item, navigation, ...cellProps }) => {
  const tail = item.tailNumber;
  const squawkPriority =
    item.squawkPriority ?? SquawkPriority[SquawkStatus.NO_SQUAWKS];
  const squawkColor = getSquawkColorByPriority(squawkPriority);

  const labelStyle = { marginHorizontal: 0 };

  return (
    <Cell {...cellProps}>
      <Circle color={squawkColor} size={10} />
      <LinkButton
        toScreen="Aircraft Details"
        params={{ documentPath: item.path }}
        mode="text"
        labelStyle={labelStyle}>
        {tail}
      </LinkButton>
    </Cell>
  );
};

const NameCell = ({ item, flex }) => (
  <Cell flex={flex}>
    <PersonText entry={_.get(item, 'owners.0')} />
  </Cell>
);

const LastTripCell = ({ item, ...cellProps }) => {
  const lastUpdate = item?.dateUpdated;
  if (!lastUpdate) {
    return <Cell {...cellProps}>-</Cell>;
  }

  const date = DateTime.fromSeconds(lastUpdate.seconds).toLocaleString(
    DateTime.DATE_MED,
  );

  return <Cell {...cellProps}>{date}</Cell>;
};

const FuelCell = ({ item, ...cellProps }) => (
  <PlainCell
    path="fuelOff"
    suffix={` ${item?.fuelUnits ?? 'gal'}`}
    row={item}
    {...cellProps}
  />
);

export default ArchivedAircraftsScreen;

const ActionsCell = React.memo(
  ({ item: aircraft, label, icon, aircraftQuantityCompany, ...cellProps }) => {
    const { action, open, ...menu } = useStateActions([aircraft]);

    return (
      <Cell ref={menu.anchorEl} {...cellProps}>
        <Button
          label={label}
          loading={action.loading}
          mode="text"
          icon={icon}
          onPress={open}
        />
        <Actions
          aircraft={aircraft}
          action={action}
          aircraftQuantityCompany={aircraftQuantityCompany}
          {...menu}
        />
      </Cell>
    );
  },
  (a, b) => a.item.state === b.item.state,
);

const getActionsForState = () => {
  return [
    {
      nextState: 'Restore',
      title: 'Restore',
      modalTitle: 'Restore Aircraft',
      modalContent: 'Are you sure you want to restore this aircraft?',
    },
    {
      nextState: 'Delete',
      title: 'Delete',
      modalTitle: 'Delete Aircraft',
      modalContent:
        'Are you sure you want to delete this aircraft? Deleted aircraft cannot be recovered.',
    },
  ];
};

const useStateActions = aircrafts => {
  const { close, ...menu } = useMenuState();
  const action = useAsyncCallback(async nextState => {
    const batch = app.firestore().batch();
    aircrafts.forEach(aircraft => {
      const doc = app.firestore().doc(aircraft.path);

      if (nextState === 'Delete') {
        batch.delete(doc);
      }

      if (nextState === 'Restore') {
        const updates = {
          archived: false,
        };
        batch.update(doc, updates);
      }
    });

    close();
    return batch.commit();
  });

  return { action, close, ...menu };
};

const Actions = ({
  anchorEl,
  isOpen,
  close,
  action,
  aircraft,
  aircraftQuantityCompany,
}) => (
  <Menu anchor={anchorEl} visible={isOpen} onDismiss={close}>
    {getActionsForState(aircraft).map(entry => (
      <ActionItem
        key={entry.title}
        action={action}
        aircraftQuantityCompany={aircraftQuantityCompany}
        {...entry}
      />
    ))}
  </Menu>
);

const ActionItem = ({
  title,
  action,
  nextState,
  modalTitle,
  modalContent,
  aircraftQuantityCompany,
}) => {
  const { data: notArchivedAircrafts } = useAircraftList(false);
  const [limitReached, setLimitReached] = useState(false);

  const handlePress = () => {
    if (nextState === 'Restore') {
      if (notArchivedAircrafts.length >= aircraftQuantityCompany) {
        setLimitReached(true);
        return;
      }
    }
    action.execute(nextState);
  };

  return (
    <ConfirmationDialog
      title={title}
      modalTitle={modalTitle}
      modalContent={modalContent}
      onPress={handlePress}
      error={
        limitReached &&
        `Please increase the number of managed aircraft in your FlightApp! subscription before restoring this aircraft. You currently have ${notArchivedAircrafts.length} aircraft.`
      }
    />
  );
};
