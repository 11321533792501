import * as React from 'react';
import type { Node } from 'react';
import styled from '@emotion/native';
import { Box, Spacer } from '@appComponents/ScreenLayout';
import Button from '@appComponents/Button';
import Text from '@appComponents/Text';
import AuthLogo from './AuthLogo';
import { useTheme } from '@appComponents/theme';

import AuthImageBackground from './AuthImageBackground';

type AuthLayoutProps = {
  children: Node,
  wide?: boolean,
};

const AuthLayout = ({ children }: AuthLayoutProps): Node => {
  const theme = useTheme();
  return (
    <Box dir="row" height="100%">
      <AuthImageBackground />
      <FormPane ph={theme.layout.space(0.25)} flex={1}>
        <AuthLogo />
        <Spacer />
        <ScrollWrapper>{children}</ScrollWrapper>
      </FormPane>
    </Box>
  );
};

const FormPane = styled(Box)(({ theme }) => ({
  minWidth: '430px',
  height: '100vh',
  justifyContent: 'left',
}));

const ScrollWrapper = styled.ScrollView(({ theme }) => ({
  paddingRight: theme.layout.space(1),
}));

export const JoinCompanyButton = ({ onPress, width = 'fit-content' }) => (
  <Button mode="outlined" onPress={onPress} width={width}>
    <Text color="primary">JOIN AN EXISTING ACCOUNT</Text>
  </Button>
);

export default AuthLayout;
