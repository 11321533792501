import React from 'react';
import { Controller, Control, useWatch } from 'react-hook-form';
import styled from '@emotion/native';
import SearchField from '@appComponents/SearchField';
import Text from '@appComponents/Text';

const dropdownStyles = {
  height: 56,
  minWidth: 180,
};

export const AircraftQuantitySelect = ({
  label,
  control,
  name,
  options,
  theme,
  rules,
  disabled,
}) => (
  <FormRow style={{ width: '45%' }}>
    <FormCol flex={2}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <>
              <SearchField
                value={value?.name ? value : ''}
                options={options}
                onChange={onChange}
                getOptionLabel={o => o.name}
                getOptionValue={o => o.name}
                isValidNewOption={() => false}
                style={dropdownStyles}
                controlStyle={{
                  height: 55,
                  backgroundColor: theme.colors.background,
                  color: theme.colors.black,
                  hoverColor: theme.colors.primary,
                }}
                optionStyle={{
                  height: undefined,
                  backgroundColor: theme.colors.background,
                  color: theme.colors.black,
                }}
                disabled={disabled}
              />
              {error && (
                <TextWrapper ml="5px" color="secondary">
                  {error.message}
                </TextWrapper>
              )}
            </>
          );
        }}
      />
    </FormCol>
  </FormRow>
);

const FormRow = styled.View`
  flex-direction: row;
  align-items: 'center';
  justify-content: space-between;
  width: ${({ width = '100%' }) =>
    Number.isInteger(width) ? width + 'px' : width};
  min-height: ${({ height = 82 }) => height.toString()}px;
  flex-wrap: ${({ wrap }) => wrap};
  margin-top: ${({ theme, mt }) =>
    mt && `${theme.layout.space(mt).toString()}px`};
`;

const FormCol = styled.View(
  ({ theme, flex = 1, alignSelf = 'center', mr, mh, ml }) => ({
    flexDirection: 'column',
    flex,
    alignSelf,
    marginHorizontal: mh && theme.layout.space(mh),
    marginRight: mr && theme.layout.space(mr),
    marginLeft: ml && theme.layout.space(ml),
  }),
);

const TextWrapper = styled(Text)(({ ml, w, h, aSelf }) => ({
  marginLeft: ml ?? 0,
  width: w ?? undefined,
  alignSelf: aSelf ?? undefined,
  height: h ?? undefined,
}));
