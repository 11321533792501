/**
 * @file
 * A dialog for viewing, adding, and removing  Trip Documents
 *
 * @format
 * @flow strict-local
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/native';
import _ from 'lodash';
import { useStorageImages } from '@webUtils/storage';
import { FitToContentButton } from '@appComponents/Button';
import { useForm } from 'react-hook-form';
import Dialog from '@appComponents/Dialog';
import ControlledImageViewer from '@webComponents/ControlledImageViewer';
import { useMyData } from '@appUtils/api';
import { getUid } from '@appFirebase';
import Text from '@appComponents/Text';

export const useTripDocumentsDialog = ({
  saveTrip,
  updateTrip,
  tripDocuments,
  tripId,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const closeDialog = useCallback(() => {
    setShowDialog(false);
  }, []);
  const openDialog = useCallback(() => {
    setShowDialog(true);
  }, []);

  // We're intentionally unmounting so the form in the dialog
  // can be reset automatically on next mount
  const dialogNode = showDialog && (
    <TripDocumentsDialog
      saveTrip={saveTrip}
      updateTrip={updateTrip}
      closeDialog={closeDialog}
      tripDocuments={tripDocuments}
      tripId={tripId}
    />
  );

  return {
    dialogNode,
    openDialog,
    closeDialog,
  };
};

const TripDocumentsDialog = ({
  saveTrip,
  updateTrip,
  closeDialog,
  tripDocuments,
  tripId,
}) => {
  const [user] = useMyData();
  const userId = getUid();
  const [documents, setDocuments] = useState(tripDocuments);
  const documentUrls = useMemo(
    () => (documents ? documents.map(document => document.url) : []),
    [documents],
  );
  const { control, reset, watch } = useForm({
    defaultValues: { documentUrls },
  });
  const storagePath = `/trips/${tripId}/documents`;
  const formDocuments = watch('documentUrls');
  const images = useStorageImages(formDocuments);
  const [errorText, setErrorText] = useState();

  useEffect(() => {
    if (!_.isEqual(documentUrls, formDocuments)) {
      let docs = documents ? _.cloneDeep(documents) : [];
      const deletedUrls = _.difference(documentUrls, formDocuments);
      const newUrls = _.difference(formDocuments, documentUrls);
      if (newUrls.length === 0 && deletedUrls.length === 0) {
        // A duplicate was uploaded, ignore it and show a warning
        reset({ documentUrls });
        setErrorText('Trip Documents must have unique file names');
        return;
      }
      if (newUrls.length !== 0) {
        const newDocument = {
          url: _.last(formDocuments),
          uploadDate: new Date(),
          uploadedBy: {
            name: `${user.firstName} ${user.lastName}`,
            role: user.role,
            id: userId,
          },
        };
        docs.push(newDocument);
      }
      if (deletedUrls.length !== 0) {
        const index = _.findIndex(docs, doc => doc.url === deletedUrls[0]);
        docs.splice(index, 1);
      }
      updateTrip({ documents: docs });
      saveTrip();
      setDocuments(docs);
      setErrorText(undefined);
    }
  }, [
    documentUrls,
    documents,
    formDocuments,
    reset,
    saveTrip,
    updateTrip,
    user,
    userId,
  ]);

  return (
    <Dialog
      visible
      title="Trip Documents"
      onDismiss={closeDialog}
      actionSlot={
        <FitToContentButton color="secondary" mr="10px" onPress={closeDialog}>
          CLOSE
        </FitToContentButton>
      }>
      <Text align="center" color="red" size="mediumLarge">
        {errorText}
      </Text>
      <ScrollWrapper>
        <ControlledImageViewer
          images={images}
          editable
          control={control}
          storagePath={storagePath}
          addImageText="Add trip documents"
          displayFileNames
          name="documentUrls"
          uploadData={documents}
        />
      </ScrollWrapper>
    </Dialog>
  );
};

const ScrollWrapper = styled.ScrollView(({ theme }) => ({
  height: 0.6 * theme.layout.height,
  paddingRight: '10px',
}));
