import React from 'react';
import { View } from 'react-native-web';
import Text from '@appComponents/Text';
import styled from '@emotion/native';
import Button from '../../../../components/Button';
import { ConfirmPasswordFormField } from './fields';
import { reauthenticateUser } from '@appUtils/auth';

const PasswordConfirmDialog = ({
  visible,
  form,
  setVisible,
  user,
  setReauthenticated,
  setSuccessNotificationVisible,
  setErrorNotificationVisible,
}) => {
  if (!visible) {
    return null;
  }
  const handleSubmit = form.handleSubmit(async data => {
    const payload = { email: user.email, password: data.currentPassword };

    const result = await reauthenticateUser(payload);
    if (result) {
      setVisible(false);
      setReauthenticated(true);
      setSuccessNotificationVisible(true);
      return;
    }
    form.setError('currentPassword', {
      message: 'Invalid password',
    });
    setErrorNotificationVisible(true);
  });

  return (
    <>
      <Wrapper>
        <Content>
          <Text>Please enter your current password to confirm the changes</Text>
          <View style={{ paddingTop: 10 }}>
            <ConfirmPasswordFormField control={form.control} />
          </View>
          <Button onPress={() => handleSubmit()}>Confirm</Button>
        </Content>
      </Wrapper>
    </>
  );
};

const Wrapper = styled(View)(({ theme }) => ({
  backgroundColor: '#2A2D50',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  zIndex: 900,
  height: '45',
  alignItems: 'flex-end',
  justifyContent: 'center',
  borderBottomLeftRadius: 16,
  borderBottomRightRadius: 16,
}));

const Content = styled(View)({
  paddingHorizontal: 16,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 16,
  marginRight: 20,
});

export default PasswordConfirmDialog;
