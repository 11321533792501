/**
 * @file
 * Aircraft dropdown/search component
 *
 * @format
 * @flow strict-local
 */

import React, { Ref } from 'react';
import { ViewStyle } from 'react-native';
import _ from 'lodash';

import SearchField from '@appComponents/SearchField';
import { Box } from '@appComponents/ScreenLayout';
import { useAircraftList } from '@appUtils/aircraft';
import { AvailableRange, FilterDescription } from './components';
import { useTheme } from '@appComponents/theme';

type SelectAircraftProps = {
  selected?: AircraftItem,
  onChange?: AircraftItem => void,
  style?: ViewStyle,
  innerRef?: Ref,
};

export type AircraftItem = {
  tailNumber: string,
  name?: string,
  type?: string,
};

const SelectAircraft = ({
  selected,
  onChange,
  style,
  innerRef,
  disabled,
}: SelectAircraftProps) => {
  const [options, loading] = useOptions();
  const theme = useTheme();

  return (
    <Box dir="column">
      <SearchField
        innerRef={innerRef}
        isClearable
        placeholder="Select an Aircraft"
        value={selected}
        options={options}
        loading={loading}
        getOptionValue={o => o.tailNumber}
        isValidNewOption={() => false}
        onChange={onChange}
        style={{ ...styles, ...style }}
        controlStyle={getControlStyle(theme)}
        disabled={disabled}
      />
      {Boolean(selected) && <FilterDescription entity="aircraft" />}
      {Boolean(selected) && (
        <AvailableRange aircraft={selected} entity="aircraft" />
      )}
    </Box>
  );
};

const useOptions = () => {
  const { loading, data } = useAircraftList();

  const options = _.chain(data)
    .map((entry: AircraftItem) => ({
      label: `${entry.tailNumber} - ${entry.name || entry.type}`,
      tailNumber: entry.tailNumber,
    }))
    .orderBy('label')
    .value();

  return [options, loading];
};

const styles = {
  height: 40,
  minWidth: 280,
};

const getControlStyle = theme => ({
  backgroundColor: theme.colors.background,
  color: theme.colors.text,
});

export default SelectAircraft;
