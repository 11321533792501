import React from 'react';

import { UserRole } from '@appUtils/tripConverter';
import UserRoleList from './UserRoleList';

const ArchivedManagers = () => (
  <UserRoleList role={UserRole.MANAGER} withActions showArchived />
);

export default ArchivedManagers;
