import { numericFormatter } from 'react-number-format';

export const numDecimalPlaces = num => {
  const numString = num.toString();
  const decimalPointIndex = numString.indexOf('.');
  if (decimalPointIndex > -1) {
    return numString.length - decimalPointIndex - 1;
  }
  return 0;
};

export const fuelUsedDisplay = (
  fuelOn,
  fuelOff,
  emptyDisplay = '',
  fuelUnits,
) => {
  const fuelOnDecimals = numDecimalPlaces(fuelOn);
  const fuelOffDecimals = numDecimalPlaces(fuelOff);
  if (fuelOn && fuelOff) {
    return `${(Number(fuelOn) - Number(fuelOff)).toFixed(
      Math.max(fuelOnDecimals, fuelOffDecimals),
    )}${fuelUnits ? ` ${fuelUnits}` : ''}`;
  }
  return emptyDisplay;
};

const makeDecimalLimitOnChangeFunc = numDecimals => updateFunc => newText => {
  const firstDecimalPointIndex = newText.indexOf('.');
  const lastDecimalPointIndex = newText.lastIndexOf('.');
  // Only one decimal point
  if (firstDecimalPointIndex !== lastDecimalPointIndex) {
    return updateFunc(newText.slice(0, -1));
  }
  // Limit the decimal places
  if (numDecimalPlaces(newText) > numDecimals) {
    return updateFunc(
      newText.slice(0, firstDecimalPointIndex + numDecimals + 1),
    );
  }
  return updateFunc(newText);
};

export const oneDecimalOnChange = makeDecimalLimitOnChangeFunc(1);

export const twoDecimalOnChange = makeDecimalLimitOnChangeFunc(2);

export const formatNumber = ({ value, prefix, decimalScale }) => {
  if (value !== undefined) {
    const val = value.toString().replace(prefix, '').replaceAll(',', '');
    const formattedValue = numericFormatter(val, {
      thousandSeparator: true,
      decimalScale: decimalScale,
      prefix: prefix,
      displayType: 'text',
    });
    return formattedValue;
  }
  return null;
};
