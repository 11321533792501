import React from 'react';
import { StyleSheet, View, ActivityIndicator } from 'react-native';
import styled from '@emotion/native';
import { useTheme } from '@appComponents/theme';
import Tabs from '@appComponents/Tabs';
import { RadioButtonField } from '@appComponents/Metering/RadioButtonField';
import { useMeteringContext } from '@appUtils/context/MeteringContext';

export const TabsContainer = ({
  tabs,
  activeTab,
  setActiveTab,
  handleUpdateModeChange,
}) => {
  const theme = useTheme();
  const { control, saveMeteringData, isSaving } = useMeteringContext();

  const onChangeInterceptor = value => {
    handleUpdateModeChange(value);
    saveMeteringData();
  };

  return (
    <TabsWrapper theme={theme}>
      <Tabs
        tabIndex={tabs.findIndex(tab => tab.key === activeTab.key)}
        onTabPress={({ route }) => setActiveTab(route)}
        routes={tabs}
        tabBarInline
        onIndexChange={() => null}
      />
      <View style={styles.radioGroup}>
        <RadioButtonField
          name="updateMode"
          control={control}
          options={[
            { value: 'leg', label: 'Update for each leg' },
            { value: 'trip', label: 'Update for trip' },
          ]}
          onChangeInterceptor={value => onChangeInterceptor(value)}
        />
        {isSaving ? (
          <View style={styles.loader}>
            <ActivityIndicator size="small" />
          </View>
        ) : null}
      </View>
    </TabsWrapper>
  );
};

const styles = StyleSheet.create({
  radioGroup: {
    marginVertical: 20,
    width: 350,
    marginHorizontal: 22,
    flexDirection: 'row',
    alignItems: 'center',
  },
  loader: {
    position: 'absolute',
    marginRight: -50,
    right: 0,
  },
});

const TabsWrapper = styled.View`
  flex-direction: column;
  margin: 0 -24px;
`;
