/**
 * @file
 * Owner dropdown/search component
 *
 * @format
 * @flow strict-local
 */

import React, { Ref } from 'react';
import { ViewStyle } from 'react-native';

import SearchField from '@appComponents/SearchField';
import { useCompanyUsersFull } from '@appUtils/api';
import { UserRole } from '@appUtils/tripConverter';
import { userToPassengerData } from '@appUtils/passengers';

type SelectOwnerProps = {
  owner?: OwnerItem,
  onChange?: OwnerItem => void,
  onBlur?: Event => void,
  style?: ViewStyle,
  controlStyle?: ViewStyle,
  autoFocus?: boolean,
  innerRef?: Ref,
  isClearable?: boolean,
  isOptionDisabled?: OwnerItem => boolean,
  disabled?: boolean,
  isRequired?: boolean,
};

export type OwnerItem = {
  id: string,
  firstName: string,
  lastName: string,
};

const SelectOwner = ({
  owner,
  onChange,
  onBlur,
  style,
  controlStyle,
  autoFocus,
  innerRef,
  isClearable,
  isOptionDisabled,
  disabled,
  isRequired,
}: SelectOwnerProps) => {
  const { data: owners, loading } = useCompanyUsersFull({
    roles: [UserRole.OWNER],
  });
  const options = owners?.map(o => userToPassengerData({ user: o }));

  return (
    <SearchField
      innerRef={innerRef}
      isClearable={isClearable}
      placeholder={`Select an Owner${isRequired ? ' *' : ''}`}
      autoFocus={autoFocus}
      defaultValue={owner}
      options={options}
      loading={loading}
      getOptionLabel={o => o.name}
      getOptionValue={o => o.id}
      isValidNewOption={() => false}
      isOptionDisabled={isOptionDisabled}
      onChange={onChange}
      onBlur={onBlur}
      style={style}
      controlStyle={controlStyle}
      disabled={disabled}
      isRequired={isRequired}
    />
  );
};

export default SelectOwner;
